import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';

const ProductSearch = () => {


  const [search, setSearch] = useState('');
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [isOn, setIsOn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);

  const isMobile = window.innerWidth < 770;


  const handleSearch = (event) => { setSearch(event.target.value); };



  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      setIsLoading(true);
      
      const filteredData = await filterData(search);
      setFilteredRecords(filteredData);
      setIsLoading(false);
      
      if (setFilteredRecords.length <= 0) {
        setNoResultFound(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };



  const filterData = async (search) => {

    const pb = new PocketBase('https://api.akaiindia.shop');

    const collectionNames = ['tv', 'audio', 'washing_machine', 'air_conditioners'];

    let filteredData = [];

    for (const collectionName of collectionNames) {
      const fetchedRecords = await pb.collection(collectionName).getFullList({ sort: '-created' });
      filteredData = fetchedRecords.filter((record) => {
        return record.Product_Name.toLowerCase().includes(search.toLowerCase());
      });
      if (filteredData.length > 0) {
        break;
      }
      
    }
    return filteredData;
  };

  const toggleSearch = () => {
    setIsOn(!isOn);
  };

  return (

    <div className='z-50 overscroll-none w-full overflow-hidden'>

      {isMobile ?


        <div className='z-50 overscroll-none'>
          {isOn && (
            <div className='overscroll-none overflow-hidden absolute inset-0 bg-black backdrop-blur-sm bg-opacity-95 h-screen flex flex-col items-center'>

              <form onSubmit={handleSubmit} className='flex flex-col'>

                <p className='mt-[65px] text-[5vw] md:text-[4vw] font-semibold text-center mb-1 text-white'>Search AKAI Products</p>


                <input required className='w-[300px] bg-white focus:outline-none rounded-full caret-pink-500 border-3 border-gray-200 text-left p-3 h-auto mt-3' placeholder='Type here...' type="text" value={search} onChange={handleSearch} />

                <button className='hover:bg-gray-900 transform duration-200 bg-black rounded-full text-white border border-white w-auto h-9 mt-3' type="submit">

                  {isLoading ? 'Searching...' : 'Search'}

                </button>

                <button className='text-white text-xs w-fit px-5 py-2 rounded-full hover:px-7 translate duration-200 border-0 m-auto mt-5 pb-1' onClick={toggleSearch}>❌ Close Search</button>
              </form>

              {isLoading && <p className="text-white mt-4">Searching...</p>}
              {noResultFound && <p className="text-white mt-4">No Match Found</p>}

              <div className='grid grid-cols-1 gap-1 place-content-start w-[90%] h-[45%] mt-9 overflow-x-hidden space-y-1 bg-gray-000'>
                {filteredRecords.map((record) => (
                  <ResultView
                    key={record.id}
                    URL_Name={record.URL_Name}
                    Product_Name={record.Product_Name}
                    SKU={record.SKU}
                    Category={record.Category}
                    collectionId={record.collectionId}
                    id={record.id}
                    image={record.mainimage}
                    collectionName={record.collectionName}
                    toggleSearch={toggleSearch} // Pass toggleSearch function
                  />
                ))}
              </div>
            </div>
          )}
        </div>


        :

        // desktop
        <div className='z-50 overscroll-none w-full'>
          {isOn && (
            <div className=' overscroll-none overflow-hidden absolute top-0 bg-black backdrop-blur-sm bg-opacity-95 w-screen h-screen flex flex-col items-center'>
              <form onSubmit={handleSubmit} className='flex flex-col'>
                <p className='mt-[65px] text-4xl font-semibold text-center mb-1 text-white'>Search AKAI Products</p>
                <div className='mt-[50px] bg-white border-slate-500 p-1 rounded-full overflow-hidden'>
                  <input required className='focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-0 bg-white rounded-full caret-pink-500 border-3 border-gray-200 text-left w-[700px] p-9 h-[60px]' placeholder='Type here...' type="text" value={search} onChange={handleSearch} />

                  <button className='hover:bg-gray-900 transform duration-200 bg-black rounded-full text-xl text-white w-[180px] h-[70px] mt--3' type="submit">
                    {isLoading ? 'Searching...' : 'Search'}
                  </button>
                </div>
                <button className='text-white w-fit px-5 rounded-full hover:px-7 translate duration-200 border-0 m-auto mt-5 pb-1' onClick={toggleSearch}>❌ Close Search</button>
              </form>

              {isLoading && <p className="text-white mt-4">Searching...</p>}
              {noResultFound && <p className="text-white mt-4">No Match Found</p>}

              <div className='grid grid-cols-2 gap-2 place-content-start w-[60%] h-[45%] mt-9 overflow-x-hidden space-y-1 bg-gray-000'>
                {filteredRecords.map((record) => (
                  <ResultView
                    key={record.id}
                    URL_Name={record.URL_Name}
                    Product_Name={record.Product_Name}
                    SKU={record.SKU}
                    Category={record.Category}
                    collectionId={record.collectionId}
                    id={record.id}
                    image={record.mainimage}
                    collectionName={record.collectionName}
                    toggleSearch={toggleSearch} // Pass toggleSearch function
                  />
                ))}
              </div>
            </div>
          )}
        </div>

      }

    </div>


  );
};






function ResultView(props) {

  const [link, setLink] = useState('');

  useEffect(() => {
    // Determine the link based on the collectionId
    if (props.collectionId === 'oh7ahpehttpwpl7') {
      setLink('air-conditioners');
    } else if (props.collectionId === 'kkrjv3jpv5c0914') {
      setLink('tv');
    } else if (props.collectionId === 'yhzxgigb1eptk66') {
      setLink('washing-machine');
    } else if (props.collectionId === 'z8raa8bez22p9en') {
      setLink('audio');
    }
  }, [props.collectionId]); // Trigger effect only when collectionId changes

  const url = 'https://api.akaiindia.shop/api/files/' + props.collectionId + '/' + props.id + '/';

  const handleClick = () => {
    props.toggleSearch(); // Call toggleSearch function when clicked
  };


  return (
    <Link to={`/${link}/${props.URL_Name.toLowerCase().replace(/\s+/g, '-').replace(/[()]/g, '').replace(/[_]/g, '-')}-${props.SKU.toLowerCase()}/${props.id}`} onClick={handleClick}>
      <div className='bg-black w-full h-[110px] border border-gray-700 m-5 my-1 flex flex-row rounded-lg overflow-hidden items-center'>
        <img className='h-full' src={url + props.image} alt={props.Product_Name} />
        <p className='text-lg text-left text-white w-full p-5'>{props.Product_Name}</p>
      </div>
    </Link>
  );
}





function ResultViewMob(props) {

  const [link, setLink] = useState('');

  useEffect(() => {
    // Determine the link based on the collectionId
    if (props.collectionId === 'oh7ahpehttpwpl7') {
      setLink('air-conditioners');
    } else if (props.collectionId === 'kkrjv3jpv5c0914') {
      setLink('tv');
    } else if (props.collectionId === 'yhzxgigb1eptk66') {
      setLink('washing-machine');
    } else if (props.collectionId === 'z8raa8bez22p9en') {
      setLink('audio');
    }
  }, [props.collectionId]); // Trigger effect only when collectionId changes

  const url = 'https://api.akaiindia.shop/api/files/' + props.collectionId + '/' + props.id + '/';

  const handleClick = () => {
    props.toggleSearch(); // Call toggleSearch function when clicked
  };

  return (


    <Link className='w-[90%]' to={`/${link}/${props.URL_Name.toLowerCase().replace(/\s+/g, '-').replace(/[()]/g, '').replace(/[_]/g, '-')}-${props.SKU.toLowerCase()}/${props.id}`} onClick={handleClick}>

      <div className='bg-black w-full h-[110px] border border-gray-700 m-5 my-1 flex flex-row rounded-lg overflow-hidden items-center'>
        <img className='h-full text-xs' src={url + props.image} alt={props.Product_Name} />
        <p className=' text-xs text-left text-white w-full p-5'>{props.Product_Name}</p>
      </div>
    </Link>
  );
}






export default ProductSearch;
