import React, { useState, useEffect, memo } from 'react';
import PocketBase from 'pocketbase';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import BannerSlider from './components/BannerSlider';
import { SubCategoryButton, SectionBannercomp } from './components/ProductViewUtility';

import SeoSection from './components/SeoSection';
import SeoFAQ from './components/SeoFAQ';






function Audio() {


	const [records, setRecords] = useState([]);
	const [sortVal, setSortVal] = useState('-created');


	useEffect(() => {
		const pb = new PocketBase('https://api.akaiindia.shop');

		const fetchRecords = async () => {
			try {
				const fetchedRecords = await pb.collection('audio').getFullList({ sort: sortVal });

				setRecords(fetchedRecords);

			} catch (error) {
				console.error('Error fetching records:', error);
			}
		};

		fetchRecords();

	}, [sortVal]);



	//Sorting Filter
	const SortBar = (props) => {

		const SortCategory = () => { setSortVal(props.sortVal); }

		return (
			< div className='p-3 lg:p-5 hover:px-6 rounded-full w-fit flex items-center h-5 hover:border-2 border border-gray-700 hover:border-[#e4002b] duration-300'>

				<button className='text-xs md:text-xs md:font-semibold xl:text-base' onClick={SortCategory}>{props.bttnName}</button>

			</div>
		);
	}


	return (
		<div class=' pt-[60px] w-full h-full flex flex-wrap justify-center overflow-hidden'>

			{/* HERO */}
			<div class='bg-fixed w-full h-fit overflow-hidden object-contain' >

				<BannerSlider bannersId='iqv1noiyyjb888t' />

			</div>


			{/* <div class='sticky top-0 h-full md:h-[90px] w-full my-0 flex flex-row items-center justify-center flex-wrap bg-red-000 space-y-2 md:space-y-0 space-x-1 lg:space-x-3'>

				<p>Sort By:</p>

				<SortBar collection='Audio' sortVal='-created' bttnName='Newest' />
				<SortBar collection='Audio' sortVal='Price' bttnName='Price: Low-High' />
				<SortBar collection='Audio' sortVal='-Price' bttnName='Price: High-Low' />
			</div> */}

			<div class='h-fit  w-full my-10 flex md:flex-row flex-col items-center justify-center space-y-5 md:space-y-0 space-x-5'>

				<SubCategoryButton category='audio' subcategory='Tower-Speaker' />
				<SubCategoryButton category='audio' subcategory='Party-Speaker' />
				<SubCategoryButton category='audio' subcategory='Soundbar' />

			</div>



			<div class=' pt-[0px] w-fit h-full flex flex-row flex-wrap justify-center bg-red-000'>

				<ProductSection items={records} name='tower-speaker' banner='https://api.akaiindia.shop/api/files/j1e58m37h1pktq5/m1no7pgsuvojja8/image_audio_tower_63YSSXCzJG.webp' />
				<ProductSection items={records} name='party-speaker' banner='https://api.akaiindia.shop/api/files/j1e58m37h1pktq5/vkkey299rxdwhtq/image_audio_party_speakers_QK0lP2Rrtm.webp' />
				<ProductSection items={records} name='soundbar' banner='https://api.akaiindia.shop/api/files/j1e58m37h1pktq5/4l5p3lvtgfjeb1x/image_audio_soundbar_hsPeHScZdM.webp' />

			</div>


			<SeoSection subcategory='audio' />
            <SeoFAQ subcategory='audio'/>        


		</div>
	);
}




const ProductSection = (props) => {


	const [filter, setFilter] = useState(props.name);

	const filteredItems = props.items.filter(item => item.Category.toLowerCase().includes(filter.toLowerCase()));


	return (
		<div className='flex flex-col items-center my-[25px] md:my-[50px] h-max'>
			
			<SectionBannercomp name={props.name}/>

			<div class="grid grid-cols-2 md:grid md:grid-cols-3 md:gap-3 md:place-content-start md:h-full w-screen md:w-max">

				{filteredItems.map((item, index) => (

					<Link to={`/audio/${item.URL_Name.toLowerCase().replace(/\s+/g, '-').replace(/[()]/g, '').replace(/[_]/g, '-')}-${item.SKU.toLowerCase()}/${item.id}`} >

						<AudioCard collectionId={item.collectionId} id={item.id} index={index} mainimage={item.mainimage} product={item.Product_Name} Category={item.Category} Type={item.Type} sku={item.SKU} Price={item.Price} Price_Offered={item.Price_Offered} />
					</Link>

				))}

			</div>
		</div>
	);
}



function Sepration() {
	return <span class="mx-[10px] text-[#e4002b]">|</span>
}


function AmountCal(props) {

	const c = props.a - props.b;

	return <span class="mx-[10px] p-1 bg-green-200 text-sm rounded-md">Save ₹{c}/-</span>
}





function AudioCard(props) {


	const url = 'https://api.akaiindia.shop/api/files/' + props.collectionId + '/' + props.id + '/' + props.mainimage;


	return (

		<div class='w-[90%] xl:w-[480px] lg:w-[300px] h-auto md:w-[220px] m-auto my-2 md:m-1 border-2 md:border-0 hover:shadow-md rounded-xl overflow-hidden'>


			<div class='w-full h-[78%] bg-gray-000 overflow-hidden flex' >
				<img class='h-full m-auto' src={url} alt={"MainImage_" + props.index} />
			</div>

			<div class='w-full h-[22%] bg-gray-100 p-2' flex justify-center>
				<p class='text-xl text-center mb-[5px] font-'>{props.product}</p>
				<p class='text-xs text-gray-700 md:text-md text-center'>{props.sku}<Sepration />{props.Category}<Sepration />{props.Type} </p>


				< div class="flex justify-center my-2">
					<p class='text-xl md:text-2xl font-semibold text-center '>
						<span class="font-bold"> ₹{props.Price_Offered}/-</span>
						<span class="text-red-500 line-through text-sm md:text-xl"> ₹{props.Price}/-</span>
						<br /> <AmountCal a={props.Price} b={props.Price_Offered} />
					</p>
				</div>

			</div>

		</div>
	);
}
export default memo(Audio);
