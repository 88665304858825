import React, { useState } from 'react'
import hero from './images/Story/Go green.jpg';
import Kid from './images/Story/go green kids.jpg';





function GoGreen() {


	const isMobile = window.innerWidth < 768;

	const bg = process.env.PUBLIC_URL + "./GoGreenHome/1.png";


	const backgroundImageUrl = 'https://api.akaiindia.shop/api/files/gt3zkc0j35bwlny/cv5r3stef4qcli1/category_images_tv_copy_YfMktstTVS.webp';


	return (
		<div class="w-full h-full bg-Black pt-[65px] overflow-hidden">



	  		<div class="w-12/12 h-full bg-gray-900 border-2">
			<img src={hero}></img>
	  		</div>



			<div class=" w-screen h-fit border-0 flex justify-center">
				
				<div class="absolute w-[75%] border-0 my-[30px] mt-[60px]">
  					<p className="text-bold md:text-8xl text-5xl font-thin md:font-bold my-5">Our Goal</p> 
  					<p className="text-gray-800 text-5xl md:text-5xl font-bold my-8">We are taking the <span className='text-[#3BCD9D]'> greener </span> road.</p> 
  					<p className="text-gray-800 text-base md:text-2xl ">Innovating for a greener India, we aim to build good-green products for our people and the environment. Eliminating the need for conventional designs systems that inflict irreparable damage to the environment, Akai also aligns a new, sustainable way of building energy- saving products, much to our effort, with minimal energy usage, be it our air conditioners or washing machines.</p> 
				</div>
				
				<img src={Kid} className="mt-[600px] md:mt-[500px] lg:mt-[150px]"></img>

	  		</div>




			<div class="w-screen flex justify-center mt-[50px] md:mt-[0px]">
  					<p className="text-gray-800 text-4xl md:text-6xl text-center font-bold my-[30px]">Our Green Goals</p> 	
	  		</div>



			<div class="w-screen flex justify-center">


						<div class="w-[95%] flex justify-center divide-solid divide-y-2 md:divide-y-0 md:divide-x-2 md:flex-row flex-col ">
						
						<div class="w-[100%] md:w-[33%] md:h-[200px] h-[150px] border-0 md:my-[50px] my-[00px] bg-red-000 flex justify-center item-center"> 
								<img class='object-contain scale-[90%]' src={process.env.PUBLIC_URL + "./GoGreen (1).png"}/>     
						</div>
						
						<div class="w-[100%] md:w-[33%] md:h-[200px] h-[150px] border-0 md:my-[50px] my-[00px] bg-red-000 flex justify-center item-center"> 
								<img class='object-contain scale-[90%]' src={process.env.PUBLIC_URL + "./GoGreen (2).png"}/>     
						</div>
						
						<div class="w-[100%] md:w-[33%] md:h-[200px] h-[150px] border-0 md:my-[50px] my-[00px] bg-red-000 flex justify-center item-center"> 
								<img class='object-contain scale-[90%]' src={process.env.PUBLIC_URL + "./GoGreen (3).png"}/>     
						</div>
						
						</div>

			</div>








		

			<div class="w-screen flex justify-center">
				<div class="w-[90%] lg:w-[850px] border-0 my-[100px] md:my-[20px] lg:my-[100px]">
  					
					<p className="text-gray-800 font-bold text-center text-5xl lg:text-6xl my-[30px]">You are the epicentre of AKAI</p>
					<p className="text-gray-800 lg:text-xl text-center">
					Developing greener products has been the overarching goal for Akai since its inception. With an unwavering commitment to the environment and the planet, Akai believes in caring for the world around us. Akai has always adhered to its unique approach to Green World, while deeply understanding customers’ needs to stay ahead of the curve. For Akai, being conscious is not just about looking greener, but also about acting greener. The core focus is on producing high-quality,  minimal electricity usage products that are inspired by nature and powered by science.</p> 
				  
				</div>
	  		</div>


		


			  <div class=" w-screen h-max border-0 flex justify-center bg-blue-000">
				
				<div class="absolute w-[90%] lg:w-[75%] xl:w-[60%] border-0 my-[30px] mt-[10px] bg-red-000 text-center">
  					<p className="text-bold lg:text-5xl xl:text-6xl text-3xl font-bold my-5"> Act <span className='text-[#3BCD9D]'> Green </span> for Earth. <br/>Let’s Start from Home. <br/>Let’s Akai!</p> 
  					<p className="text-gray-800 xl:text-2xl lg:text-lg">Embracing a green lifestyle at home begins with simple, conscious choices in our daily routine. Start by reducing energy consumption – switch to LED bulbs, unplug electronics when not in use, and opt for energy-efficient appliances. Choose sustainable, reusable alternatives over single-use items, such as cloth bags instead of plastic. Conserve water by fixing leaks and using a low-flow showerhead. Cultivate greenery indoors to improve air quality and connect with nature. These small steps collectively foster a mindful, eco-friendly home environment, demonstrating that individual actions can collectively make a substantial impact on our planet. Let’s take Greener actions. Let’s Akai.</p> 
				</div>
				
				<img src={process.env.PUBLIC_URL + "./GoGreenHome/1.png"} className="mt-[500px] md:mt-[250px] lg:mt-[320px]"></img>
				
				{/* <div className="bg-no-repeat bg-center w-full h-auto" style={{backgroundImage: `url(${bg})`,}}> 
				<div className="bg-no-repeat bg-center w-full h-auto" style={{backgroundImage: `url(${backgroundImageUrl})`,}}>

  					<p className="text-bold lg:text-5xl xl:text-6xl text-3xl font-bold my-5"> Act <span className='text-[#3BCD9D]'> Green </span> for Earth. <br/>Let’s Start from Home. <br/>Let’s Akai!</p> 
  					<p className="text-gray-800 xl:text-2xl lg:text-lg">Embracing a green lifestyle at home begins with simple, conscious choices in our daily routine. Start by reducing energy consumption – switch to LED bulbs, unplug electronics when not in use, and opt for energy-efficient appliances. Choose sustainable, reusable alternatives over single-use items, such as cloth bags instead of plastic. Conserve water by fixing leaks and using a low-flow showerhead. Cultivate greenery indoors to improve air quality and connect with nature. These small steps collectively foster a mindful, eco-friendly home environment, demonstrating that individual actions can collectively make a substantial impact on our planet. Let’s take Greener actions. Let’s Akai.</p> 
				
				</div> */}

	  		</div>

			



			  <div class="relative w-screen h-max border-0 flex justify-center bg-blue-000">
				
			  {isMobile ? 
			  			<img src={process.env.PUBLIC_URL + "./GoGreenHome/2.1.png"} className=""/>
			  			: 
			  			<img src={process.env.PUBLIC_URL + "./GoGreenHome/2.png"} className=""/>
				}


				<div class="absolute border-0 w-[80%] md:w-[60%] lg:w-[53%] xl:w-[37%] border-0 my-[30px] mt-[140px] md:mt-[230px] lg:mt-[320px] xl:mt-[500px] bg-red-000 text-center text-white">
  					<p className="text-bold md:text-xl lg:text-3xl xl:text-4xl text-xl font-bold my-3">Our ACs exemplify green living with their use of R32, a more environmentally friendly refrigerant.</p> 
  					<p className="md:mx-[50px] md:text-xs/5 lg:text-base xl:text-xl text-sm md:mt-5">Embracing a green lifestyle at home begins with simple, conscious choices in our daily routine. Start by reducing energy consumption – switch to LED bulbs, unplug electronics when not in use, and opt for energy-efficient appliances. Choose sustainable, reusable alternatives over single-use items, such as cloth bags instead of plastic. Conserve water by fixing leaks and using a low-flow showerhead. Cultivate greenery indoors to improve air quality and connect with nature. These small steps collectively foster a mindful, eco-friendly home environment, demonstrating that individual actions can collectively make a substantial impact on our planet. Let’s take Greener actions. Let’s Akai.</p> 
				</div>
				

	  		</div>


			  <div class=" w-[90%] md:w-[70%] rounded-3xl mx-auto h-[1100px] xl:h-[2000px] md:h-[1400px] border-0 flex justify-center bg-blue-000 border-4 overflow-hidden md:mb-[200px] mb-[20px]">
				
				<div class="absolute w-[80%] md:w-[45%] border-0 my-[30px] mt-[10px] xl:mt-[90px] md:mt-[40px] bg-red-000">
  					<p className="text-bold lg:text-4xl xl:text-6xl text-3xl font-bold my-5">Green<br/>The New Go To Color <br/> for our Homes</p> 
  					<p className="md:text-xl md:mt-12">We are bringing together our passion and purpose to make a difference through our green-rich experience and vision of ‘shared-values’ & ‘shared-experiences.’ Not just through, electronics world, but little efforts in our daily life too. </p> 
				</div>


				<div class="absolute w-[80%] xl:w-[45%] md:w-[55%] border-0 my-[330px] mt-[550px] xl:mt-[990px] md:mt-[690px] bg-red-000">
  					<p className="text-bold md:text-4xl :xl:text-6xl text-3xl font-bold my-5">Innovating for Green Lifestyle</p> 
  					<p className="md:text-xl md:mt-9">One of the strongest pillars of Akai- the goodwill and trust built over the years is to ‘Innovate for Conscious Choices.’ We strive to deliver the greatest possible customer benefits through our products with minimal adverse effects on our planet. At Akai, we consider it our goal to improve people’s quality of life.</p> 
				</div>
				
				<img src={process.env.PUBLIC_URL + "./GoGreenHome/3.png"} className=" absolute mt-[330px] lg:mt-[200px]"></img>

				<img src={process.env.PUBLIC_URL + "./sunrise.jpg"} className="xl:mt-[1250px] md:mt-[1070px] lg:mt-[940px] mt-[865px] w-full h-fit xl:h-[750px]"></img>

	  		</div>










			
			{/* <div class="w-full h-full border-0">
				<img src={footer}></img>

				</div>  */}
		



		</div>
	);
}

export default GoGreen;
