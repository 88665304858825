import { BrowserRouter as Router, Route, Link, useParams} from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PocketBase from 'pocketbase';



function New_Launch(props) {


    const [records, setRecords] = useState([]);

    const [scrollPosition, setScrollPosition] = useState(0);
    const galleryRef = useRef(null);
  



	useEffect(() => {
    
		const pb = new PocketBase('https://api.akaiindia.shop');
	
		const fetchRecords = async () => {
		  try {
			const fetchedRecords = await pb.collection('New_Launch').getFullList({ sort: 'created' });
			setRecords(fetchedRecords);
		 
		  } catch (error) {
			console.error('Error fetching records:', error);
		  }
		};
	
		fetchRecords();
	  }, []);



    //Screen Size Check Part  start
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkWindowSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  useEffect(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);

    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);
  //Screen Size Check Part  End






    const scrollLeft = () => {
      const cardWidth = 650; // Replace with the actual width of your ImageCard
      const newPosition = galleryRef.current.scrollLeft - cardWidth;
      setScrollPosition(Math.max(newPosition, 0));
      galleryRef.current.scrollLeft = newPosition;
  };
  
  const scrollRight = () => {
      const cardWidth = 650; // Replace with the actual width of your ImageCard
      const newPosition = galleryRef.current.scrollLeft + cardWidth;
      setScrollPosition(newPosition);
      galleryRef.current.scrollLeft = newPosition;
  };




	return (	

        <div className="w-screen h-full bg-slate-000 border-0 flex flex-col md:flex-col justify-center items-center">    

                
                
                <p className="text-4xl font-bold md:text-6xl md:font-bold my-3 md:my-9" >New Launch</p>
                
                <div className="w-screen h-full border-0 flex flex-col md:flex-row justify-center items-center">    

                <button onClick={scrollLeft} className={`m-1 h-[100px] w-[30px] hover:h-[120px] duration-200 bg-black text-white p-2 rounded ${isSmallScreen ? 'hidden' : ''}`}> &lt; </button>
            
                    <div className="w-screen md:w-[90%] grid grid-rows-1 grid-flow-col bg-slate-000 mb-9 p-3 overflow-x-scroll overflow-y-hidden" ref={galleryRef} style={{ scrollBehavior: 'smooth' }}>      

                    { records.map((records, index) => (
                        <ImageCard  key={index} URL={records.Product_URL} collectionId={records.collectionId} id={records.id} Image={records.Image} Category_Name={records.Category_Name}/>
                    ))}

                </div>
                
                <button onClick={scrollRight} className={`m-1 h-[100px] w-[30px] hover:h-[120px] duration-200 bg-black text-white p-2 rounded ${isSmallScreen ? 'hidden' : ''}`}> &gt; </button>
                </div>
            
            </div>
	);
}  
  
  



const ImageCard = (props) => {

    const imageUrl = `https://api.akaiindia.shop/api/files/${props.collectionId}/${props.id}/${props.Image}`;

	return (
        <div className='flex flex-col items-center justify-center'>
        <div className="w-[325px] h-[200px] md:w-[650px] md:h-[400px] bg-slate-200 overflow-hidden rounded-xl m-1"> 
        <Link to={props.URL}>
            <img className='object-cover w-full' src={imageUrl} alt='New launch'/>
        </Link>
        
        </div>
            
            <p className='text-xl font-semibold my-3'>{props.Category_Name}</p>
	
    </div>
    );
  };





export default New_Launch;
