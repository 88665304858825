import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PocketBase from 'pocketbase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Import the bubble theme
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';





function BlogsRead() {


  const [records, setRecords] = useState({});
  const [records2, setRecords2] = useState([]);
  const [value, setValue] = useState('');
  const [dateParts, setDateParts] = useState([]);
  
  const { title, blogId } = useParams();


  useEffect(() => {
    const pb = new PocketBase('https://api.akaiindia.shop');

    const fetchRecords = async () => {
      try {
        const fetchedRecords = await pb.collection('Blogs').getOne(blogId, {
          expand: 'Specs_Images,relField2.subRelField',
        });
        setRecords(fetchedRecords);
        setDateParts(fetchedRecords.created.split(' '));
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    fetchRecords();
  }, [blogId]);





  useEffect(() => {
    const pb = new PocketBase('https://api.akaiindia.shop');

    const fetchRecords = async () => {
      try {
        const fetchedRecords = await pb.collection('Blogs').getFullList({ sort: '-created' });
        setRecords2(fetchedRecords);
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    fetchRecords();
  }, []);

  
  
  
  
  function RichText(props) {
    setValue(props.text);
    return <ReactQuill style={{ fontSize: '60px' }} theme="bubble" value={value} readOnly preserveWhitespace />;
  }




  const imageUrl = `https://api.akaiindia.shop/api/files/${records.collectionId}/${records.id}/${records.Title_Image}`;



  return (
    <div className="w-full flex flex-col justify-center bg-black md:pt-[150px] pt-[70px]">
      
      <div className="flex justify-center rounded-xl  bg-gray-000">
          
          <div className="md:w-[75%] w-[100%] bg-white md:p-12 p-6 rounded-xl overflow-hidden">
         
          <div > <img src={imageUrl} className='w-full'/></div> 

          <p className="xl:md:text-[3vw] md:text-[4vw] text-[6vw] font-bold my-6">{records.Title}</p>
          <p className="md:text-2xl text-xl font-base my-6">{records.Subtitle}</p>
          <p className="md:text-lg text-sm my-6">{dateParts[0]}</p>
          
          <div className="w-full bg-black h-[1px] user-select-none"></div>


          <div className="w-full pt-9"></div>
            <RichText class="user-select-none" text={records.Text2} />
          </div>

      </div>

      <p className="md:text-3xl  text-xl my-12 text-white text-center">Read More Blogs</p>

      <div className="md:h-[220px] h-[200px] flex overflow-x-scroll md:mb-[100px] mb-[30px]">
        {records2.map((item) => (
            <Link className='' to={`/blog/${item.Title.toLowerCase().replace(/\s+/g, '-').replace(/[(),:]/g, '')}/${item.id}`}>
            <BlogCard key={item.id} Title={item.Title} Subtitle={item.Subtitle} />
          </Link>        
          ))}
      </div>
    </div>
  );
}




function BlogCard(props) {
  
  return (
    <div className="transform hover:bg-gray-100 duration-200 hover:h-[180px] w-[250px] md:w-[350px] h-[170px] m-2 shrink-0 p-5 bg-gray-100 rounded-lg flex-initial">
     
      <p className="text-md md:text-xl md:text-lg line-clamp-2 font-bold">{props.Title}</p>
     
      <p className="text-xs md:text-sm truncate my-2">{props.Subtitle}</p>
     
      <div className="flex my-5">
        <div className="hover:w-[50px] w-[40px] duration-200 hover:text-[#e4002b]">Read</div>
      </div>
    
    </div>
  );
}



export default BlogsRead;
