
import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';




function WhoWeAre() {

    return (
      <div class="w-full h-full bg-red-000 pt-[70px] overflow-hidden">
  
  
          <div class="w-full h-full border-0 overflow-hidden">
              <img class='object-contain' src={process.env.PUBLIC_URL + "./WhoWeAre (4).png"}></img>
          </div>
          
  
  
        
        <div class="w-screen border-0 flex justify-center">
          <div class="lg:w-[75%] w-[85%] border-0 my-[50px]">
            <p className="text-[#e4002b] font-bold text-center text-4xl md:text-5xl xl:text-8xl mb-[40px]">The Story of Akai</p>
  					<p className="text-center text-gray-800 text-base md:text-lg xl:text-3xl/10 md:font-thin">Akai Electronics was born in Japan in 1929 with a mission to honor people and their values by creating unparalleled experiences. After over 90+ years, we’re innovating, constantly developing and creating designs that fit into your lifestyle seamlessly. Akai still manages to drive on its mission and vision created years ago to serve people well. </p> 
          </div>
        </div>


        
        <div class="w-screen border-0 flex justify-center">
          <div class="lg:w-[63%] w-[85%] border-0 my-[100px]">
           
            <p className="text-black font-bold text-center text-2xl md:text-4xl xl:text-6xl/10 md:mb-[20px] xl:mb-[40px]">AKAI - A REASON FOR BEING</p>
  				
          	<p className="text-center  leading-relaxed  text-gray-800 text-base md:text-2xl xl:text-3xl/10 md:font-thin">The essence of the brand falls right into the innovation, strength, quality and reliability of a warrior.</p> 
           
            <img class='object-contain my-9' src={process.env.PUBLIC_URL + "./WhoWeAre (3).png"}></img>
          
          </div>
        </div>
      


          <div class="w-screen border-0 flex flex-col md:flex-row justify-center">
          <div class="lg:w-[45%] w-screen border-0 p-9">
            <p className="text-black font-bold text-center md:text-left text-5xl md:text-7xl mb-[20px]">Akai in India</p>
  					<p className="text-left  leading-relaxed  text-gray-800 md:text-2xl text-1xl sm:font-base font-bold mb-[20px]">Hometech Digital Limited embarked on its remarkable journey in 2016 with the founding of the company. Notably, the firm's journey also included the acquisition of Akai India in 2016, reinforcing the firm’s position as a dynamic and expanding player in the electronics market. </p> 
  					<p className="text-left  leading-relaxed  text-gray-800 md:text-lg text-md font-base">The trajectory has been characterized by consistent expansion, ingenuity, and strategic partnerships, allowing AKAI to firmly establish its foothold and deliver a diverse array of premium products to valued customers. In the same year, we unveiled our pioneering Akai LED TV model, marking our entry into the electronics market. We expanded our product portfolio to include Akai Semi-automatic Washing Machines and Akai Air Conditioners, solidifying our presence in the home appliance sector.  Akai enriched its offerings further by introducing Akai Audio and Mobile Accessories. Our strategic vision continued to bear fruit when we formed a pivotal alliance with Amazon, enabling ourselves to establish a widespread presence across India with Akai service centers spanning major states. 
Our collaboration with Amazon reached new heights as we co-developed and released the innovative Akai Fire TV and strengthened our distribution channels through a strategic alliance with Reliance Digital stores, augmenting the reach. Our commitment to excellence marked a significant milestone as Akai launched Fully Automatic Washing Machines, catering to evolving customer needs, and introduced the cutting-edge Akai Google TV, further diversifying our product lineup and technological capabilities.</p> 
          
          </div>

          <div class="lg:w-[40%] w-screen border-0">
            <img class='object-contain my-9 scale-[80%]' src={process.env.PUBLIC_URL + "./WhoWeAre (2).png"}></img>

          </div>

        </div>



        <div class="w-screen border-0 flex justify-center flex flex-col justify-center items-center space-y-5 md:space-y-9 mb-9 lg:mb-3">
       
            <p className="text-black font-bold text-center md:text-4xl text-2xl mx-[40px] lg:mx-[100px]">We honor you, your time and your experiences; thus, we say,</p>
            <img class='lg:w-[50%] w-[70%]' src={process.env.PUBLIC_URL + "./WhoWeAre (1).png"}></img>     
       
        </div>
      

        <div class="w-screen flex justify-center bg-red-000">

            <div class="lg:w-[90%] flex justify-center items-center divide-solid lg:divide-x-2 lg:flex-row flex-col ">
             
              <div class="w-[100%] lg:w-[25%] lg:h-[200px] h-[100px] border-0 lg:my-[50px] my-[00px] bg-red-000 lg:p-9 p-3"> 
                  <p className="text-black font-bold text-center text-3xl lg:text-3xl xl:text-5xl lg:mb-[20px] mb-[10px]">#1 Brand </p>
                  <p className="text-black font-base text-center lg:text-lg text-1xl ">Accounted for Legacy and Iconic Units</p>
              </div>
              
              <div class="w-[100%] lg:w-[25%] lg:h-[200px] h-[100px] border-0 lg:my-[50px] my-[00px] bg-red-000 lg:p-9 p-3"> 
                  <p className="text-black font-bold text-center text-3xl lg:text-3xl xl:text-5xl lg:mb-[20px] mb-[10px]">90+ years </p>
                  <p className="text-black font-base text-center lg:text-lg text-1xl ">of Trusted Customers</p>
              </div>
              
              <div class="w-[100%] lg:w-[25%] lg:h-[200px] h-[100px] border-0 lg:my-[50px] my-[00px] bg-red-000 lg:p-9 p-3"> 
                  <p className="text-black font-bold text-center text-3xl lg:text-3xl xl:text-5xl lg:mb-[20px] mb-[10px]">100+</p>
                  <p className="text-black font-base text-center lg:text-lg text-1xl ">Akai Products</p>
              </div>
              
              <div class="w-[100%] lg:w-[25%] lg:h-[200px] h-[100px] border-0 lg:my-[50px] my-[00px] bg-red-000 lg:p-9 p-3"> 
                  <p className="text-black font-bold text-center text-3xl lg:text-3xl xl:text-5xl lg:mb-[20px] mb-[10px]">Infinite</p>
                  <p className="text-black font-base text-center lg:text-lg text-1xl ">Stories Collected and Connected</p>
              </div>
            
            </div>

        </div>


        <div class="w-screen border-0 flex justify-center mb-[200px]">
          <div class="lg:w-[70%] w-[85%] border-0 my-[50px]">
            <p className="text-black font-bold text-center text-5xl md:text-7xl mb-[30px]">VISION</p>
  					<p className="text-center  leading-relaxed  text-gray-800 md:text-2xl text-base md:font-thin">Akai’s insight lies in the intention to elevate everyday experiences and bring joy-inducing products to every home. We as a Home Brand don’t look afield for inspiration and never have.  We always begin with familiar experiences inside our homes. At the heart of all this are our stories and the people behind them. The essence of every expression has come at each step with you and through you- and Akai will carry each part of the stories of its people throughout generations. </p> 
          </div>
        </div>





        {/* <div class="w-full h-full border-0">
  
          </div>  */}
      
  
  
  
      </div>
    );
  }




export default WhoWeAre;

