import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import BlogPageView from './components/BlogPageView';



function Blogs() {


  const [records, setRecords] = useState([]);


  useEffect(() => {

    const pb = new PocketBase('https://api.akaiindia.shop');

    const fetchRecords = async () => {
      try {
        const fetchedRecords = await pb.collection('Blogs').getFullList({ sort: '-created' });
        setRecords(fetchedRecords);

      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    fetchRecords();
  }, []);





  //Sorting Filter
  const SortBar = (props) => {

    const SortCategory = async ({ datk }) => {
      const pb = new PocketBase('https://api.akaiindia.shop');
      const fetchedRecords = await pb.collection(props.collection).getFullList({ sort: props.sortVal });
      setRecords(fetchedRecords);
    };

    return (

      < div class='mx-5 p-5 hover:p-6 rounded-full m-auto flex items-center h-5 hover:border-2 border border-gray-700 hover:border-[#e4002b] duration-300'>

        <button class='mx-5' onClick={SortCategory}>{props.bttnName}</button>
      </div>);
  }




  return (


    <div class=' pt-[60px] w-full h-full flex flex-wrap justify-center overflow-hidden'>

      <div class='flex my-9 md:my-16 font-bold bg-gray-000 w-screen m-auto object-contain justify-center items-center' >
        <p className='text-5xl md:text-9xl'> Blogs</p>
      </div>




      <div class=' pt-[30px] w-[95%] h-full flex flex-row flex-wrap justify-center items-center mb-[100px]'>

        < div class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-3 place-content-center md:place-content-start w-full md:h-full sm:w-max " >
          {records.map((records, index) => (

            <Link className='' to={`/blog/${records.Title.toLowerCase().replace(/\s+/g, '-').replace(/[(),:]/g, '')}/${records.id}`}>
              <BlogPageView created={records.created} collectionId={records.collectionId} id={records.id} Title_Image={records.Title_Image} Title={records.Title} Subtitle={records.Subtitle} />
            </Link>

          ))}
        </div>

      </div>


    </div>

  );
}



export default Blogs;



