import React from 'react';
import { Route, Routes, Redirect, Link } from 'react-router-dom';
import ScrollUpButton from './Pages/components/ScrollUpButton';

import { useAuth0 } from "@auth0/auth0-react";


import Home from './Pages/Home';
import GoGreen from './Pages/GoGreen';
import Story from './Pages/Story';
import WhoWeAre from './Pages/WhoWeAre';
import Dealer from './Pages/Dealer';
import ContactUs from './Pages/ContactUs';


import TvView from './Pages/TvView';
import AudioView from './Pages/AudioView';
import AcView from './Pages/AcView';
import WashingMachineView from './Pages/WashingMachineView';

import Product from './Pages/Product';

import Tv from './Pages/Tv';
import Ac from './Pages/Ac';
import WashingMachine from './Pages/WashingMachine';
import Audio from './Pages/Audio';
import InteractiveDisplays from './Pages/InteractiveDisplays';


import Blogs from './Pages/Blogs';
import BlogsRead from './Pages/BlogsRead';

import Newsroom from './Pages/Newsroom';
import NewsRead from './Pages/NewsRead';


import Footer from './Pages/components/Footer';
import Navigation from './Navigation';

import Admin from './Pages/Admin_Pages/Admin';
import ScrollToTop from './Pages/components/ScrollToTop';
import ReachUs from './Pages/components/ReachUs';
import NotFound from './Pages/NotFound';



function App() {
  return (
    <div >

      <div class="absolute z-10"><Navigation /> </div>
      <div class="absolute z-50"><ScrollUpButton /> </div>

      <ScrollToTop>
       
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/go-green" element={<GoGreen />} />
          <Route path="/story" element={<Story />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/find-dealers" element={<Dealer />} />
          <Route path="/contact" element={<ContactUs />} />


          <Route path="/tv" element={<Tv />} />
          <Route path="/air-conditioners" element={<Ac />} />
          <Route path="/audio" element={<Audio />} />
          <Route path="/washing-machine" element={<WashingMachine />} />
          <Route path="/interactive-displays" element={<InteractiveDisplays />} />



          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:title/:blogId" element={<BlogsRead />} />

          <Route path="/newsroom" element={<Newsroom />} />
          <Route path="/newsroom/:title/:newsId" element={<NewsRead />} />



          <Route path="/tv/:title/:productId" element={<TvView />} />
          <Route path="/audio/:title/:productId" element={<AudioView />} />
          <Route path="/air-conditioners/:title/:productId" element={<AcView />} />
          <Route path="/washing-machine/:title/:productId" element={<WashingMachineView />} />

          <Route path="/product/:category/:subcategory" element={<Product />} />



          <Route path="/admin" element={<Admin />} />


          <Route path="*" element={<NotFound />} />

        </Routes>
      </ScrollToTop>

      <ReachUs />
      <Footer />


    </div>

  );
}





export default App;
