import React, { useState, useEffect } from 'react';

const ScrollUpButton = () => {
  const [isVisible, setIsVisible] = useState(false);


  const isMobile = window.innerWidth < 500;



  useEffect(() => {
    // Add scroll event listener
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // Adjust the scroll position at which the button becomes visible 
      setIsVisible(scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollUp = () => {
    // Smooth scroll to the top
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {isVisible && (
        <button onClick={handleScrollUp} class='fixed z-9 
        
              bottom-[55px] right-[15px] w-[50px] h-[50px] 
              
              md:bottom-[80px] md:right-[20px] md:w-[70px] md:h-[70px] 
              
               bg-white border-white border-2 text-white text-3xl md:text-sm rounded-full flex justify-center items-center'>


          <svg class="fill-red-500 w-full h-full" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z" />
          </svg>


          {/* {isMobile ? '⭱' : 'Scroll Up'} */}

          {/* <img className='fill-blue-500' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/wsib6xu8y9358xk/plain_arrow_up_UzuN0U07dV.svg'/> */}
        </button>
      )}
    </div>
  );
};

export default ScrollUpButton;



