import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PocketBase from 'pocketbase';
import ProductImageViewer from './components/ProductImageViewer';
import ImageMapping from './components/ImageMapping';
import KeyFeatureMapping from './components/KeyFeatureMapping';
import { SpecsRow, BuyButton, AmountCal, ProductInfoCardDesk, ProductInfoCard, Separation, FaqItem } from './components/ProductViewUtility';



function TvView() {


	const { productId } = useParams();

	console.log('this is' + useParams())

	const [records, setRecords] = useState([]);
	const [records2, setRecords2] = useState([]);
	const [records3, setRecords3] = useState([]);
	const [keyfeature, setKeyfeature] = useState([]);


	const overviewRef = useRef(null);
	const specificationRef = useRef(null);
	const keyfeatureRef = useRef(null);
	const FaqRef = useRef(null);


	const scrollToOverview = () => { overviewRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToSpecification = () => { specificationRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToKeyfeature = () => { keyfeatureRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToFaq = () => { FaqRef.current.scrollIntoView({ behavior: 'smooth' }); };






	useEffect(() => {

		const pb = new PocketBase('https://api.akaiindia.shop');

		const fetchRecords = async () => {
			try {
				const fetchedRecords = await pb.collection('tv').getOne(productId, { expand: 'Specs_Images,relField2.subRelField' },);
				setRecords(fetchedRecords);
				setRecords2(fetchedRecords.Specs_Images);
				setRecords3(fetchedRecords.Listing_Image);
				setKeyfeature(fetchedRecords.Key_Features);


			} catch (error) {
				console.error('Error fetching records:', error);
			}
		};

		fetchRecords();
	}, []);


	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Function to handle scroll event
		const handleScroll = () => {
			// Calculate the scroll position
			const scrollPosition = window.scrollY;

			// Set visibility based on scroll position
			if (scrollPosition > window.innerHeight * 1.2) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		// Add scroll event listener when component mounts
		window.addEventListener("scroll", handleScroll);

		// Remove scroll event listener when component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []); // Empty dependency array to run effect only once when component mounts















	return (
		<div class="w-full overflow-hidden">

			<div class=" bg-slate-000 flex flex-row pt-[70px]">

				{/* Listing View */}

				<ProductImageViewer class='my-[50px]' images={records3} records={records} />

			</div>

			<div class='w-screen flex flex-col items-center justify-center sticky mt-5'>

				<div class="w-screen h-full md:h-[100px] flex flex-row flex-wrap justify-center">

					<div class=" w-screen flex justify-center hidden md:flex md:block">
						<ProductInfoCardDesk Product_Name={records.Product_Name} SKU={records.SKU} Screen_Size={records.Screen_Size} Model={records.Model} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

					<div class=" w-screen md:hidden visible">
						<ProductInfoCard Product_Name={records.Product_Name} SKU={records.SKU} Screen_Size={records.Screen_Size} Model={records.Model} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

				</div>

				{/* Scroll navigation */}
				<div class="md:w-[90%] w-screen md:h-full bg-gray-200 flex md:flex-row justify-center mt-2 md:rounded-full md:space-x-12 md:space-y-0 space-x-5 p-5 md:p-0">

					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToOverview}>Overview</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToKeyfeature}>Key features</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToSpecification}>Specification</button>
					{/* <button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToFaq}>FAQ</button> */}

				</div>

			</div>





{/*pop up bar  */}

<div className="fixed xl:top-[70px] lg:top-[109px] md:top-[106px] bottom-[0px] left-0 w-full h-max flex flex-col justify-center z-40">
				{/* Popover content */}
				{isVisible && (
					<>
						<div class="w-[100%] h-fit py-3 bg-black flex flex-col xl:flex-row flex-wrap justify-center items-center">

							<div class="flex xl:pl-5 border-0 mb-3 xl:mb-0 w-[100%] justify-center md:justify-center xl:justify-start items-center xl:w-[50%] text-white font-normal">
								<h1 className='text-sm font-light md:text-2xl md:font-semibold'>	{records.Product_Name} </h1>
							</div >

							<div class="flex flex-col md:flex-row w-[100%] xl:w-[50%] text-white  border-0  font-normal space-x-3 items-center justify-center xl:justify-end xl:pr-5">
							
							<div className='flex justify-center items-center space-x-3'>
								<p className='text-white text-lg md:text-2xl '>	₹{records.Price_Offered}/-   </p>
								<p class="text-red-000 line-through text-xs md:text-base">₹ {records.Price}/- </p>
								<AmountCal className='' a={records.Price_Offered} b={records.Price} />
							</div>
							
								<div className='mt-2 md:mt-0'> <BuyButton product={records.Product_Name} className='' /> </div>

							</div >


						</div>


						<div class="md:w-[90%] w-screen md:h-full bg-gray-200 flex md:flex-row justify-center mt-2 md:rounded-2xl lg:rounded-full md:space-x-12 md:space-y-0 space-x-5 p-5 md:py-3">

							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToOverview}>Overview</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToKeyfeature}>Key features</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToSpecification}>Specification</button>
							{/* <button class="hover:text-[#e4002b] duration-200 text-base md:text-2xl " onClick={scrollToFaq}>FAQ</button> */}

						</div>

					</>


				)}
			</div>









			<div ref={overviewRef} class="w-screen h-auto flex bg-slate-000 mt-[3vw]">
				<p class="text-3xl md:text-5xl mb-[3vw]  font-bold m-auto">Overview</p>
			</div>

			<div class="w-screen h-full pb-[50px] lg:pb-[100px] bg-black-400 flex flex-col item-center justify-center">

				<div>
					<text class="px-[8%] lg:px-[20%] bg-gray-000 flex text-center">{records.overview}</text>
				</div>
			</div>

			<div ref={keyfeatureRef} class="w-screen h-auto flex flex-col bg-slate-000 mb-[2vw]">
				<p class="text-3xl md:text-5xl mb-[3vw] font-bold m-auto ">Key Features</p>
				<KeyFeatureMapping category='TV' ext='.jpg' keyfeature={keyfeature} />

			</div>


			{/* showing A+ Images here  */}
			<div class="md:grid md:grid-cols-3 md:gap-4 items-center justify-center">
				<ImageMapping records={records} records2={records2} />
			</div>

			<div ref={specificationRef} class="w-screen h-[150px] flex bg-slate-000">
				<p class="text-3xl md:text-5xl mb-[3vw]  font-bold m-auto">Specification</p>
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Display</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Screen Size:' val={records.Screen_Size} />
				<SpecsRow label='Display Type:' val={records.Display_Type} />
				<SpecsRow label='Refresh Rate:' val={records.Refresh_Rate} />
				<SpecsRow label='Resolution:' val={records.Resolution} />
				<SpecsRow label='Colours:' val={records.Colours} />
				<SpecsRow label='HDR Compatibility:' val={records.HDR_Compatibility} />
				<SpecsRow label='Colour Gamut:' val={records.Colour_Gamut} />
				<SpecsRow label='4K Upscalling:' val={records.fourK_Upscalling} />
				<SpecsRow label='Digital Noise Reduction:' val={records.Digital_Noise_Reduction} />
				<SpecsRow label='Micro Dimming:' val={records.Micro_Dimming} />
				<SpecsRow label='Dynamic Signal Calibration:' val={records.Dynamic_Signal_Calibration} />
				<SpecsRow label='Blue Light Reduction:' val={records.Blue_Light_Reduction} />
				<SpecsRow label='Super Brightness:' val={records.Super_Brightness} />
				<SpecsRow label='MEMC:' val={records.MEMC} />
				<SpecsRow label='Dolby Vision:' val={records.Dolby_Vision} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Sound</p>
			</div>


			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Sound Output:' val={records.Sound_Output} />
				<SpecsRow label='Speaker Type:' val={records.Speaker_Type} />
				<SpecsRow label='Dolby Audio:' val={records.Dolby_Audio} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Gaming</p>
			</div>


			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Game Mode:' val={records.Game_Mode} />
				<SpecsRow label='ALLM:' val={records.ALLM} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Features</p>
			</div>



			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Operating System:' val={records.Operating_System} />
				<SpecsRow label='Pre Installed Application:' val={records.Pre_Installed_Application} />
				<SpecsRow label='Voice Assistance:' val={records.Voice_Assistance} />
				<SpecsRow label='Casting:' val={records.Casting} />
				<SpecsRow label='RAM:' val={records.RAM} />
				<SpecsRow label='Storage:' val={records.Storage} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Connectivity</p>
			</div>


			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Wifi:' val={records.Wifi} />
				<SpecsRow label='Bluetooth:' val={records.Bluetooth} />
				<SpecsRow label='HDMI:' val={records.HDMI} />
				<SpecsRow label='USB:' val={records.USB} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Power</p>
			</div>


			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Power Supply:' val={records.Power_Supply} />
				<SpecsRow label='Power Consumption:' val={records.Power_Consumption} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Hardware</p>
			</div>


			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap flex-left">
				<SpecsRow label='CPU:' val={records.CPU} />
				<SpecsRow label='GPU:' val={records.GPU} />
			</div>

			<div class="w-screen h-full pb-[50px] lg:pb-[100px] mt-[100px] bg-black-400 flex flex-col item-center justify-center">

				<div>
					<text class="px-[8%] lg:px-[20%] bg-gray-000 flex text-left">{records.SCO}</text>
				</div>
			</div>



			{/* <div ref={FaqRef} class="w-screen md:h-[150px] flex bg-slate-000">
								<p class=" text-xl lg:text-5xl font-bold m-auto">FAQ (Frequently Asked Questions)</p>
						</div>



							
							<div class="w-screen h-full  md:pb-[100px] pb-[20px] md:mt-[20px] mt-[20px] bg-black-400 flex flex-col item-center justify-center">
								
								<div>
						
								<FaqItem index='1' question="What is GoogleTV?" answer="GoogleTV is OS system from Google"/>
								<FaqItem index='2' question="Why GoogleTV?" answer=" Its Future of TV"/>
								<FaqItem index='2' question="features of GoogleTV?" answer=" shdbvcjdsbvcjhdsbvjhbvsdjhcvcsjvchgasvcgh djdhcvbjhsdvj dshvcbsdjhbhjbfhjsdhfbhj sdjfhvbdsjhfbhsdfhsdkh sddkfbkdsfbksdbfk"/>

						
								</div>
							</div> */}

		</div>
	);
}





// const SpecsRow = (props) => {

// 	return (
// 	  <div class="w-[100%] lg:w-[33%] flex border-0 border-red-500">
// 			<p class="text-left text-gray-700 text-base p-[8px] lg:p-[20px] font-semibold">{props.label} <span class="text-base font-normal p-[20px] m-auto">{props.val}</span></p>  
// 	  </div>
// 	);
//   };




//   const FaqItem = ({ question, answer, index }) => {
// 	const [isOpen, setIsOpen] = useState(false);

// 	const toggleAnswer = () => {
// 	  setIsOpen(!isOpen);
// 	};

// 	return (
// 	  <div className="w-screen flex flex-col justify-center items-center h-full transition ease-in-out delay-150 mb-2">

// 			<div className='lg:w-[70%] w-[95%] overflow-hidden border-2 border-gray-200 rounded-xl transition-all duration-500 ease-in-out text-black'>

// 				<div className=" relative flex transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5" onClick={toggleAnswer}>
// 				<span className='text-red-500 mr-2'>Question:{index}</span> {question} 
// 				<div className='absolute right-5 my-auto'> 
// 				<img className='w-[25px] h-[25px] mr-2' src={process.env.PUBLIC_URL + '/Icons/arrow-down.svg'} alt="My Image" />
// 				</div>
// 				</div>


// 				{isOpen && <div className="transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5"> <span className='text-red-500'>Answer:</span> {answer}</div>}

// 			</div>
// 	  </div>
// 	);
//   };




// //infor of product name price etc   
// const ProductInfoCard = (props) => {


// 	return (
// 		<div class=" w-[97%] bg-black rounded-xl flex flex-row flex-wrap p-[5%] text-white text-middle space-y-2 m-auto ">

// 		<div className='flex flex-col text-center text-lg font-semibold items-center justify-center w-full'>
// 			<p>{props.Product_Name} </p>
// 		</div>

// 		<div className='w-full items-center justify-center flex flex-row text-white text-center text-xs'>
// 			<p>{props.SKU}</p><Sepration/>
// 			<p>{props.Screen_Size}</p><Sepration/>
// 			<p>{props.Model}</p>
// 		</div>


// 		<div class="w-full justify-center flex flex-row w-full  text-2xl font-normal space-x-3 items-center">		
// 			<p>	₹{props.Price_Offered}/- </p>
// 			<p>	<span class="text-red-500 line-through text-sm">₹ {props.Price}/- </span> </p>
// 			<p>  <AmountCal a={props.Price_Offered} b={props.Price}/> </p>		
// 		</div >

// 		<div className='flex justify-center items-center w-full'> 
// 			<BuyButton  className='mt-3' />
// 		</div>

// 	</div>
// 	);
//   };


//   //infor of product name price etc   
//   const ProductInfoCardDesk = (props) => {


// 	return (
// 		<div class="w-[80%] h-[100px] bg-black flex flex-row flex-wrap justify-center items-center rounded-2xl">

// 			<div class="flex flex-col px-[2%] w-[65%] text-white font-normal">
// 				<p className='text-2xl font-semibold'>	{props.Product_Name} </p> <br/>	
// 				<p className='text-lg mt-2'>	{props.SKU} <Sepration/> {props.Screen_Size} <Sepration/> {props.Model} </p>	
// 			</div >

// 			<div class="flex w-[35%]  text-2xl font-normal space-x-3 items-center">
// 				<p className='text-white'>	₹{props.Price_Offered}/- <span class="text-red-500 line-through text-sm">₹ {props.Price}/- </span> 
// 					<AmountCal a={props.Price_Offered} b={props.Price}/>
// 				</p>
// 			<BuyButton  className='ml-3' />

// 			</div >


// 		</div>
// 	);
//   };







//   function Sepration(){ return <span class="mx-[10px] text-[#e4002b]">|</span> }


//   function AmountCal(props){

// 	  const c = props.a - props.b;
// 	  return <span class="mx-[10px] p-1 bg-green-600 text-sm rounded-md">Save ₹{c}/-</span>
//   }



export default TvView;
