import React, { useState, useEffect } from 'react';

function BlogPageView(props) {
 
 
 
  const imageUrl = `https://api.akaiindia.shop/api/files/${props.collectionId}/${props.id}/${props.Title_Image}?thumb=550x0`;
  const [dateParts, setDateParts] = useState([]);

  const isMobile = window.innerWidth < 1024;


  useEffect(() => {
    const formatDate = () => {
      setDateParts(props.created.split(' '));
    };

    formatDate();
  }, [props.created]);



  return (
   
    <div>
      {isMobile ? 
      
      <div className="border rounded-xl w-[100%] h-[115px] flex flex-row overflow-hidden">
    
    
        <div className="w-[35%] bg-gray-500 overflow-hidden">
          <img className="object-cover h-full object-center" src={imageUrl} alt="Blog Cover" />
        </div>
      
        <div className=" w-[65%] h-full bg-gray-100 p-3">
      
          <p className="text-left text-xs">{dateParts[0]}</p>
          <p className="font-bold line-clamp-2 text-left text-sm mt-1">{props.Title}</p>
          <p className="text-xs truncate mt-2">{props.Subtitle}</p>
      
        </div>
  
      </div>

      : 
      
      
      <div className="relative border-2 rounded-xl w-[100%] md:h-[450px] m-1 overflow-hidden">
    
    
        <div className="h-[52%] bg-gray-500 overflow-hidden">
          <img className="object-cover h-full w-full object-center" src={imageUrl} alt="Blog Cover" />
        </div>
      
        <div className=" h-[35%] bg-gray-000 p-5">
      
          <p className="text-left text-md">{dateParts[0]}</p>
          <p className="font-bold line-clamp-2 text-left text-lg mt-3">{props.Title}</p>
          <p className="text-sm truncate mt-2">{props.Subtitle}</p>
      
        </div>

        <div className="mx-5 flex w-[80px] flex-row items-center bottom-5 border rounded-full p-2 ">
            <div className="ml-2 duration-200 hover:text-[#e4002b]">Read </div>
        </div>
      
      </div>
      
      }


    </div>
   
  );
}

export default BlogPageView;
