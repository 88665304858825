import React from 'react';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const CarouselSlick = () => {
  
  const settings = {
    dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true
  };

  return (
    <div>
        <Slider {...settings}>
          
          <div >
            <img className='object-cover w-auto' src={'https://api.akaiindia.shop/api/files/a4yhwstolck2r7b/yrwodm3kkmleevy/slider_1_s_og_ni_kbsc3_zNXQicNryS.webp'}></img>
          </div>
        
          <div>
          <img className='object-cover w-auto' src={"https://api.akaiindia.shop/api/files/a4yhwstolck2r7b/0rkqy9i9xct7dd8/new_slides_semi_BEEDvLx2PY.webp"}></img>
          </div>
          
          <div>
          <img className='object-cover w-auto' src={"https://api.akaiindia.shop/api/files/a4yhwstolck2r7b/i5yfljo1r9fwp77/new_slides_interactive_semi_MQaSLBu0dM.webp"}></img>
          </div>
          
          <div>
          <img className='object-cover w-auto' src={"https://api.akaiindia.shop/api/files/a4yhwstolck2r7b/v0in3pbnnrwkhpl/slider_2_4QcYqGukcM.webp"}></img>
          </div>
          
          <div>
          <img className='object-cover w-auto' src={'https://api.akaiindia.shop/api/files/a4yhwstolck2r7b/8joxew1200ioien/slider_4_S0QvlyjXDo.webp'}></img>
          </div>

          <div>
          <img className='object-cover w-auto' src={'https://api.akaiindia.shop/api/files/a4yhwstolck2r7b/i5qfzxxb0hky3ax/slider_5_nzmfL2oHU5.webp'}></img>
          </div>

          <div>
          <img className='object-cover w-auto' src={'https://api.akaiindia.shop/api/files/a4yhwstolck2r7b/rzxcagpnrwrmj3s/slider_3_IEED0B0dEo.webp'}></img>
          </div>

        </Slider>
      </div>
  );
};

export default CarouselSlick;

