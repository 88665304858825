import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import BannerSlider from './components/BannerSlider';
import { SubCategoryButton, SectionBannercomp } from './components/ProductViewUtility';

import SeoSection from './components/SeoSection';
import SeoFAQ from './components/SeoFAQ';





function Ac() {

	const [records, setRecords] = useState([]);
	const [sortVal, setSortVal] = useState('-created');



	useEffect(() => {
		const pb = new PocketBase('https://api.akaiindia.shop');

		const fetchRecords = async () => {
			try {
				const fetchedRecords = await pb.collection('air_conditioners').getFullList({ sort: sortVal });

				setRecords(fetchedRecords);

			} catch (error) {
				console.error('Error fetching records:', error);
			}
		};

		fetchRecords();

	}, [sortVal]);


	//Sorting Filter
	const SortBar = (props) => {

		const SortCategory = () => { setSortVal(props.sortVal); }

		return (
			< div className='p-3 lg:p-5 hover:px-6 rounded-full w-fit flex items-center h-5 hover:border-2 border border-gray-700 hover:border-[#e4002b] duration-300'>

				<button className='text-xs md:text-xs md:font-semibold xl:text-base' onClick={SortCategory}>{props.bttnName}</button>

			</div>
		);
	}





	return (
		<div class=' pt-[60px] w-auto h-full flex flex-wrap justify-center overflow-hidden '>

			{/* HERO */}
			<div class='bg-fixed bg-gray-000 w-full h-fit overflow-hidden object-contain' >

				<BannerSlider bannersId='rvn5va9q5da0n97' />

			</div>


			<div class='h-fit  w-full my-10 flex md:flex-row flex-col items-center justify-center space-y-5 md:space-y-0 space-x-5'>

				<SubCategoryButton category='air-conditioners' subcategory='Window' />
				<SubCategoryButton category='air-conditioners' subcategory='Split-Inverter' />
				<SubCategoryButton category='air-conditioners' subcategory='Split-Fixed-Speed' />

			</div>


			<div className='bg-gray-000 w-full  mb-[00px] flex flex-wrap'>
				
				<div class=' pt-[0px] w-fit h-full flex flex-row flex-wrap justify-center bg-red-000'>

					<ProductSection items={records} name='split-inverter' />

					<ProductSection items={records} name='split-fixed-speed' />

					<ProductSection items={records} name='window' />
				
				</div>

			</div>

			<SeoSection subcategory='air-conditioners' />
            <SeoFAQ subcategory='air-conditioners'/>        


		</div>
	);
}





const ProductSection = (props) => {


	const [filter, setFilter] = useState(props.name);

	const filteredItems = props.items.filter(item => item.Category.toLowerCase().includes(filter.toLowerCase()));


	return (
		<div className='flex flex-col items-center my-[25px] md:my-[50px] h-max'>

			{/* {filteredItems.length > 0 && (
				<div className='w-[95%] h-full bg-red-000 rounded-2xl overflow-hidden mx-auto'>
					<img className='w-fit' src={props.banner} />

				</div>
			)} */}
								<SectionBannercomp name={props.name}/>


			<div class="grid grid-cols-2 md:grid md:grid-cols-3 md:gap-3 md:place-content-start md:h-full w-full md:w-max">

				{filteredItems.map((item, index) => (

					<Link to={`/air-conditioners/${item.URL_Name.toLowerCase().replace(/\s+/g, '-').replace(/[()]/g, '').replace(/[_]/g, '-')}-${item.SKU.toLowerCase()}/${item.id}`} >

						<AcCard
							collectionId={item.collectionId}
							id={item.id} index={index}
							mainimage={item.mainimage}
							product={item.Product_Name}
							model={item.Model}
							CapacityInTons={item.CapacityInTons}
							StarRating={item.StarRating}
							Category={item.Category}
							sku={item.SKU}
							Price={item.Price}
							Price_Offered={item.Price_Offered} />
					</Link>
				))}

			</div>
		</div>
	);
}




function Sepration() { return <span class="mx-[10px] text-[#e4002b]">|</span> }




function AmountCal(props) {

	const c = props.a - props.b;
	return <span class="mx-[10px] p-1 bg-green-200 text-sm rounded-md">Save ₹{c}/-</span>

}




function AcCard(props) {


	const url = 'https://api.akaiindia.shop/api/files/' + props.collectionId + '/' + props.id + '/' + props.mainimage;


	return (

		<div class='w-[90%] xl:w-[480px] lg:w-[300px] h-auto md:w-[220px] m-auto my-2 md:m-1 border-2 md:border-0 hover:shadow-md rounded-xl overflow-hidden'>

			<div class='w-full h-[78%] bg-gray-200 overflow-hidden' >
				<img class='' src={url} alt={"MainImage_" + props.index} />
			</div>

			<div class='w-full h-[22%] bg-gray-100 p-2'>

				<p class='text-md font-semibold md:text-xl text-center mb-[5px] font-'>{props.product}</p>
				<p class=' text-gray-700 md:text-md text-xs text-center'>{props.CapacityInTons}Ton<Sepration />{props.StarRating} Star Rating<Sepration />{props.model}<Sepration />{props.sku}</p>

				< div class="flex justify-center my-2">
					<p class='text-xl md:text-2xl font-semibold text-center '>
						<span class="font-bold"> ₹{props.Price_Offered}/-</span>
						<span class="text-red-500 line-through text-sm md:text-xl"> ₹{props.Price}/-</span> <br />
						<AmountCal a={props.Price} b={props.Price_Offered} />
					</p>
				</div>

			</div>

		</div>
	);
}







//componenet to render Section Banners




export default Ac;
