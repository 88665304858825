import React from 'react';

function KeyFeatureMapping(props) {
  return (
    <div className='w-[90%] bg-red-000 flex flex-wrap justify-center items-center md:m-9 border-gray-300 border-b-2 pb-[30px] md:pb-[50px] mx-auto'>
      {props.keyfeature.map((item, index) => (
        <KeyShow key={index} category={props.category} img={item} ext={props.ext}/>
      ))}
    </div>
  );
}

const KeyShow = (props) => {
  const imagePath = `${process.env.PUBLIC_URL}/Products icons/${props.category}/${props.img}${props.ext}`;

  return (
    <div className="overflow-hidden w-[95px] md:w-[150px] text-center text-white m-2 md:m-3 border rounded-xl bg-gray-000">
      <div>
        <img src={imagePath} alt={props.img} />
      </div>
    </div>
  );
};


export default KeyFeatureMapping;