
import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';




function InteractiveDisplays() {






  const [imageSlidermob, setImageSlidermob] = useState([
    "./Interactive/SlideMob-(1).webp",
    "./Interactive/SlideMob-(2).webp",
    "./Interactive/SlideMob-(3).webp"
  ]);


  const InteractiveSlider = (props) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [index, setIndex] = useState(0);

    useEffect(() => {
      if (currentImage !== index) {
        setCurrentImage(index);
      }
    }, [index, currentImage]);

    const handleNextImage = () => {
      if (index < props.imageSlider.length - 1) {
        setIndex(prevIndex => prevIndex + 1);
      }
    };

    const handlePreviousImage = () => {
      if (index > 0) {
        setIndex(prevIndex => prevIndex - 1);
      }
    };

    return (
      <div className="w-screen  mt-0 border-0 flex flex-col items-center bg-red-000">

        <div className="w-full bg-gray-000 grid grid-cols-8 gap-4 items-center justify-center mt-[50px]">

          <button className="text-3xl hover:text-4xl p-2 duration-200" onClick={handlePreviousImage}> ❮ </button>

          <img title='akai-interactive-panel-display' className="w-[100%] m-auto rounded-lg col-span-6" src={process.env.PUBLIC_URL + props.imageSlider[currentImage]} alt={`akai-interactive-panel-display ${currentImage + 1}`} />

          <button className="text-3xl hover:text-4xl p-2 duration-200" onClick={handleNextImage}>❯</button>

        </div>

        <div className="flex space-x-5 justify-center p-3 rounded-xl">
          {props.imageSlider.map((image, idx) => (
            <img title='akai-interactive-panel-display'
              key={idx}
              src={process.env.PUBLIC_URL + image}
              alt={`akai-interactive-panel-display ${idx + 1}`}
              className={`w-[20%] rounded-md cursor-pointer ${idx === currentImage ? 'w-[150px] border border-black' : ''}`}
              onClick={() => setIndex(idx)}
            />
          ))}
        </div>
      </div>
    );
  };








  return (
    <div class="w-full h-full bg-Black pt-[70px] overflow-hidden">


      <div class="relative w-full h-full border-0 overflow-hidden flex justify-center items-center">

        <h3 className='absolute text-3xl top-0 my-[50px] text-center'>
          Welcome to <br /> <span className='font-bold'>Akai Interactive Panel Displays</span>
        </h3>

        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain mt-[100px]' src={process.env.PUBLIC_URL + "./Interactive/main.webp"}></img>

        <h3 className='absolute text-center text-xl font-semibold bottom-[10px] mx-[30px]'>
          Make headway for<br />a new era of Smart Education<br />and Workspace.
        </h3>

      </div>


      <div class="relative w-full h-full border-0 overflow-hidden flex justify-center mt-[-40px]">
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display.webp"}></img>
      </div>


      <div class="relative w-full h-full border-0 flex justify-center mb-[500px]">


        <h3 className='absolute text-3xl top-0 my-[50px] text-center'>
          From <span className='font-bold'>Classroom</span> to <span className='font-bold'>Boardroom</span>,<br />
          <span className='text-xl'>Redefining Interactive Excellence.</span>
        </h3>


        {/* <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain mt-[150px]' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (4).webp"}></img> */}


        <div className='absolute bottom-[-420px] bg-black w-[90%] h-max rounded-3xl py-9 px-[20px] z-50'>

          <h3 className='text-white text-3xl font-bold top-9 mb-[20px] text-center'>Innovate. Interact. Inspire.</h3>

          <p className='text-white text-base font-thin text-center'>
            At Akai India, we understand the pivotal role of seamless communication and virtual experience in today's dynamic world. Our state-of-the-art Interactive Panel transcends boundaries, catering to the diverse needs of education, business, retail, healthcare, hospitality, corporate and government sectors.
            <br className='mb-5' /> Elevate your interactive experience with our cutting-edge technology designed to empower, engage, and inspire. </p>

        </div>

      </div>

      <h3 className='text-3xl font-bold mb-[20px] text-center'>
        Key Featurs
      </h3>

      <div class="w-screen overflow-x-scroll mb-9">

        <div class='w-[1200px] rounded-2xl border-2 overflow-hidden m-9' >
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' src={process.env.PUBLIC_URL + "./Interactive/Button-Panel.webp"}></img>
        </div>

      </div>



      <div class="px-[10px] grid grid-cols-2 gap-2">

        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (1).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (2).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (3).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (4).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (5).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (6).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (7).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (8).webp"} />

      </div>




      {/* 4K Display*/}

      <div class="relative w-full h-full my-[5vw] overflow-hidden flex flex-col justify-start items-center">

        <div className='w-[100%] flex justify-start pr-9'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain ' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (2).webp"}></img>
        </div>

        <div className='w-[95%] px-12 space-y-3 text-center'>
          <h3 className=' text-[8vw] font-bold'>
            4K Display                </h3>
          <p className=' text-[4.5vw] '>
            Enhance visual clarity and visibility across wide angles by incorporating the optically bonded glass layer, ensuring a sharp and clear picture. It also provides eye protection for all day-long working.                </p>
        </div>

      </div>









      {/* Multi-touch Responsive */}

      <div class="relative my-20 w-full h-full my-[5vw] overflow-hidden flex flex-col justify-start items-center">


        <div className='w-[100%]'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (7).webp"}></img>
        </div>


        <div className='w-[95%] px-12 space-y-3 text-center'>
          <h3 className=' text-[8vw] font-bold'>
            Multi-touch Responsive                </h3>
          <p className=' text-[4.5vw] '>
            Elevate user experiences in collaboration and teamwork with <span className='font-semibold'> more than 20 touch points,</span> allowing for increased hands-on interaction.
          </p>
        </div>



      </div>



      {/* Screen Cast*/}

      <div class="relative w-full h-full my-[10vw] overflow-hidden flex flex-col justify-start items-center">

        <div className='w-[85%] mr-7'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (3).webp"}></img>
        </div>




        <div className='w-[95%] px-12 space-y-2 text-center'>
          <h3 className=' text-[8vw] font-bold'>
            Screen Cast                </h3>
          <p className=' text-[4.5vw] '>
            Achieve the ultimate screen sharing experience. Generate, write, or distribute files and videos directly from your screen, or make real-time edits for sharing.    </p>
        </div>





      </div>




      {/* Pen with Dual Color */}

      <div class="relative w-full h-full my-[5vw] overflow-hidden flex flex-col justify-start items-center">

        <div className='w-[85%]'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (4).webp"}></img>
        </div>

        <div className='w-[95%] px-12 space-y-2 text-center'>
          <h3 className=' text-[7vw] font-bold'>
            Pen with Dual Color                </h3>
          <p className=' text-[4.5vw]'>
            Dual-color pen allowing users to switch between two colors for more versatile note-taking. Additionally, this feature allows users to adjust the thickness of the pen.     </p>
        </div>

      </div>






















      <InteractiveSlider imageSlider={imageSlidermob} />

      <h3 className='text-3xl font-bold mt-[70px] text-center'>
        Multi-Platform Integration
      </h3>

      <p className='text-base md:font-thin mb-[50px] mt-[20px] px-[20px] text-center'>
        Let’s venture on a revolutionary journey with Akai's Smart Interactive Panels. Whether you're in the boardroom or classroom, navigating campus, business meetings or embracing remote learning, we equip you with the essential tools to elevate the smart learning and collaboration experience. It leads to a future where learning is not bound by time or place, but rather, an immersive and dynamic experience that prepares students for the smart education and work opportunities of the digital age. Seamlessly Integrate our Smart interactive Panels with various platforms and enhance compatibility and streamlined workflows across education, business and government sectors.
      </p>


      <div class="relative w-full h-full border-0  flex justify-center items-center mb-[420px]">


        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (1).webp"}></img>

        <h3 className='absolute text-3xl text-center font-bold text-white bottom-[30px]'>
          Dynamic Business Meetings
        </h3>
        <p className='absolute text-base text-center md:font-thin px-[20px] bottom-[-380px] text-center'>
          Let’s venture on a revolutionary journey with Akai's Smart Interactive Panels. Whether you're in the boardroom or classroom, navigating campus, business meetings or embracing remote learning, we equip you with the essential tools to elevate the smart learning and collaboration experience. It leads to a future where learning is not bound by time or place, but rather, an immersive and dynamic experience that prepares students for the smart education and work opportunities of the digital age. Seamlessly Integrate our Smart interactive Panels with various platforms and enhance compatibility and streamlined workflows across education, business and government sectors.
        </p>

      </div>


      <div class="relative w-full h-full border-0 overflow-hidden flex justify-center items-center">


        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain mt-[90px]' src={process.env.PUBLIC_URL + "./Interactive/LearninMadeEasy.webp"}></img>

        <h3 className='absolute text-3xl text-center font-bold text-black top-[50px]'>
          Learning Made Easier.
        </h3>
        <p className='absolute text-base text-center text-black md:font-thin px-[20px] top-[100px] text-center'>
          Our Interactive Panel transforms traditional classrooms into interactive hubs of innovation and creativity.
        </p>

      </div>


      <h3 className='text-2xl text-center font-bold mt-[100px]'>
        Available Sizes
      </h3>


      <div class=" mt-[20px] flex overflow-x-scroll">
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-1.webp' size='139cm (55)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-2.webp' size='165cm (65)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-3.webp' size='190cm (75)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-4.webp' size='218cm (86)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-5.webp' size='249cm (98)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-6.webp' size='280cm (110)' />
      </div>


      <p className='text-[11px] text-center md:font-thin px-[10px] my-[20px] text-center'>
        Images shown here are for representational purpose only, actual may vary. All features, specifications and prices are subject to change without prior notice. Model availability may vary from location to location.
      </p>

      <p className='text-base text-center mx-[5px] my-[20px] py-[20px] text-center border-y-2'>
        <span className='font-semibold'>Suggested Accessories :</span> Display Stand, OPS PC Module, Web-Cam , Soundbar
      </p>


    </div>
  );
}










const ProductCard = (props) => {

  return (
    <div class="w-[500px] flex flex-col m-2 mx-5">

      <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='w-[800px] px-1 object-contain border hover:border-black duration-700 overflow-hidden rounded-md' src={process.env.PUBLIC_URL + props.img} />

      <p class="text-center text-gray-700 text-md font-semibold my-3">{props.size}</p>
      <button class="text-sm w-max py-1 px-4 text-white bg-black rounded-full mb-3 m-auto">See More</button>

    </div>
  );
};













export default InteractiveDisplays;

