import React, { useState, useEffect, memo } from 'react';
import PocketBase from 'pocketbase';




const BannerSlider = (props) => {


  const [currentImage, setCurrentImage] = useState(0);
  const [index, setIndex] = useState(0);
  const [records, setRecords] = useState([]);
  const [banners, setBanners] = useState([]);



  useEffect(() => {
    console.log('ima first');

    fetchData();
  }, [props.bannersId]);
  

  console.log('ima first 333');

  const fetchData = async () => {
    try {
      const pb = new PocketBase('https://api.akaiindia.shop');
      const fetchedRecords = await pb.collection('BannerSlider').getOne(props.bannersId, {
        expand: 'relField2.subRelField',
      });
  

      setRecords(fetchedRecords);
      setBanners(fetchedRecords.Banners);
      setCurrentImage(0);
      setIndex(index + 1);
    console.log('stted');

    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };
  

  useEffect(() => {
 
    setCurrentImage(index);
 
    console.log('current' + index);

    // Auto-slide every 4 seconds
    const intervalId = setInterval(() => {
      handleNextImage();
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [index]);


  const url = `https://api.akaiindia.shop/api/files/${records.collectionId}/${records.id}/`;


  const handleNextImage = () => {
    if (banners.length > 0) {
      setIndex((index + 1) % banners.length);
    }
  };

  const handlePreviousImage = () => {
    if (banners.length > 0) {
      setIndex((index - 1 + banners.length) % banners.length);
    }
  };

  if (banners.length === 0) {
    return <div>Loading.</div>;
  }

  

  return (
    <div className="w-full mx-auto mt-2 border-0 flex flex-col items-center overflow-hidden">
    

      <div className="w-full bg-gray-000 items-center justify-center relative overflow-hidden">
        {/* Uncomment the lines below if you want manual navigation */}
        {/* <button className="text-5xl hover:text-6xl hover:border p-3 rounded-xl duration-200" onClick={handlePreviousImage}> ❮ </button> */}
        <img className="w-full h-auto" src={url + banners[currentImage]} alt={`Product Image ${currentImage + 1}`} />
        {/* <button className="text-5xl hover:text-6xl hover:border p-3 rounded-xl duration-200" onClick={handleNextImage}> ❯ </button> */}
      </div>


      <div className="flex space-x-3 p-3 rounded-xl">
        {banners.map((image, idx) => (
          <div key={idx} className={`hover:bg-red-500 duration-200 w-12 h-[10px] rounded-md cursor-pointer bg-black 
                ${ idx === currentImage ? 'h-14 w-[150px] bg-red-500' : ''}`}
              onClick={() => setIndex(idx)} />
        ))}
      </div>
   
    </div>
  );
};

export default BannerSlider;
