import React, { useState } from 'react'
import CarouselSlick from './components/CarouselSlick';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Japan from './images/Home/japan.png';
import Featured_Products from './components/Featured_Products';
import New_Launch from './components/New_Launch';
import DealerSearch from './components/DealerSearch';
import Explore_Products from './components/Explore_Products';





function Home() {


    return (
        <div className="pt-[65px] overflow-hidden w-full">


            {/* Slider */}
            <CarouselSlick />

            <div className="w-full h-[150px] md:h-[350px] flex items-center justify-center">
                <img alt='akai-india-finest-electronic' className='md:w-[60%] w-[90%]' src={Japan}></img>
            </div>

            <WhyChooseUS />

            <Featured_Products />

            <div className=" mt-[50px]"> <Explore_Products /> </div>

            <GoogleTVHome />

            <div className=" mt-[50px]"> <New_Launch /> </div>

            <WhoWeAre2 />

            <Story />

            <GoGreenHome />

            <SocialConnect />


            <div className="w-screen my-9 h-auto items-center justify-center flex flex-col">

                <DealerSearch />

                <Link className=" my-5 px-9 py-3 text-base m-auto bg-black text-white rounded-full" to="/find-dealers">View all Dealerships</Link>


            </div>

            {/* <ReachUs/> */}

        </div>
    );
}







function WhoWeAre2() {

    // const imgtub = {backgroundImage: process.env.PUBLIC_URL + "./Home/AbouAkai.jpg"};


    return (

        <div className="relative md:w-full w-screen  h-full bg-slate flex bg-red-000">


            <div className="relative m-auto md:w-[95%] lg:w-[90%] w-screen rounded-2xl bg-slate-200 flex items-center justify-center">

                <Link to="/who-we-are">

                    <div className='absolute border-0 border-red-500 inset-0  m-12 w-[80%] md:w-[65%] md:text-2xl text-wrap flex-col flex justify-end md:justify-center lg:justify-end items-center '>

                        <div>
                            <h1 className='font-bold text-4xl md:text-5xl mb-5'>The Story of Akai</h1>

                            <p className='text-sm md:text-sm lg:text-lg/6 xl:text-xl/8'>Akai Electronics was born in Japan in 1929 with a mission to honor people and their values by creating unparalleled experiences. After over 90+ years, we’re innovating, constantly developing and creating designs that fit into your lifestyle seamlessly. Akai still manages to drive on its mission and vision created years ago to serve people well. </p>

                            <LearnButton Name="Learn more about us" Link="who-we-are" />
                        </div>
                    </div>

                    <div className=''>
                        <img alt='about-akai-india' className='object-cover w-full md:w-full md:h-full h-[600px]' src={process.env.PUBLIC_URL + "./Home/AbouAkai.webp"}></img>
                    </div>

                </Link>

            </div>

        </div>);
}


function SocialConnect() {

    return (
        <div className="w-screen md:h-auto h-full md:py-0 p-0 flex-col bg-red-000 space-y-3">

            <p className='text-4xl md:text-5xl font-bold text-center mt-10'>Let's <span className='text-[#e4002b]'> Re</span>connect</p>

            <div className="md:w-full w-screen h-auto border-0 md:px-10 p-5 items-center justify-center space-x-4 flex md:flex-row">


                <a href="https://www.facebook.com/akaiindia.in" target="_blank">
                    <img class='hover:scale-[110%] transform duration-200 object-cover h-[70px] md:h-[95px] lg:h-[95px] m-auto' src={process.env.PUBLIC_URL + "./Icons/Social-fb-bk.svg"} alt="akai-fb-link" />
                </a>

                <a href="https://www.instagram.com/akai.india" target="_blank">
                    <img class='hover:scale-[110%] transform duration-200 object-cover h-[70px] md:h-[95px] lg:h-[95px] m-auto' src={process.env.PUBLIC_URL + "./Icons/Social-Insta-bk.svg"} alt="akai-instagram-link" />
                </a>


                <a href="https://twitter.com/akai_india" target="_blank">
                    <img className='hover:scale-[110%] transform duration-200 object-cover h-[70px] md:h-[95px] lg:h-[95px] m-auto' src={process.env.PUBLIC_URL + "./Icons/Social-Twitter-bk.svg"} alt="akai-twitter-link" />
                </a>

            </div>

        </div>
    );
};



function Story() {

    return (

        <div className="w-full md:h-[550px] lg:h-[550px] h-full bg-slate-000 flex lg:flex-row flex-col md:items-center md:mt-9">

            <div className="w-screen lg:w-[50%] xl:w-[60%]  h-full bg-slate-000 flex items-center justify-center">

                <divv className='md:w-[80%] w-[90%] md:mt-0 mt-7'>
                    <img alt='akai-story-blog' className='object-cover w-full h-full' src={process.env.PUBLIC_URL + "./Home/Story.webp"}></img>
                </divv>

            </div>

            <div className=" w-screen md:w-[80%] lg:w-[40%] h-auto flex flex-col space-y-4 justify-center items-center lg:items-start lg:pl-[120px] bg-red-000 ">

                <p className='xl:text-5xl lg:text-3xl md:text-3xl text-2xl mx-5 md:mx-0 font-bold lg:text-left text-center'>Collecting Stories & Connecting People with Premium Experience.</p>

                <LearnButton Name="Read Stories" Link="story" />

            </div>
        </div>
    );
}




function GoGreenHome() {




    return (

        <div className="relative  w-screen md:w-auto h-max bg-slate-000 flex md:mt-9 mt-9 mb-9">


            <div className="relative  m-0 md:m-auto md:w-[90%] w-screen rounded-2xl items-center flex justify-center md:items-center md:justify-center">

                <Link to="/go-green">


                    <div className=''>
                        <img alt='akai-go-green' className='w-full  h-auto md:h-full ' src={process.env.PUBLIC_URL + "./Home/GoGreen_Home.webp"}></img>
                    </div>



                    <div className='border-0 md:absolute right-0 inset-y-0 md:py-auto md:w-[50%] xl:w-[50%] xl:h-[78%] md:w-[45%] md:h-[75%] md:text-2xl flex flex-col justify-center items-center'>

                        <p className='font-bold text-4xl md:text-5xl lg:text-6xl xl:text-8xl md:text-white  text-center md:text-left md:mb-4'>Go Green</p>

                        <div className='mt-5 md:mt-2 rounded-full hover:bg-white md:text-white transform duration-200 hover:text-black flex w-[250px] md:w-[250px] h-[50px] border-2 md:border-white' to="/GoGreen">
                            <p className='m-auto'>Learn more</p>
                        </div>

                    </div>



                </Link>

            </div>

        </div>);
}





function GoogleTVHome() {

    return (

        <div className="relative md:w-screen w-screen md:h-auto h-fit flex md:mb-[50px] bg-slate-300">

            <Link to="/tv">

                <div className='overflow-hidden'>
                    <img alt='akai-google-tv-qled' className='md:object-cover w-full md:w-screen h-auto ' src={process.env.PUBLIC_URL + "./Home/GoogleTV_QLED.webp"} />
                </div>

            </Link>

        </div>
    );
}






function WhyChooseUS() {


    return (

        <div className="md:w-full w-full h-Auto bg-red-000 mb-9">
            <div className="w-full flex items-center justify-center">
                <div className='flex space-y-2 md:space-y-0 flex-wrap w-[75%] md:w-[90%] lg:md:w-[90%] bg-red-000 justify-evenly border-0'>
                    <img className='object-cover w-[85px] sm:w-[55px] md:w-[105px] lg:w-[140px] xl:w-[170px] h-auto' src={process.env.PUBLIC_URL + "./WhyChooseUS_Icon/why-icon-1.svg"} loading="lazy" alt='akai-icons' />
                    <img className='object-cover w-[85px] sm:w-[55px] md:w-[105px] lg:w-[140px] xl:w-[170px] h-auto' src={process.env.PUBLIC_URL + "./WhyChooseUS_Icon/why-icon-2.svg"} loading="lazy" alt='akai-icons' />
                    <img className='object-cover w-[85px] sm:w-[55px] md:w-[105px] lg:w-[140px] xl:w-[170px] h-auto' src={process.env.PUBLIC_URL + "./WhyChooseUS_Icon/why-icon-3.svg"} loading="lazy" alt='akai-icons' />
                    <img className='object-cover w-[85px] sm:w-[55px] md:w-[105px] lg:w-[140px] xl:w-[170px] h-auto' src={process.env.PUBLIC_URL + "./WhyChooseUS_Icon/why-icon-4.svg"} loading="lazy" alt='akai-icons' />
                    <img className='object-cover w-[85px] sm:w-[55px] md:w-[105px] lg:w-[140px] xl:w-[170px] h-auto' src={process.env.PUBLIC_URL + "./WhyChooseUS_Icon/why-icon-5.svg"} loading="lazy" alt='akai-icons' />
                    <img className='object-cover w-[85px] sm:w-[55px] md:w-[105px] lg:w-[140px] xl:w-[170px] h-auto' src={process.env.PUBLIC_URL + "./WhyChooseUS_Icon/why-icon-6.svg"} loading="lazy" alt='akai-icons' />
                </div>
            </div>
        </div>
    );
}





function LearnButton(props) {
    return (
        <div className='my-4 rounded-full hover:bg-[#e4002b] transform duration-200 hover:text-white flex w-[300px] h-[50px] border-2 border-red-500' to={"/" + props.Link}>
            <p className='m-auto text-[4.5vw] md:text-xl'>{props.Name}</p>
        </div>
    );
}






export default Home;
