
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NewsLetter from './NewsLetter';



const isMobile = window.innerWidth < 768;

const Footer = () => {

  return (
    <div>

      {isMobile ? <FooterMobile /> : <FooterDesktop />}

    </div>
  );
}





const FooterDesktop = () => {



  return (
    <div class='w-full h-auto bg-gray-100 border-t-0 border-gray-500 p-5 text-gray-800 overflow-hidden'>

      <div class='bg-gray-000 h-[20%] flex md:my-9'>
        <NewsLetter />
      </div>


      {/* Upper Part of footer */}
      <div class="grid md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-y-5 place-content-start md:h-full w-full flex">


        <div class='bg-red-000 w-full h-full flex flex-col px-[10%] w-[500px] space-y-4'>

          <p class='md:text-xl xl:text-2xl my-2'>Products</p>

          <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/tv">Televisions</Link>
          <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/audio">Audio</Link>
          <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/washing-machines">Washing Machines</Link>
          <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/air-conditioners">Air Conditioners</Link>
          <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/interactive-displays">Interactive Display</Link>

        </div>



        <div class='bg-red-000 w-full h-full flex flex-col px-[10%] w-[500px] space-y-4'>

          <p class='md:text-xl xl:text-2xl my-2'>Learn More</p>


          <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/who-we-are">Who We Are</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/story">Story</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/go-green">Go Green</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/blog">Blogs</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 text-md" to="/go-green">Newsroom</Link>

        </div>



        <div class='bg-red-000 w-full h-full flex flex-col px-[10%] w-[500px] space-y-4'>

          <p class='md:text-xl xl:text-2xl my-2'>Reach us</p>

          <Link class="hover:text-[#e4002b] transform duration-200 mt-1 text-md" to="/contact">Contact Us</Link>
          <Link class="hover:text-[#e4002b] transform duration-200 mt-1 text-md" to="/find-dealers">Find Akai Shop in your City</Link>

        </div>





        {/* info */}

        <div class='bg-red-000 w-full h-full flex justify-center items-center lg:items-start lg:justify-evenly flex-col px-[7%] md:col-span-3 lg:col-span-2 space-y-4 md:my-5 lg:my-0'>

          <img alt='akai-india-logo' class='w-[120px] h-auto my-0 ' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/ucwpg1vdwdanby3/akai_logo_red_web_p_QeH6cnMmOe.webp'></img>



          <div className='text-md space-y-3 '>
            <p className='text-center font-semibold text-lg lg:text-left'>Customer Care  +91 85448 40022  |  +91 85448 40033 </p>
            <p className='text-center lg:text-left'>Email at:  akaicare@akaiindia.in</p>
            <p className='text-center lg:text-left'>For information on e-waste, please call Toll Free 1800 1025679</p>
          </div>




          <div class='flex space-x-5 mt-5 border-0'>

            <a href="https://www.facebook.com/akaiindia.in" target="_blank">
              <img alt='akai-social-media-link' class='hover:scale-[110%] transform duration-200 object-cover h-[35px] m-auto' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/0xzqfcivxtnrr1y/social_fb_bk_kHxw2xXxOi.svg'></img>
            </a>

            <a href="https://www.instagram.com/akai.india" target="_blank">
              <img alt='akai-social-media-link' class='hover:scale-[110%] transform duration-200 object-cover h-[35px] m-auto' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/0e866n2jlb9qlp3/social_insta_bk_gyBZe2VXWM.svg'></img>
            </a>

            <a href="https://twitter.com/akaielectronics" target="_blank">
              <img alt='akai-social-media-link' class='hover:scale-[110%] transform duration-200 object-cover h-[35px] m-auto' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/aokqdzu0i7r4nu0/social_twitter_bk_cuKeCswJ9Z.svg'></img>
            </a>

          </div>



        </div>


        <div class='bg-red-000 border-t border-gray-500 w-full h-full flex flex-col px-[10%] md:col-span-3 lg:col-span-5 xl:col-span-5 space-y-4 py-3 items-center justify-center'>
          <p className='text-center '>© 2024 - Akai India. All rights reserved.</p>
        </div>


      </div>



    </div>
  );
}







// MObile Version

const FooterMobile = () => {

  return (
    <div class='w-screen h-full bg-gray-100 text-black'>

      <NewsLetter />
      <div class='w-screen h-full mb-1 flex flex-col divide-x divide-gray-800'>

        <div class='bg-red-000 w-max px-[50px] h-full flex '>
          <div class='mx-auto mt-7 flex flex-col'>
            <p class='text-2xl my-2'>Products</p>
            <Link class="hover:text-[#e4002b] font-light transform duration-200 mt-1 text-md" to="/tv">Televisions</Link>
            <Link class="hover:text-[#e4002b] font-light transform duration-200 mt-1 text-md" to="/audio">Audio</Link>
            <Link class="hover:text-[#e4002b] font-light transform duration-200 mt-1 text-md" to="/washing-machines">Washing Machines</Link>
            <Link class="hover:text-[#e4002b] font-light transform duration-200 mt-1 text-md" to="/air-conditioners">Air Conditioners</Link>
            <Link class="hover:text-[#e4002b] font-light transform duration-200 mt-1 text-md" to="/interactive-displays">Interactive Display</Link>

          </div>
        </div>


        <div class='bg-red-000 w-max px-[50px] h-full flex '>
          <div class='mx-auto mt-7 flex flex-col'>
            <p class='text-2xl my-2'>Learn More</p>
            <Link class="hover:text-[#e4002b] transform duration-200 font-light mt-1 text-md" to="/who-we-are">Who We Are</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 font-light mt-1 text-md" to="/story">Story</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 font-light mt-1 text-md" to="/go-green">Go Green</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 font-light mt-1 text-md" to="/blog">Blogs</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 font-light mt-1 text-md" to="/go-green">Newsroom</Link>

          </div>
        </div>


        <div class='bg-red-000 w-max px-[50px] h-full flex '>
          <div class='mx-auto mt-7 flex flex-col'>
            <p class='text-2xl my-2'>Reach us</p>
            <Link class="hover:text-[#e4002b] transform duration-200 font-light mt-1 text-md" to="/contact">Contact Us</Link>
            <Link class="hover:text-[#e4002b] transform duration-200 font-light mt-1 text-md" to="/find-dealers">Find Akai Shop in your City</Link>
          </div>
        </div>



      </div>



      {/*  lower part */}

      <div class='bg-slate-000 w-screen h-[30%] flex flex-col'>

        <div class='bg-red-000 w-full h-full flex flex-col flex-wrap space-y-2 px-12 py-9'>


          <p class='text-2xl'>Customer Care</p>
          <p className='font-light mt-1 text-md'>+91 85448 40022</p>
          <p className='font-light mt-1 text-md'>+91 85448 40033 </p>
          <p className='font-light mt-1 text-md'>Mail Us:  akaicare@akaiindia.in</p>

        </div>


        <div class='bg-red-000 w-screen h-full flex space-y-5 flex-col px-12 py-5 items-center justify-center'>

          <img class='object-cover h-[70px] mb-6' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/ucwpg1vdwdanby3/akai_logo_red_web_p_QeH6cnMmOe.webp'></img>

          <p class='font-semibold text-lg'>Follow us</p>

          <div class='w-max flex space-x-5'>


            <a href="https://www.facebook.com/akaiindia.in" target="_blank">
              <img class='hover:scale-[110%] transform duration-200 object-cover h-[35px] m-auto' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/0xzqfcivxtnrr1y/social_fb_bk_kHxw2xXxOi.svg'></img>
            </a>

            <a href="https://www.instagram.com/akai.india" target="_blank">
              <img class='hover:scale-[110%] transform duration-200 object-cover h-[35px] m-auto' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/0e866n2jlb9qlp3/social_insta_bk_gyBZe2VXWM.svg'></img>
            </a>

            <a href="https://twitter.com/akaielectronics" target="_blank">
              <img class='hover:scale-[110%] transform duration-200 object-cover h-[35px] m-auto' src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/aokqdzu0i7r4nu0/social_twitter_bk_cuKeCswJ9Z.svg'></img>
            </a>


          </div>

          <p className='py-[20px] text-xs '>© 2023 - Akai India. All rights reserved</p>


        </div>

      </div>

    </div>
  );
}



export default Footer;