
import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';




function InteractiveDisplays() {


  const [imageSlider, setImageSlider] = useState([
    "./Interactive/Slide (1).webp",
    "./Interactive/Slide (2).webp",
    "./Interactive/Slide (3).webp"
  ]);


  const InteractiveSlider = (props) => {


    const [currentImage, setCurrentImage] = useState(0);
    const [index, setIndex] = useState(0);


    useEffect(() => {
      if (currentImage !== index) {
        setCurrentImage(index);
      }
    }, [index, currentImage]);

    const handleNextImage = () => {
      if (index < props.imageSlider.length - 1) {
        setIndex(prevIndex => prevIndex + 1);
      }
    };

    const handlePreviousImage = () => {
      if (index > 0) {
        setIndex(prevIndex => prevIndex - 1);
      }
    };

    return (
      <div className="w-[100%] mx-auto mt-8 border-0 flex flex-col items-center bg-red-000">

        <div className="w-full bg-gray-000 grid grid-cols-8 gap-4 items-center justify-center mt-[100px]">

          <button className="text-5xl hover:text-6xl hover:border  p-3 rounded-xl duration-200" onClick={handlePreviousImage}> ❮ </button>

          <img fetchpriority="high" title='akai-interactive-panel-display' className="w-[100%] m-auto rounded-lg col-span-6" src={process.env.PUBLIC_URL + props.imageSlider[currentImage]} alt={`akai-interactive-panel-display ${currentImage + 1}`} />

          <button className="text-5xl hover:text-6xl hover:border  p-3 rounded-xl duration-200" onClick={handleNextImage}>❯</button>

        </div>

        <div className="flex space-x-9 p-3 rounded-xl">
          {props.imageSlider.map((image, idx) => (
            <img  title='akai-interactive-panel-display'
              key={idx}
              src={process.env.PUBLIC_URL + image}
              alt={`akai-interactive-panel-display ${idx + 1}`}
              className={`hover:w-[160px] duration-200 w-[150px] rounded-md cursor-pointer ${idx === currentImage ? 'w-[150px] border border-black' : ''}`}
              onClick={() => setIndex(idx)}
            />
          ))}
        </div>
      </div>
    );
  };








  return (
    <div class="w-full min-h-[600px] bg-Black pt-[70px]">


      <div class="relative w-full min-h-[600px] border-0 overflow-hidden flex justify-center items-center bg-red-000">

        <h3 className='absolute text-[4.5vw] top-9 my-[50px] text-center'>
          Welcome to <br /> <span className='font-bold'>Akai Interactive Panel Displays</span>
        </h3>

        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/main.webp"}></img>

        <h3 className='absolute text-center text-[4.5vw] font-bold bottom-[120px] xl:bottom-[60px]'>
          Make headway for<br />a new era of Smart Education<br />and Workspace.
        </h3>

      </div>


      <div class="relative w-full min-h-[600px] bg-red-000 border-0 overflow-hidden flex justify-center mt-[-200px]">
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display.webp"}></img>
      </div>


      <div class="relative w-full min-h-[600px] border-0 flex justify-center">


        <h3 className='absolute text-[4.3vw] top-9 my-[50px] text-center'>
          From <span className='font-bold'>Classroom</span> to <span className='font-bold'>Boardroom</span>,<br />
          <span className='text-[2.8vw]'>Redefining Interactive Excellence.</span>
        </h3>


        {/* <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain mt-[150px]' src={process.env.PUBLIC_URL + "./main2.webp"}></img> */}


        <div className='absolute bottom-[-150px] bg-black w-[80%] h-max rounded-3xl py-9 px-[7%] '>

          <h3 className='text-white xl:text-[4vw] text-[5vw] font-bold top-9 mb-[20px] text-center'>Innovate. Interact. Inspire.</h3>

          <p className='text-white lg:text-[2vw] md:text-[2vw] xl:text-[1.4vw] font-thin text-center'>
            At Akai India, we understand the pivotal role of seamless communication and virtual experience in today's dynamic world. Our state-of-the-art Interactive Panel transcends boundaries, catering to the diverse needs of education, business, retail, healthcare, hospitality, corporate and government sectors.
            <br className='mb-5' /> Elevate your interactive experience with our cutting-edge technology designed to empower, engage, and inspire. </p>

        </div>

      </div>


      <div class="relative w-full min-h-[600px] border-0 overflow-hidden flex justify-center mt-[200px]">

        <HotspotImage />

      </div>

      <h3 className='text-[5vw] font-bold mb-[70px] text-center'>
        Key Featurs
      </h3>

      <div class="px-[10%] grid grid-cols-4 gap-4">

        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (1).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (2).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (3).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (4).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (5).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (6).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (7).webp"} />
        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Key-Feature (8).webp"} />

      </div>




      {/* 4K Display*/}

      <div class="relative w-full min-h-[600px] my-[5vw] overflow-hidden flex justify-start items-center">

        <div className='w-[60%]'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (2).webp"}></img>
        </div>

        <div className='w-[40%] px-12 space-y-9'>
          <h3 className=' text-[5vw] xl:text-[4.3vw] text-left font-bold'>
            4K Display                </h3>
          <p className=' text-[1.9vw] lg:text-[1.6vw] xl:text-[1.6vw] text-left  font-thin '>
            Enhance visual clarity and visibility across wide angles by incorporating the optically bonded glass layer, ensuring a sharp and clear picture. It also provides eye protection for all day-long working.                </p>
        </div>

      </div>









      {/* Multi-touch Responsive */}

      <div class="relative w-full min-h-[600px] my-[5vw] overflow-hidden flex justify-start items-center">


        <div className='w-[35%] pl-20 space-y-9 bg-red-000 flex  flex-col justify-end'>
          <h3 className=' text-[5vw] xl:text-[4.3vw] text-left font-bold'>
            Multi-touch Responsive</h3>
          <p className=' text-[1.9vw] lg:text-[1.6vw] xl:text-[1.6vw] text-left  font-thin '>
            Elevate user experiences in collaboration and teamwork with <span className='font-semibold'> more than 20 touch points,</span> allowing for increased hands-on interaction.            </p>
        </div>


        <div className='w-[75%]'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (7).webp"}></img>
        </div>



      </div>



      {/* Screen Cast*/}

      <div class="relative w-full min-h-[600px] my-[5vw] overflow-hidden flex justify-start items-center">

        <div className='w-[60%]'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (3).webp"}></img>
        </div>

        <div className='w-[40%] px-12 space-y-9'>
          <h3 className=' text-[5vw] xl:text-[4.3vw] text-left font-bold'>
            Screen Cast                </h3>
          <p className=' text-[1.9vw] lg:text-[1.6vw] xl:text-[1.6vw] text-left  font-thin '>
            Achieve the ultimate screen sharing experience. Generate, write, or distribute files and videos directly from your screen, or make real-time edits for sharing.    </p>
        </div>

      </div>




      {/* Pen with Dual Color */}

      <div class="relative w-full min-h-[600px] my-[5vw] overflow-hidden flex flex-col justify-start items-center">

        <div className='w-[60%]'>
          <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (4).webp"}></img>
        </div>

        <div className='w-[70%] px-12 space-y-9 text-center'>
          <h3 className=' text-[5vw] xl:text-[4.3vw] font-bold'>
            Pen with Dual Color                </h3>
          <p className=' text-[1.9vw] lg:text-[1.6vw] xl:text-[1.6vw]  font-thin '>
            Dual-color pen allowing users to switch between two colors for more versatile note-taking. Additionally, this feature allows users to adjust the thickness of the pen.     </p>
        </div>

      </div>













      <InteractiveSlider imageSlider={imageSlider} />


      <h3 className='text-[4vw] font-bold xl:my-[50px] my-[20px] xl:mt-[150px] mt-[70px] text-center'>
        Multi-Platform Integration
      </h3>



      <div class="relative w-full min-h-[600px] border-0 overflow-hidden flex justify-center items-center">


        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/Akai-Interactive-Panel-Display (1).webp "}></img>

        <h3 className='absolute text-[5vw] xl:text-[4.3vw] text-center font-bold text-white xl:bottom-[300px] bottom-[200px]'>
          Dynamic Business Meetings
        </h3>
        <p className='absolute text-[1.9vw] lg:text-[1.6vw] xl:text-[1.3vw] text-center text-white font-thin px-[50px] xl:px-[200px] xl:bottom-[100px] bottom-[40px] text-center'>
          Let’s venture on a revolutionary journey with Akai's Smart Interactive Panels. Whether you're in the boardroom or classroom, navigating campus, business meetings or embracing remote learning, we equip you with the essential tools to elevate the smart learning and collaboration experience. It leads to a future where learning is not bound by time or place, but rather, an immersive and dynamic experience that prepares students for the smart education and work opportunities of the digital age. Seamlessly Integrate our Smart interactive Panels with various platforms and enhance compatibility and streamlined workflows across education, business and government sectors.
        </p>

      </div>


      <div class="relative w-full min-h-[600px] border-0 overflow-hidden flex justify-center items-center">


        <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='object-contain' src={process.env.PUBLIC_URL + "./Interactive/LearninMadeEasy.webp"}></img>

        <h3 className='absolute text-[4.3vw] text-center font-bold text-black xl:top-[100px] top-[30px]'>
          Learning Made Easier.
        </h3>
        <p className='absolute text-[2.5vw] text-center text-black font-thin px-[200px] xl:top-[220px] top-[90px] text-center'>
          Our Interactive Panel transforms traditional classrooms into interactive hubs of innovation and creativity.
        </p>

      </div>


      <h3 className='text-[4vw] text-center font-bold xl:my-[100px] my-[60px]'>
        Available Sizes
      </h3>


      <div class="px-[150px] grid md:grid-cols-2 lg:grid-cols-3 gap-9 flex">
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-1.webp' size='139cm (55)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-2.webp' size='165cm (65)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-3.webp' size='190cm (75)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-4.webp' size='218cm (86)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-5.webp' size='249cm (98)' />
        <ProductCard img='./Interactive/Screens-Akai-Interactive-Panel-Display-6.webp' size='280cm (110)' />
      </div>


      <p className='text-[1.5vw] xl:text-[1vw] text-center font-thin px-[10%] xl:px-[18%] my-[50px] text-center'>
        Images shown here are for representational purpose only, actual may vary. All features, specifications and prices are subject to change without prior notice. Model availability may vary from location to location.
      </p>

      <p className='md:text-[2.5vw] xl:text-[1.5vw] text-center mx-[80px] xl:mx-[150px] my-[50px] py-[30px] text-center border-y-2'>
        <span className='font-semibold'>Suggested Accessories :</span> Display Stand, OPS PC Module, Web-Cam , Soundbar
      </p>


    </div>
  );
}



const ProductCard = (props) => {

  return (
    <div class="w-full flex flex-col">

      <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' class='px-6 min-h-[150px] min-w-[150px] object-contain border hover:border-black duration-700 overflow-hidden rounded-2xl' src={process.env.PUBLIC_URL + props.img}></img>

      <p class="text-center text-gray-700 text-2xl font-semibold my-3">{props.size}</p>
      <button class="w-max py-2 px-6 text-white bg-black rounded-full mb-3 m-auto">See More</button>

    </div>
  );
};







const HotspotImage = () => {

  // State to track active image
  const [activeImage, setActiveImage] = useState(null);

  // Define hotspot coordinates and actions
  const hotspots = [
    { x: 8, y: 83, image: ' ' },
    { x: 13, y: 88.5, image: ' ' },
    { x: 41.5, y: 85, image: ' ' },
    { x: 71, y: 81.0, image: ' ' },
    { x: 82, y: 92.7, image: ' ' },
  ];

  // Handle hotspot click
  const handleHotspotClick = (imagePath) => {
    setActiveImage(imagePath);
  };

  return (
    <div className="relative">
      <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' className="object-contain" src={process.env.PUBLIC_URL + "./Interactive/Display.webp"} />

      {hotspots.map((spot, index) => (
        <button
          key={index}
          className="absolute animate-pulse rounded-full w-[2vw] h-[2vw] duration-300 border-[#e4002b] border-[3.5px]  lg:border-[5px] xl:border-[8px] hover:border-black"
          style={{ left: `${spot.x}%`, top: `${spot.y}%` }}
          onClick={() => handleHotspotClick(spot.image)}
        />
      ))}

      {/* Modal or new screen to display the active image */}
      {activeImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <img alt='akai-interactive-panel-display' title='akai-interactive-panel-display' src={process.env.PUBLIC_URL + activeImage} />
            <button className="mt-4 p-2 bg-red-500 text-white rounded" onClick={() => setActiveImage(null)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};





export default InteractiveDisplays;

