import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PocketBase from 'pocketbase';
import ProductImageViewer from './components/ProductImageViewer';
import ImageMapping from './components/ImageMapping';
import KeyFeatureMapping from './components/KeyFeatureMapping';
import {SpecsRow, BuyButton, AmountCal, ProductInfoCardDesk, ProductInfoCard, Separation, FaqItem} from './components/ProductViewUtility';









function AcView() {


	const { productId } = useParams();

	console.log('this is' + useParams())

	const [records, setRecords] = useState([]);
	const [records2, setRecords2] = useState([]);
	const [records3, setRecords3] = useState([]);
	const [keyfeature, setKeyfeature] = useState([]);


	const overviewRef = useRef(null);
	const specificationRef = useRef(null);
	const keyfeatureRef = useRef(null);
	const FaqRef = useRef(null);


	const scrollToOverview = () => { overviewRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToSpecification = () => { specificationRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToKeyfeature = () => { keyfeatureRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToFaq = () => { FaqRef.current.scrollIntoView({ behavior: 'smooth' }); };






	useEffect(() => {

		const pb = new PocketBase('https://api.akaiindia.shop');

		const fetchRecords = async () => {
			try {
				const fetchedRecords = await pb.collection('air_conditioners').getOne(productId, { expand: 'Specs_Images,relField2.subRelField' },);
				setRecords(fetchedRecords);
				setRecords2(fetchedRecords.Specs_Images);
				setRecords3(fetchedRecords.Listing_Image);
				setKeyfeature(fetchedRecords.Key_Features);




			} catch (error) {
				console.error('Error fetching records:', error);
			}
		};

		fetchRecords();
	}, []);




	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Function to handle scroll event
		const handleScroll = () => {
			// Calculate the scroll position
			const scrollPosition = window.scrollY;

			// Set visibility based on scroll position
			if (scrollPosition > window.innerHeight * 1.2) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		// Add scroll event listener when component mounts
		window.addEventListener("scroll", handleScroll);

		// Remove scroll event listener when component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []); // Empty dependency array to run effect only once when component mounts














	return (
		<div class="w-full overflow-hidden bg-red-000">

			<div class="w-screen bg-slate-000 flex flex-row pt-[70px] overflow-hidden">

				{/* Listing View */}
			
				<ProductImageViewer class='my-0 md:my-[50px] mx-auto' images={records3} records={records} />

			</div>


			<div class='w-screen flex flex-col items-center justify-center sticky mt-5'>

			<div class="w-screen h-full  flex flex-row flex-wrap justify-center">

					<div class=" w-screen flex justify-center hidden md:flex md:block">
						<ProductInfoCardDesk Product_Name={records.Product_Name} SKU={records.SKU} Category={records.Category} Type={records.Type} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

					<div class=" w-screen md:hidden visible">
						<ProductInfoCard Product_Name={records.Product_Name} SKU={records.SKU} Category={records.Category} Type={records.Type} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

				</div>

				{/* Scroll navigation */}
				<div class="md:w-[90%] w-screen md:h-full bg-gray-200 flex md:flex-row justify-center mt-2 md:rounded-full md:space-x-12 md:space-y-0 space-x-5 p-5 md:p-0">

					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToOverview}>Overview</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToKeyfeature}>Key features</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToSpecification}>Specification</button>
					{/* <button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToFaq}>FAQ</button> */}

				</div>

			</div>






{/*pop up bar  */}

<div className="fixed xl:top-[70px] lg:top-[109px] md:top-[106px] bottom-[0px] left-0 w-full h-max flex flex-col justify-center z-40">
				{/* Popover content */}
				{isVisible && (
					<>
						<div class="w-[100%] h-fit py-3 bg-black flex flex-col xl:flex-row flex-wrap justify-center items-center">

							<div class="flex xl:pl-5 border-0 mb-3 xl:mb-0 w-[100%] justify-center md:justify-center xl:justify-start items-center xl:w-[50%] text-white font-normal">
								<h1 className='text-sm font-light md:text-2xl md:font-semibold'>	{records.Product_Name} </h1>
							</div >

							<div class="flex flex-col md:flex-row w-[100%] xl:w-[50%] text-white  border-0  font-normal space-x-3 items-center justify-center xl:justify-end xl:pr-5">
							
							<div className='flex justify-center items-center space-x-3'>
								<p className='text-white text-lg md:text-2xl '>	₹{records.Price_Offered}/-   </p>
								<p class="text-red-000 line-through text-xs md:text-base">₹ {records.Price}/- </p>
								<AmountCal className='' a={records.Price_Offered} b={records.Price} />
							</div>
							
								<div className='mt-2 md:mt-0'> <BuyButton className='' /> </div>

							</div >


						</div>


						<div class="w-full md:h-max py-3 md:py-2 text-gray-200 bg-black border-t border-gray-700 flex md:flex-row justify-center mt-0 md:rounded-0 md:space-x-12 md:space-y-0 space-x-5 p-5 md:p-0">

							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToOverview}>Overview</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToKeyfeature}>Key features</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToSpecification}>Specification</button>
							{/* <button class="hover:text-[#e4002b] duration-200 text-base md:text-2xl " onClick={scrollToFaq}>FAQ</button> */}

						</div>

					</>


				)}
			</div>









			<div ref={overviewRef} class="w-screen h-auto flex bg-slate-000 mt-[3vw]">
			<p class="text-3xl md:text-5xl mb-[3vw]  font-bold m-auto">Overview</p>
			</div>

			<div class="w-screen h-full pb-[50px] lg:pb-[100px] bg-black-400 flex flex-col item-center justify-center">

				<div>
					<text class="px-[8%] lg:px-[20%] bg-gray-000 flex text-center">{records.overview}</text>
				</div>
			</div>

			<div ref={keyfeatureRef} class="w-screen h-auto flex flex-col bg-slate-000 mb-[2vw]">
				<p class="text-3xl md:text-5xl mb-[3vw] font-bold m-auto ">Key Features</p>
				<KeyFeatureMapping category='AC' ext='.png' keyfeature={keyfeature} />

			</div>

			{/* showing A+ Images here  */}
			<div class="md:grid md:grid-cols-3 md:gap-4 items-center justify-center">
				<ImageMapping records={records} records2={records2} />
			</div>


			<div ref={specificationRef} class="w-screen h-[150px] flex bg-slate-000">
			<p class="text-3xl md:text-5xl mb-[3vw]  font-bold m-auto">Specification</p>
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Basic Information</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Product Name:' val={records.ProductName} />
				<SpecsRow label='Model Name:' val={records.ModelName} />
				<SpecsRow label='In the Box:' val={records.InTheBox} />
				<SpecsRow label='Type:' val={records.Type} />
				<SpecsRow label='Capacity In Tons:' val={records.CapacityInTons} />
				<SpecsRow label='Star Rating:' val={records.StarRating} />
				<SpecsRow label='BEE Rating Year:' val={records.BEERatingYear} />
				<SpecsRow label='Colour:' val={records.Colour} />
				<SpecsRow label='Series Name:' val={records.SeriesName} />
				<SpecsRow label='Cooling and Heating:' val={records.CoolingAndHeating} />
				<SpecsRow label='Cooling Capacity:' val={records.CoolingCapacity} />
				<SpecsRow label='Dehumidification:' val={records.Dehumidification} />
				<SpecsRow label='Remote Control:' val={records.RemoteControl} />
				<SpecsRow label='Refrigerant:' val={records.Refrigerant} />
				<SpecsRow label='Operating Modes:' val={records.OperatingModes} />
				<SpecsRow label='Technology Used:' val={records.TechnologyUsed} />
				<SpecsRow label='Condensor Coil:' val={records.CondensorCoil} />
			</div>

			<div class="w-[82%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Indoor & Outdoor Unit Details</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Indoor (L x W x H):' val={records.IndoorDimensions} />
				<SpecsRow label='Indoor Unit Weight:' val={records.IndoorUnitWeight} />
				<SpecsRow label='Outdoor (W x H x D):' val={records.OutdoorDimensions} />
				<SpecsRow label='Outdoor Unit Weight:' val={records.OutdoorUnitWeight} />
				<SpecsRow label='Remote Control Weight:' val={records.RemoteControlWeight} />

			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] "> Performance Features</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Indoor Noise Level:' val={records.IndoorNoiseLevel} />
				<SpecsRow label='Panel Display:' val={records.PanelDisplay} />
				<SpecsRow label='Indoor Temperature Indicator:' val={records.IndoorTemperatureIndicator} />
				<SpecsRow label='Cooling Coverage Area:' val={records.CoolingCoverageArea} />
				<SpecsRow label='Turbo Mode:' val={records.TurboMode} />
				<SpecsRow label='Other Performance Features:' val={records.OtherPerformanceFeatures} />
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">  Filters & Air Flow</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Evaporator Fin Type:' val={records.EvaporatorFinType} />
				<SpecsRow label='Condensor Fin Type:' val={records.CondensorFinType} />
				<SpecsRow label='Other Body and Design Features:' val={records.OtherBodyAndDesignFeatures} />
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">  Filters & Air Flow</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Evaporator Fin Type:' val={records.EvaporatorFinType} />
				<SpecsRow label='Condensor Fin Type:' val={records.CondensorFinType} />
				<SpecsRow label='Other Body and Design Features:' val={records.OtherBodyAndDesignFeatures} />
				<SpecsRow label='Air Circulation:' val={records.AirCirculation} />
				<SpecsRow label='Auto Air Swing:' val={records.AutoAirSwing} />
				<SpecsRow label='Air Flow Direction:' val={records.AirFlowDirection} />
				<SpecsRow label='Other Air Flow Features:' val={records.OtherAirFlowFeatures} />
				<SpecsRow label='Anti-bacterial Filter:' val={records.AntiBacterialFilter} />
				<SpecsRow label='Dust Filter:' val={records.DustFilter} />
				<SpecsRow label='Active Carbon Filter:' val={records.ActiveCarbonFilter} />
				<SpecsRow label='Deodorizing Filter:' val={records.DeodorizingFilter} />
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">  Modes & Convenience Features</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Auto restart:' val={records.AutoRestart} />
				<SpecsRow label='Timer:' val={records.Timer} />
				<SpecsRow label='Child Lock:' val={records.ChildLock} />
				<SpecsRow label='Quiet Mode:' val={records.QuietMode} />
				<SpecsRow label='Sleep Mode:' val={records.SleepMode} />
				<SpecsRow label='Memory Features:' val={records.MemoryFeatures} />
				<SpecsRow label='Self Diagnosis:' val={records.SelfDiagnosis} />
				<SpecsRow label='Emergency Operational Button:' val={records.EmergencyOperationalButton} />
				<SpecsRow label='Additional Features:' val={records.AdditionalFeatures} />

			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Power Details</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Power Requirement:' val={records.PowerRequirement} />
				<SpecsRow label='Power Consumption:' val={records.PowerConsumption} />
				<SpecsRow label='Operating Current:' val={records.OperatingCurrent} />
				<SpecsRow label='Other Power Features:' val={records.OtherPowerFeatures} />
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] "> Remote Control Details</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='LED Backlit Buttons:' val={records.LEDBacklitButtons} />
				<SpecsRow label='Night Glow Buttons on remote:' val={records.NightGlowButtons} />
				<SpecsRow label='Battery Type:' val={records.BatteryType} />
				<SpecsRow label='Other Remote Control Features:' val={records.OtherRemoteControlFeatures} />

			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Services</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Installation Detail:' val={records.InstallationDetail} />
				<SpecsRow label='Technician Visit Detail:' val={records.TechnicianVisitDetail} />
				<SpecsRow label='Uninstallation Detail:' val={records.UninstallationDetail} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Warranty</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Warranty Summary:' val={records.WarrantySummary} />
				<SpecsRow label='Covered in Warranty:' val={records.CoveredInWarranty} />
				<SpecsRow label='Not Covered in Warranty:' val={records.NotCoveredInWarranty} />
				<SpecsRow label='Warranty Service Type:' val={records.WarrantyServiceType} />
			</div>








			<div class="w-screen h-full pb-[50px] lg:pb-[100px] mt-[100px] bg-black-400 flex flex-col item-center justify-center">

				<div>
					<text class="px-[8%] lg:px-[20%] bg-gray-000 flex text-left">{records.SCO}</text>
				</div>
			</div>



			{/* <div ref={FaqRef} class="w-screen h-[150px] flex bg-slate-000">
				<p class=" text-xl lg:text-5xl font-bold m-auto">FAQ (Frequently Asked Questions)</p>
			</div>




			<div class="w-screen h-full  lg:pb-[100px] mt-[20px] bg-black-400 flex flex-col item-center justify-center">

				<div>

					<FaqItem question="What is GoogleTV?" answer="GoogleTV is OS system from Google" />
					<FaqItem question="Why GoogleTV?" answer=" Its Future of TV" />


				</div>
			</div> */}

		</div>
	);
}





// const SpecsRow = (props) => {

// 	return (
// 		<div class="w-[100%] lg:w-[33%] flex border-0 border-red-500">
// 			<p class="text-left text-gray-700 text-base p-[8px] lg:p-[20px] font-semibold">{props.label} <span class="text-base font-normal p-[20px] m-auto">{props.val}</span></p>
// 		</div>
// 	);
// };




// const FaqItem = ({ question, answer, index }) => {
// 	const [isOpen, setIsOpen] = useState(false);

// 	const toggleAnswer = () => {
// 		setIsOpen(!isOpen);
// 	};

// 	return (
// 		<div className="w-screen flex flex-col justify-center items-center h-full transition ease-in-out delay-150 mb-2">

// 			<div className='lg:w-[70%] w-[95%] overflow-hidden border-2 border-gray-200 rounded-xl transition-all duration-500 ease-in-out text-black'>

// 				<div className=" relative flex transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5" onClick={toggleAnswer}>
// 					<span className='text-red-500 mr-2'>Question:{index}</span> {question}
// 					<div className='absolute right-5 my-auto'>
// 						<img className='w-[25px] h-[25px] mr-2' src={process.env.PUBLIC_URL + '/Icons/arrow-down.svg'} alt="My Image" />
// 					</div>
// 				</div>


// 				{isOpen && <div className="transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5"> <span className='text-red-500'>Answer:</span> {answer}</div>}

// 			</div>
// 		</div>
// 	);
// };




// //infor of product name price etc   
// const ProductInfoCard = (props) => {


// 	return (
// 		<div class=" w-[95%] bg-black rounded-xl flex flex-col flex-wrap p-[5%] text-white text-middle space-y-2 text-lg font-normal m-auto ">

// 			<div className='flex flex-row text-2xl'>
// 				<p>{props.Product_Name} </p>
// 				<p><span className='mx-3'>|</span>{props.SKU}</p>
// 			</div>

// 			<div className='flex flex-row'>
// 				<p>{props.Category} <span className='mx-3'>|</span></p>
// 				<p>{props.Type}</p>
// 			</div>

// 			<div className='flex flex-row items-center'>
// 				<img className='w-[12px] h-[15px] mr-1' src={process.env.PUBLIC_URL + '/Icons/Inr_white.png'} alt="My Image" />
// 				<p>	<span class="text-red-000 line-through">{props.Price}/- </span>
// 					<span class="bg-green-600 p-1 rounded-xl ml-2"> {props.Price_Offered}/-  </span>
// 					<span class=" ml-2 text-xs"> (Inclusive of All Taxes)  </span>
// 				</p>
// 			</div>
// 		</div>);
// };


// //infor of product name price etc   
// const ProductInfoCardDesk = (props) => {


// 	return (
// 		<div class="w-[80%] h-full py-3 bg-black flex flex-row flex-wrap justify-center items-center rounded-2xl">

// 			<div class="flex flex-col px-[2%] w-[65%] text-white font-normal">
// 				<p className='text-2xl font-semibold'>	{props.Product_Name} </p> <br />
// 				<p className='text-lg mt-2'>	{props.SKU} <Sepration /> {props.Category} <Sepration /> {props.Type} <Sepration /> {props.Output} </p>
// 			</div >

// 			<div class="flex w-[35%]  text-2xl font-normal space-x-3 items-center">
// 				<p className='text-white'>	₹{props.Price_Offered}/- <span class="text-red-500 line-through text-sm">₹ {props.Price}/- </span>
// 					<AmountCal a={props.Price_Offered} b={props.Price} />
// 				</p>
// 				<BuyButton className='ml-3' />

// 			</div >


// 		</div>
// 	);
// };



// function Sepration() { return <span class="mx-[10px] text-[#e4002b]">|</span> }


// function AmountCal(props) {

// 	const c = props.a - props.b;
// 	return <span class="mx-[10px] p-1 bg-green-600 text-sm rounded-md">Save ₹{c}/-</span>
// }






export default AcView;
