import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SectionBannercomp } from './components/ProductViewUtility';

import SeoFAQ from './components/SeoFAQ';
import SeoSection from './components/SeoSection';



function Product() {
   
    
    const { category, subcategory } = useParams();
    const [records, setRecords] = useState([]);


    useEffect(() => {
       
        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
                const fetchedRecords = await pb.collection(category.replace(/[-]/g, '_')).getFullList({ filter: `Category = "${subcategory}"` });
                setRecords(fetchedRecords);

            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };
        
        fetchRecords();

    }, []);




    const SortBar = (props) => {
        const SortCategory = async () => {
            try {
                const pb = new PocketBase('https://api.akaiindia.shop');
                const fetchedRecords = await pb.collection(props.collection).getFullList({ sort: props.sortVal });
                setRecords(fetchedRecords);
            } catch (error) {
                console.error('Error sorting category:', error);
            }
        };

        return (
            <div className='p-3 lg:p-5 hover:px-6 rounded-full w-fit flex items-center h-5 hover:border-2 border border-gray-700 hover:border-[#e4002b] duration-300'>
                <button className='text-xs md:text-xs md:font-semibold xl:text-base' onClick={SortCategory}>{props.bttnName}</button>
            </div>
        );
    };







    return (
        <div className='pt-[90px] w-full h-full flex flex-wrap justify-center overflow-hidden'>
            
            <div className='bg-fixed  w-full h-fit overflow-hidden object-contain'>
            
                <SectionBannercomp name={subcategory} />

            </div>

            <div className='sticky top-0 h-full md:h-[90px] w-full my-0 flex flex-row items-center justify-center flex-wrap bg-red-000 space-y-2 md:space-y-0 space-x-1 lg:space-x-3'>
                <p className='text-2xl'>Sort By</p>
                <SortBar collection={category} sortVal='-created' bttnName='Newest' />
                <SortBar collection={category} sortVal='Price' bttnName='Price: Low-High' />
                <SortBar collection={category} sortVal='-Price' bttnName='Price: High-Low' />
            </div>

            <div className='pt-[0px] w-fit h-full flex flex-row flex-wrap justify-center bg-red-000'>
                {records.length > 0 ? (
                    <ProductSection pageopen={category} items={records} name={subcategory} banner='' />
                ) : (
                    <NoItem />
                )}
            </div>





            <div className='w-full '>

			        <SeoSection subcategory={subcategory} />
                    <SeoFAQ subcategory={subcategory}/>        

            </div>



        </div>
    );
}







const ProductSection = (props) => {
    
    const { name, items } = props;
    
    const [filter, setFilter] = useState(name);
    
    const filteredItems = items.filter(item => item.Category.toLowerCase().includes(filter.toLowerCase()));

    return (
        <div className='flex flex-col items-center my-[25px] md:my-[50px] h-max'>
            
            <div className='grid grid-cols-2 md:grid-cols-3 md:gap-3 md:place-content-start md:h-full w-screen md:w-max'>
                {filteredItems.map((item, index) => (
                   
                   <Link to={`/${props.pageopen}/${item.URL_Name.toLowerCase().replace(/\s+/g, '-').replace(/[()]/g, '').replace(/[_]/g, '-')}-${item.SKU.toLowerCase()}/${item.id}`} >
                        <GoViewProduct item={item} />
                    </Link>
                ))}
            </div>
        </div>
    );
};






function GoViewProduct({ item }) {
    
    const { mainimage, Product_Name, SKU, Category, Price, Price_Offered } = item;
    const url = `https://api.akaiindia.shop/api/files/${item.collectionId}/${item.id}/${mainimage}`;

    return (
        <div className='w-[90%] xl:w-[480px] lg:w-[300px] h-auto md:w-[220px] m-auto my-2 md:m-1 border-2 md:border-0 hover:shadow-md rounded-xl overflow-hidden'>
            <div className='w-full h-[78%] bg-gray-000 overflow-hidden flex'>
                <img className='h-full m-auto' src={url} alt={`MainImage_${item.index}`} />
            </div>
            <div className='w-full h-[22%] bg-gray-100 p-2' flex justify-center>
                <p className='text-md font-semibold md:text-xl text-center mb-[5px] font-'>{Product_Name}</p>
                <p className='text-xs text-gray-700 md:text-md text-center'>{SKU}<Sepration />{Category} </p>
                <div className='flex justify-center my-2'>
                    <p className='text-xl md:text-2xl font-semibold text-center'>
                        <span className='font-bold'>₹{Price_Offered}/-</span>
                        <span className='text-red-500 line-through text-sm md:text-xl'>₹{Price}/-</span>
                        <AmountCal a={Price} b={Price_Offered} />
                    </p>
                </div>
            </div>
        </div>
    );
}






function Sepration() {
    return <span className='mx-[10px] text-[#e4002b]'>|</span>;
}



function AmountCal(props) {
    const c = props.a - props.b;
    return <span className='mx-[10px] p-1 bg-green-200 text-sm rounded-md'>Save ₹{c}/-</span>;
}



function NoItem() {
    return (
        <div className='w-full h-[300px] flex justify-center items-center'>
            <p className='text-3xl'> Loding</p>
        </div>
    );
}



export default Product;
