
import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';




const DealerSearch = () => {


  const [search, setSearch] = useState('');
  const [filteredRecords, setFilteredRecords] = useState([]);

  const handleCityChange = (event) => {

    setSearch(event.target.value);
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (search === '') {window.alert("Add value in search Please")};

    // Perform the filtering based on city and state
    const filteredData = await filterData(search);

    // Update the state with filtered data
    setFilteredRecords(filteredData);


  };

  const filterData = async (search) => {
    // Use your database access logic here

    const pb = new PocketBase('https://api.akaiindia.shop');

    const fetchedRecords = await pb.collection('DealerData').getFullList({ sort: '-created' });

    console.log(fetchedRecords)

    // Filter data based on city and state
    const filteredData = fetchedRecords.filter((record) => {
      return record.City.toLowerCase() === search.toLowerCase() || record.State.toLowerCase() === search.toLowerCase();
    });
    console.log(filteredData)

    return filteredData;
  
  };

   

  return (
    <div class='bg-slate-000 w-[90%] md:w-[80%] lg:w-screen h-full flex flex-col items-center justify-center py-[80px]'>
        
      <p class='text-xl md:text-4xl font-bold text-center'>Find Akai Products Near You</p>

      <form onSubmit={handleSubmit}>
       
        <div className='mt-[15px] flex flex-col md:flex-row items-center justify-center md:space-x-3 px-2 md:mt-[35px] border-2 bg-white border-slate-000 p-1 rounded-xl md:rounded-full overflow-hidden w-full md:w-[600px] lg:w-[900px]'>
        
        <input required className='my-1 bg-white rounded-md md:rounded-full caret-pink-500 border-3 border-gray-200 text-left w-full p-9 md:h-[60px]' placeholder='Search City/State/Pincode' type="text" value={search} onChange={handleCityChange} />
        <button className='hover:bg-gray-900 transform duration-200 bg-black rounded-md md:rounded-full text-white w-full md:w-[180px] h-[70px]' type="submit">Search</button>
      
        </div>

      </form>

      
      {search && ( <p className='my-5'>Found: {filteredRecords.length} Dealerships</p> )}
      
      <div class=' w-screen md:w-[880px] rounded-2xl bg-slate-100 flex flex-row flex-wrap overflow-y-scroll'>
        
        {filteredRecords.map((record) => (
          <DealerView Dealership={record.Dealership} City={record.City}   State={record.State} PIN={record.PIN} Mobile={record.Mobile} Phone={record.Phone} Email={record.Email} Address={record.Address}/>
        ))}

      </div>



    </div>
  );
};



function DealerView(props) {

  return(

    <div class='w-[90%] flex flex-wrap md:w-[400px] h-auto m-2 border border-gray-500 p-3 m-3 rounded-xl'>
      <p class='text-xl text-left mb-[5px]  w-[300px]'></p>

      <p class='text-xl text-left mb-[5px]  w-[300px]'>{props.Dealership}</p>
      <p class='text-sm text-left mb-[5px]  w-[300px]'>Address: {props.Address}</p>

      <p class='text-sm text-left mb-[5px]  w-[400px]'>{props.City} Pincode {props.PIN} | {props.State} </p>
      <p class='text-sm text-left mb-[5px]  w-[400px]'>Contacts: {props.Mobile} | {props.Phone} | {props.Email}</p>
      
    </div>
  );
}






export default DealerSearch;

