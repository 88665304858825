import React from 'react';

function ReachUs() { 
    const handleChatClick = () => {
        // Replace the phone number and message with your own
        const phoneNumber = '+917710338899';
        const message = 'Hello Akai! I have a question.';        
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        
        window.open(whatsappLink, '_blank');
    };

    const handleEmail = () => {
        const email = 'akaicare@akaiindia.in';
        window.location.href = `mailto:${email}`;
    };

    const handlePhoneCall = () => {
        const phoneNumber = '+918544840022';    
        window.location.href = `tel:${phoneNumber}`;
    };

    return( 
        <div className="flex justify-center md:my-[30px] mb-[50px] z-10">
            <div className="lg:w-[65%] md:w-[95%] w-[85%] h-[380px] md:h-[200px] bg-red-000 grid grid-cols-1 grid-flow-row md:grid-cols-3 gap-0 space-x-6">   
            

                <div className="bg-red-000 h-[100%] md:col-span-3 flex justify-center items-center ">  
                    <p className='text-center text-lg lg:text-2xl'>
                        <span className='font-bold'> We value your feedback! </span> Whether you have a question, comment, or concern, <br/>
                        we're here to help. <span className='font-bold text-[#e4002b]'>Your satisfaction is our priority.</span>
                    </p>
                </div>
            

                <button onClick={handleChatClick} className="flex border bg-red-000 transform duration-200 rounded-full hover:bg-gray-100 h-[90%] md:h-[70%] xl:h-[100%] justify-center items-center space-x-3">  
                    <img className='object-cover w-[35px] h-auto' src={'https://api.akaiindia.shop/api/files/13x5dhauaogq8te/ypzvshvm0ob61vk/reach_us_1_fOTUMUTSVj.png'} alt="Chat Icon" />
                    <p className='text-center font-semibold xl:text-xl'>Chat on Whatsapp</p>
                </button>
            

                <button onClick={handleEmail} className="flex border bg-red-000 transform duration-200 rounded-full hover:bg-gray-100 h-[90%] md:h-[70%] xl:h-[100%] justify-center items-center space-x-3">  
                    <img className='object-cover w-[35px] h-auto' src={'https://api.akaiindia.shop/api/files/13x5dhauaogq8te/42dm6a5n29vtbgi/reach_us_2_AkhrQrM7Jx.png'} alt="Email Icon" />
                    <p className='text-center font-semibold xl:text-xl'>Send us Email</p>
                </button>
            

                <button onClick={handlePhoneCall} className="flex border bg-red-000 transform duration-200 rounded-full hover:bg-gray-100 h-[90%] md:h-[70%] xl:h-[100%] justify-center items-center space-x-3">  
                    <img className='object-cover w-[35px] h-auto' src={'https://api.akaiindia.shop/api/files/13x5dhauaogq8te/3syhg0btfy2bax9/reach_us_3_q1GE0rAuhK.png'} alt="Phone Call Icon" />
                    <p className='text-center font-semibold xl:text-xl'>Connect on Call</p>
                </button>
            

            </div>
        </div>
    );
}

export default ReachUs;
