import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';



function Newsroom() {


    const [records, setRecords] = useState([]);


    useEffect(() => {

        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
                const fetchedRecords = await pb.collection('newsroom').getFullList({ sort: '-created' });
                setRecords(fetchedRecords);

            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();
    }, []);





    //Sorting Filter
    const SortBar = (props) => {

        const SortCategory = async ({ datk }) => {
            const pb = new PocketBase('https://api.akaiindia.shop');
            const fetchedRecords = await pb.collection(props.collection).getFullList({ sort: props.sortVal });
            setRecords(fetchedRecords);
        };

        return (

            < div class='mx-5 p-5 hover:p-6 rounded-full m-auto flex items-center h-5 hover:border-2 border border-gray-700 hover:border-[#e4002b] duration-300'>

                <button class='mx-5' onClick={SortCategory}>{props.bttnName}</button>
            </div>);
    }




    return (


        <div class=' pt-[60px] w-full h-full flex flex-wrap justify-center overflow-hidden'>

            <div class='flex my-9 md:my-16 font-bold bg-gray-000 w-[75%] m-auto object-contain justify-start items-center' >
                <h1 className='text-5xl text-left md:text-7xl'>Newsroom</h1>
            </div>




            <div class=' pt-[30px] xl:w-[75%] w-[80%] h-full flex flex-row flex-wrap justify-center items-center mb-[100px]'>

                < div class="grid grid-cols-1  gap-3 place-content-center md:place-content-start md:h-full sm:w-max " >
                    {records.map((item, index) => (

                        <NewsView item={item} />

                    ))}
                </div>

            </div>


        </div>

    );
}








function NewsView({ item }) {

    const [dateParts, setDateParts] = useState([]);

    const { title, text, link } = item;

    return (

        <div>


            <div className="relative border border-black rounded-xl  min-h-auto py-3 m-1 overflow-hidden flex flex-col justify-end itmes-end">



                <div className=" h-full bg-gray-000 p-5">

                    <p className="font-semibold line-clamp-3  text-left xl:text-2xl xl:text-xl md:text-lg">{title}</p>

                </div>

                <div className="mx-3 flex w-auto space-x-5 items-center bottom-5 border-t-2 p-2 ">


                    <Link className='flex items-center hover:space-x-2 duration-200 hover:text-red-500' to={`/newsroom/${item.title.toLowerCase().replace(/\s+/g, '-').replace(/[(),:]/g, '')}/${item.id}`}>

                        <div> Read </div>

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>

                    </Link>

                    <CopyLinkButton link={link} />

                </div>
            </div>

        </div>

    );
}





const CopyLinkButton = ({ link }) => {

    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(link)
            .then(() => setCopied(true))
            .catch(err => console.error('Failed to copy:', err));
    };

    return (
        <div>
            <button className='flex items-center space-x-9' onClick={copyToClipboard}>

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z" />
                </svg>
                {copied ? 'Copied!' : 'Copy Link'}
            </button>
        </div>
    );
};








export default Newsroom;



