
import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

import DealerSearch from './components/DealerSearch';


const Dealer = () => {
  const [records, setRecords] = useState([]);

 
 
 
  useEffect(() => {
    
    const pb = new PocketBase('https://api.akaiindia.shop');

    const fetchRecords = async () => {
      try {
        const fetchedRecords = await pb.collection('DealerData').getFullList({ sort: '-created' });
        setRecords(fetchedRecords);
     
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    fetchRecords();
  }, []);






  return (
    <div className='w-screen overflow-hidden'>
      
      <div class='w-screen h-auto pt-[100px] flex justify-center'>

      <DealerSearch/>
      </div>

      <div class="bg-sl h-fullate-200 flex flex-wrap mb-9"> 
        {records.map((record, index) => (
            <DealerView index={index} dealer={record.Dealership} city={record.City} state={record.State} pin={record.PIN} person={record.Person_Name} mobile={record.Mobile} phone={record.Phone} email={record.Email} address={record.Address}/>            
          
        ))}

      </div>


    </div>
  );
};









function DealerView(props) {

  return(
    <div class='w-[90%] md:w-[480px] h-auto m-2 border border-gray-500 p-5 m-3 rounded-xl flex flex-wrap overflow-hidden'>
      <p class='text-xl text-left mb-[5px]  w-[300px]'></p>
      <p class='text-xl text-left mb-[5px]  w-[300px]'>{props.dealer}</p>
      <p class='text-sm text-left mb-[5px]  w-[300px]'>address: {props.address}</p>
      <p class='text-sm text-left mb-[5px]  w-[400px]'>{props.city} Pincode {props.pin} | {props.state} </p>
      <p class='text-sm text-left mb-[5px]  w-[400px]'>contacts: {props.mobile} | {props.phone} | {props.email}</p>    
    </div>
  );
}








export default Dealer;



