import React from 'react';
import {Route, Routes, Redirect, Link} from 'react-router-dom';


function NotFound(){
  return (
    <div className='w-screen h-screen flex justify-center items-center'>

<h1 className='text-5xl font-bold text-gray-800'>Sorry... Page Not Found</h1>
    </div>

  );
}





export default NotFound;
