import Story2 from './images/Story/Story-2.png';
import CoupleBox from './images/Story/CoupleBox.png';
import BlogPageView from './components/BlogPageView';



import React, { useState, useEffect, memo } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';






function Story() {







// data fetch

	const [records, setRecords] = useState([]);


	useEffect(() => {
    
		const pb = new PocketBase('https://api.akaiindia.shop');
	
		const fetchRecords = async () => {
		  try {
			const fetchedRecords = await pb.collection('Blogs').getFullList({ sort: '-created' });
			setRecords(fetchedRecords);
		 
		  } catch (error) {
			console.error('Error fetching records:', error);
		  }
		};
	
		fetchRecords();
	  }, []);
	









	return (
		<div class="w-full h-full bg-Black pt-[70px] overflow-hidden">


	  		<div class="w-full h-[430px] md:h-[777px] flex justify-center">
			  <img class='object-contain my-5 scale-[90%]' src={process.env.PUBLIC_URL + "./Story.png"}></img>     
	  		
			</div>
			  <div class=" w-full h-fit border-0 flex justify-center">
				
				<div class="w-10/12 md:w-8/12 border-0 my-[20px]">
  				
					<p className="text-black text-2xl md:text-6xl text-center font-black my-[20px] md:my-[60px]">लोग: <span className="text-[#e4002b]">कहानियां:</span> अनुभव</p> 
					<p className="text-black text-2xl md:text-6xl text-center font-black my-[20px] md:my-[60px]">人々: <span className="text-[#e4002b]">ストーリー: </span>経験</p> 
					<p className="text-black text-2xl md:text-6xl text-center font-bold my-[20px] md:my-[60px]">People: <span className="text-[#e4002b]">Stories:</span> Experiences</p> 
  				
				</div>
	  		</div>


			{/* <div className='flex justify-center items-center bg-[#e4002b]'>
			<div class="py-9 grid grid-cols-1 md:grid-cols-3 w-fit h-full overflow-hidden grid-cols-1 gap-4 place-content-start mt-[50px] ">
				
				{ records.map((records, index) => (
				<Link to={`/BlogsRead/${records.id}`}> 
					<BlogPageView  key={index} collectionId={records.collectionId} id={records.id} Title_Image={records.Title_Image} created={records.created} Title={records.Title} Subtitle={records.Subtitle}/>
				</Link>
				))}
				
			</div>
			</div> */}
		
			<div class='w-screen h-[80px] flex'>
                <Link class="p-3  w-[200px] text-xl text-center m-auto bg-black text-white rounded-full" to="/blog">View Blogs</Link>
				</div>




			<div class="w-12/12 border-0 flex justify-center">
				<div class="w-10/12 md:w-6/12 border-0 my-[100px]">
  					
					<p className="text-[#e4002b] font-semibold text-center text-5xl md:text-8xl mb-[100px]">Aspects of Akai</p>
					<img src={Story2}/>

				</div>
	  		</div>
		
			<div class="w-full h-full border-0">
				<img src={CoupleBox}></img>

				</div> 
		



		</div>
	);
}

export default memo(Story) ;
