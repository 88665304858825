import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PocketBase from 'pocketbase';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';





function NewsRead() {


    const [records, setRecords] = useState({});
    const [records2, setRecords2] = useState([]);
    const [value, setValue] = useState('');
    const [dateParts, setDateParts] = useState([]);

    const { title, newsId } = useParams();


    useEffect(() => {
        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
                const fetchedRecords = await pb.collection('newsroom').getOne(newsId, {
                    expand: 'Specs_Images,relField2.subRelField',
                });
                setRecords(fetchedRecords);
                setDateParts(fetchedRecords.created.split(' '));
            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();
    }, [newsId]);





    useEffect(() => {
        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
                const fetchedRecords = await pb.collection('newsroom').getFullList({ sort: '-created' });
                setRecords2(fetchedRecords);
            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();
    }, []);




    return (
        <div className="w-full flex flex-col justify-center bg-gray-000 md:pt-[150px] pt-[70px]">

            <div className="flex justify-center rounded-xl  bg-gray-000 border-b-2">

                <div className="xl:w-[60%] lg:w-[80%] md:w-[90%] w-[95%] bg-white md:p-12 p-6 my-9 rounded-xl border overflow-hidden">


                    <div className="w-full bg-black h-[1px] user-select-none"></div>

                    <p className="md:text-4xl text-2xl font-semibold my-6  xl:leading-[3rem] leading-[2.5rem] ">{records.title}</p>
                    <p className="md:text-lg text-sm my-6">{dateParts[0]}</p>
                    
                    <div className="w-full bg-black h-[1px] user-select-none"></div>


                    <p className="text-base my-5  leading-[2rem] ">{records.text}</p>

                    <div className="w-full flex items-center hover:border-red-100 bg-black justify-center h-auto mt-5 border-2 rounded-full">
                       
                        <a className="text-lg font-semibold text-white  leading-[2rem]  " href={records.link}>Go to Main Article </a>

                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                        </svg>

                    </div>

                </div>

            </div>

            <p className="md:text-3xl  text-xl my-12 text-black text-center">Read More</p>

            <div className="md:h-[220px] h-[200px] flex overflow-x-scroll md:mb-[100px] mb-[30px]">
                {records2.map((item) => (
                    <Link className='' to={`/newsroom/${item.title.toLowerCase().replace(/\s+/g, '-').replace(/[(),:]/g, '')}/${item.id}`}>
                        <BlogCard key={item.id} title={item.title} subtitle={item.subtitle} />
                    </Link>
                ))}
            </div>
        </div>
    );
}

function BlogCard(props) {
    return (
        <div className="transform duration-200 hover:h-[180px] w-[250px] md:w-[350px] h-[170px] m-2 shrink-0 p-5 bg-gray-100 rounded-lg flex-initial">

            <p className="text-md md:text-xl md:text-lg line-clamp-2 font-bold">{props.title}</p>

            <p className="text-xs md:text-sm truncate my-2">{props.Subtitle}</p>

            <div className="flex my-5">
                <div className="hover:w-[50px] w-[40px] duration-200 hover:text-[#e4002b]">Read</div>
            </div>

        </div>
    );
}

export default NewsRead;
