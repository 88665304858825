import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';











function SeoFAQ({ subcategory }) {


    const [faq, setFaq] = useState([]);


    useEffect(() => {

        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
                const fetchedRecords = await pb.collection('FAQ').getFullList({ filter: `Category = "${subcategory}"` });
                setFaq(fetchedRecords);
                console.error('Fetch OK', faq);

            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();

    }, []);





    return (


        <div class="w-full pb-[50px] mt-2 lg:pb-[100px] bg-red-000  flex flex-col item-center justify-center">

            {faq.map((item, index) => (

                <FaqItem key={index} question={item.question} answer={item.answer} index={index} />

            ))}

        </div>

    )
}



const FaqItem = ({ question, answer, index }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleAnswer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="group w-full flex flex-col justify-center items-center h-full transition ease-in-out delay-150 mb-2">

            <div className='overflow-hidden group/item w-[80%] group-hover:border-red-500 border-2 border-gray-200 rounded-xl'>

                <div className="flex hover:bg-gray-100 text-base lg:text-xl p-5" onClick={toggleAnswer}>
                
                    <div className='w-[98%]'>
                    <span className='text-red-500 mr-2'>Question:{index + 1}</span> {question} 
                    </div>
                    <div className='right-5 my-auto group-hover/edit:translate-y-1'>
                        <img className=' w-[25px] h-[25px] mr-2' src={process.env.PUBLIC_URL + '/Icons/arrow-down.svg'} alt="My Image" />
                    </div>
                    
                </div>


                {isOpen && <div className="transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5"> <span className='text-red-500'>Answer:</span> {answer}</div>}

            </div>
        </div>
    );
};



export default SeoFAQ;