import React, { useState, useEffect, useRef } from 'react';


function ImageMapping({ records, records2 }) {
  const [imageDimensions, setImageDimensions] = useState({});

  useEffect(() => {
    records2.forEach((item, index) => {
      const img = new Image();
      img.src = `https://api.akaiindia.shop/api/files/${records.collectionId}/${records.id}/${item}`;

      img.onload = () => {
        const { width, height } = img;
        setImageDimensions(prevDimensions => ({
          ...prevDimensions,
          [index]: { width, height }
        }));
        console.log(width,height);
        console.log(img.naturalWidth);
      
      };

      img.onerror = (error) => {
        console.error(`Error loading image at index ${index}:`, error);
      };
    });
  }, [records, records2]);

  return (
    <>
      {records2.map((item, index) => {
        const dimensions = imageDimensions[index];

        if (!dimensions) {
          // You can return a loading state or placeholder if dimensions are not available yet
          return <div key={index}>Loading...</div>;
        }

        const { width, height } = dimensions;

        if (width === height) {
          return <SquareImg key={index} cid={records.collectionId} id={records.id} img={item} />;
        } else {
          return <Aplus key={index} cid={records.collectionId} id={records.id} img={item} />;
        }
      })}
    </>
  );
};



const Aplus = (props) => {

  return (
    <div class=" rounded-xl overflow-hidden m-[20px] col-span-3">

      <div> <img className='w-full' src={`https://api.akaiindia.shop/api/files/${props.cid}/${props.id}/${props.img}`} /> </div>

    </div>
  );
};


const SquareImg = (props) => {

  return (
    <div class="rounded-xl overflow-hidden m-[20px] border-2 ">
      <div>
        <img src={`https://api.akaiindia.shop/api/files/${props.cid}/${props.id}/${props.img}`} />
      </div>
    </div>
  );
};

export default ImageMapping;
