import React, { useState } from 'react';
import PocketBase from 'pocketbase';






const BuyButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      {/* Button to open the popup */}
      <button className="bg-white py-2 px-5 text-sm font-semibold w-max text-black rounded-full active:bg-green-200" onClick={togglePopup} > Buy Now </button>

      {/* Popup window */}

      {isOpen && (

        <div className="w-full fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90 bg-gray-700">

          <div className="relative flex flex-col md:flex-row itemns-center justify-center h-auto w-[90%] xl:w-[60%] lg:w-[85%] md:w-[75%] bg-white p-3 md:p-6 rounded-3xl shadow-lg flex">

            <div className='flex flex-col px-9 justify-center text-center items-center w-full md:w-[50%] space-y-3 md:space-y-9 my-9'>

              <p className="text-black text-md lg:text-xl md:text-md ">
                Currently, this product is not available for online purchase.
              </p>

              <p className="text-black text-sm lg:text-xl md:text-md font-semibold ">
                Please share your details and we'll promptly contact you with suitable buying options.
              </p>

              <h1 className="text-black text-2xl font-bold text-[#4002b]" > We appreciate your patience and interest!</h1>
            </div>

            <div className='flex justify-center items-center w-full md:w-[50%]'>

              <MyForm />
            </div>


            <button className="absolute top-0 left-0  text-black hover:text-red-500 py-2 px-4 rounded w-full md:w-[7vw] h-[5vw] " onClick={togglePopup}> X Close </button>
          </div>
        </div>
      )}
    </div>
  );
};








function MyForm() {


  const pb = new PocketBase('https://api.akaiindia.shop');


  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');


  const handleSubmit = async (event) => {

    event.preventDefault();

    const formData = { name, email, message };

    try {
      const record = await pb.collection('buy_now').create(formData);
      console.log('Form submitted:', record);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };



  return (
    <form onSubmit={handleSubmit}>

      <div className="flex flex-col m-auto w-full ">

        <div className='border-2 border-black p-3 rounded-2xl divide-y'>

          <label>
            <input
              className="w-full duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2"
              type="text"
              value={name}
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
            />
          </label>

          <label>
            <input
              className="w-full duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2"
              type="text"
              value={name}
              placeholder="Enter your mobile number"
              onChange={(e) => setName(e.target.value)}
            />
          </label>



          <label>
            <input
              className="w-full  duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2"
              type="email"
              value={email}
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>



          <label>
            <input
              className="w-full  duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2"
              type=""
              value={email}
              placeholder="Enter your Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </label>



          <label>
            <input
              className="w-full duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2"
              type="text"
              value={email}
              placeholder="Enter your city"
              onChange={(e) => setCity(e.target.value)}
            />
          </label>



          <label>
            <input
              className="w-full h-[120px]  duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2"
              type="text"
              value={message}
              placeholder="Write us message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </label>



          <input
            className="bg-black duration-200 hover:bg-slate-800 text-center text-white p-[20px] md:mt-[20px] mt-[20px] md:m-0 m-auto w-[120px] rounded-xl"
            type="submit"
            value="Send"
          />
        </div>
      </div>
    </form>
  );
}









export default BuyButton;
