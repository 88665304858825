import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PocketBase from 'pocketbase';






function Explore_Products() {
    
    
    const [records, setRecords] = useState([]);
    const [scrollPosition, setScrollPosition] = useState(0);
    const galleryRef = useRef(null);



    useEffect(() => {
        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
                const fetchedRecords = await pb.collection('Explore_Products').getFullList({ sort: 'created' });
                setRecords(fetchedRecords);
            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();
    }, []);

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const checkWindowSize = () => {
        setIsSmallScreen(window.innerWidth <= 800);
    };

    useEffect(() => {
        checkWindowSize();
        window.addEventListener('resize', checkWindowSize);

        return () => {
            window.removeEventListener('resize', checkWindowSize);
        };
    }, []);

    const scrollLeft = () => {
        const cardWidth = 330; // Assuming card width is 330px
        const newPosition = galleryRef.current.scrollLeft - cardWidth;
        setScrollPosition(Math.max(newPosition, 0));
        galleryRef.current.scrollLeft = newPosition;
    };

    const scrollRight = () => {
        const cardWidth = 330; // Assuming card width is 330px
        const newPosition = galleryRef.current.scrollLeft + cardWidth;
        setScrollPosition(newPosition);
        galleryRef.current.scrollLeft = newPosition;
    };

    return (
        <main className="w-screen h-full bg-slate-000 border-0 flex flex-col md:flex-col justify-center items-center">
            <h1 className="text-4xl font-bold md:text-8xl md:font-bold md:my-9">
                Explore <span className="text-[#e4002b]"> Akai </span>
            </h1>

            <div className="w-screen h-full border-0 flex flex-col md:flex-row justify-center items-center">
                <button
                    onClick={scrollLeft}
                    className={`m-1 h-[100px] w-[30px] hover:h-[120px] duration-200 bg-black text-white p-2 rounded ${
                        isSmallScreen ? 'hidden' : ''
                    }`}
                >
                    {'<'}
                </button>
                <div className="w-[90%] md:w-[90%] grid grid-rows-1 grid-flow-col bg-slate-000 mb-9 p-3 overflow-x-scroll overflow-y-hidden" ref={galleryRef} style={{ scrollBehavior: 'smooth' }}>
                    {records.map((record, index) => (
                        <ImageCard key={index} record={record} />
                    ))}
                </div>
                <button
                    onClick={scrollRight}
                    className={`m-1 h-[100px] w-[30px] hover:h-[120px] duration-200 bg-black text-white p-2 rounded ${
                        isSmallScreen ? 'hidden' : ''
                    }`}
                >
                    {'>'}
                </button>
            </div>
        </main>
    );
}

const ImageCard = ({ record }) => {
    const { id, Category, Image, Category_Name } = record;
    const imageUrl = `https://api.akaiindia.shop/api/files/${record.collectionId}/${id}/${Image}`;

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="w-[330px] h-[412px] bg-slate-200 overflow-hidden rounded-xl m-1">
                <Link to={`/${Category}`}>
                    <img className="object-cover w-full" src={imageUrl} alt={Category_Name} />
                </Link>
            </div>
            <p className="text-xl font-semibold my-3">{Category_Name}</p>
        </div>
    );
};

export default Explore_Products;
