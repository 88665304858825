import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProductSearch from './Pages/components/ProductSearch';

import Logo from './Pages/images/AkaiLogo.png';


const SearchIcon = process.env.PUBLIC_URL + "./Icons/Search1.svg";

// const isDesktop = window.innerWidth > 1100;

// const isMobile = window.innerWidth < 500;

// const isPad = window.innerWidth < 900 && window.innerWidth > 500;



function Navigation() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (windowWidth > 1439) {
    return <DesktopNav />;
  } else if (windowWidth < 1440 && windowWidth > 767) {
    return <PadNav />;
  } else if (windowWidth < 767) {
    return <MobileNav />;
  }
}








function PadNav() {

  const [isHovered, setIsHovered] = useState(false);
  const [isOn, setIsOn] = useState(false);



  return (

    <div class="w-full fixed bg-white shadow-md">


      <div class=" h-[70px] flex flex-row items-center space-x-3 bg-red-000 justify-evenly">

        <div>
          <Link class="px-0 m-auto" to="/"><img class="w-[130px] px-[20px] py-[10px]" src={Logo} /> </Link>
        </div>

        <Link class="hover:text-[#e4002b] text-[1.7vw] font-bold p-1 duration-200" to="/tv">TV</Link>
        <Link class="hover:text-[#e4002b] text-[1.7vw] font-bold p-1 duration-200" to="/audio">Audio</Link>
        <Link class="hover:text-[#e4002b] text-[1.7vw] font-bold p-1 duration-200" to="/air-conditioners">Air-Conditioners</Link>
        <Link class="hover:text-[#e4002b] text-[1.7vw] font-bold p-1 duration-200" to="/washing-machine">Washing Machines</Link>
        <Link class="hover:text-[#e4002b] text-[1.7vw] font-bold p-1 duration-200" to="/interactive-displays">Interactive Displays</Link>


        <div onClick={() => setIsOn(!isOn)} class='space-x-2 items-center hover:px-6 px-5 h-[50px] rounded-full border hover:border-[#e4002b] flex flex-row bg-white hover:bg-gray-100 transform duration-200'>


        <svg className='fill-red-500' xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>

          <p >Search </p>
        </div>
      </div>

      <div className='bg-red-000 flex justify-evenly items-center py-2 border-t'>

        <Link class="hover:text-[#e4002b] text-[1.6vw] p-0 duration-200" to="/who-we-are">Who We Are</Link>
        <Link class="hover:text-[#e4002b] text-[1.6vw] p-0 duration-200" to="/story">Stories</Link>
        <Link class="hover:text-[#e4002b] text-[1.6vw] p-0 duration-200" to="/go-green">Go Green</Link>
        <Link class="hover:text-[#e4002b] text-[1.6vw] p-0 duration-200" to="/blog">Blogs</Link>
        <Link class="hover:text-[#e4002b] text-[1.6vw] p-0 duration-200" to="/newsroom">Newsroom</Link>
        <Link class="hover:text-[#e4002b] text-[1.6vw] p-0 duration-200" to="/Contact">Contact Us</Link>

      </div>

      {isOn && <div> <ProductSearch /> </div>}


    </div>
  );
}



function DesktopNav() {

  const [isHovered, setIsHovered] = useState(false);
  const [isOn, setIsOn] = useState(false);



  return (

    <div class="w-full fixed bg-white shadow-md">


      <div class="w-fit h-[70px] flex flex-row items-center space-x-5">

        <Link class="px-5 m-auto" to="/"><img class="w-[150px] px-[20px] py-[10px]" src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/ucwpg1vdwdanby3/akai_logo_red_web_p_QeH6cnMmOe.webp' /> </Link>

        <Link class="hover:text-[#e4002b] text-[1.1vw] font-semibold p-1 duration-200" to="/tv">TV</Link>
        <Link class="hover:text-[#e4002b] text-[1.1vw] font-semibold p-1 duration-200" to="/audio">Audio</Link>
        <Link class="hover:text-[#e4002b] text-[1.1vw] font-semibold p-1 duration-200" to="/air-conditioners">Air-Conditioners</Link>
        <Link class="hover:text-[#e4002b] text-[1.1vw] font-semibold p-1 duration-200" to="/washing-machine">Washing Machines</Link>
        <Link class="hover:text-[#e4002b] text-[1.1vw] font-semibold p-1 duration-200" to="/interactive-displays">Interactive Displays</Link>

        <div>|</div>

        <Link class="hover:text-[#e4002b] text-[1vw] p-0 duration-200" to="/who-we-are">Who We Are</Link>
        <Link class="hover:text-[#e4002b] text-[1vw] p-0 duration-200" to="/story">Stories</Link>
        <Link class="hover:text-[#e4002b] text-[1vw] p-0 duration-200" to="/go-green">Go Green</Link>
        <Link class="hover:text-[#e4002b] text-[1vw] p-0 duration-200" to="/blog">Blogs</Link>
        <Link class="hover:text-[#e4002b] text-[1vw] p-0 duration-200" to="/newsroom">Newsroom</Link>

        <Link class="hover:text-[#e4002b] text-[1vw] p-0 duration-200" to="/contact">Contact Us</Link>

        <div onClick={() => setIsOn(!isOn)} class='space-x-2 w-fit items-center hover:px-9 px-5 h-[50px] rounded-full border hover:border-[#e4002b] flex flex-row bg-white hover:bg-gray-100 transform duration-200'>
          {/* <img class="w-[25px] hover:rotate-6" src={SearchIcon} /> */}
          <svg className='fill-red-500' xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>

          <p >Search </p>
        </div>
      </div>

      {isHovered && (
        <div onMouseLeave={() => setIsHovered(false)} class="w-screen h-[100px] bg-black flex justify-center item-center">

          <div class="flex flex-row m-auto space-x-5">

            <Link to="/Tv">
              <div class="rounded-full hover:border transform duration-300 py-2 px-5 text-xl text-white" onClick={() => setIsHovered(false)}>TV</div>
            </Link>
            <Link to="/Audio">
              <div class="rounded-full hover:border transform duration-300 py-2 px-5 text-xl text-white" onClick={() => setIsHovered(false)}>Audio</div>
            </Link>
            <Link to="/WashingMachine">
              <div class="rounded-full hover:border transform duration-300 py-2 px-5 text-xl text-white" onClick={() => setIsHovered(false)}>Washing Machines</div>
            </Link>
            <Link to="/Ac">
              <div class="rounded-full hover:border transform duration-300 py-2 px-5 text-xl text-white" onClick={() => setIsHovered(false)}>Air Conditioners</div>
            </Link>
            <Link to="/InteractiveDisplays">
              <div class="rounded-full hover:border transform duration-300 py-2 px-5 text-xl text-white" onClick={() => setIsHovered(false)}>Interactive Displays</div>
            </Link>
          </div>

        </div>
      )}

      {isOn && <div> <ProductSearch /> </div>}


    </div>
  );
}








function MobileNav() {

  const [isOpen, setIsOpen] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (

    <div class="grid grid-cols-2 p-0 w-screen fixed bg-white md:hidden h-[70px] flex justify-center items-center shadow-sm shadow-gray-500">



      <Link onClick={() => setIsOpen(false)} class="" to="/"><img class="w-[125px] px-[20px] py-[10px]" src='https://api.akaiindia.shop/api/files/13x5dhauaogq8te/ucwpg1vdwdanby3/akai_logo_red_web_p_QeH6cnMmOe.webp' /> </Link>





      <div class="place-self-end mr-5 flex space-x-5 bg-red-000 my-auto">

        <button className="text-black text-4xl " onClick={() => { setIsOn(!isOn); setIsOpen(false); }} >


        <svg className='fill-red-500' xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>



        </button>

        <button className="text-black text-2xl" onClick={toggleMenu} >

       {isOpen ? <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg> : 

<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>

}
       
        

        </button>
      </div>



      {isOpen && (
        <div class=" absolute top-[70px] inset-x-0 bg-black w-screen h-screen text-left flex flex-col justify-left p-7 space-y-3">


          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-lg" to="/tv">Televisions</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-lg" to="/audio">Audio</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-lg" to="/washing-machine">Washing Machines</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-lg" to="/air-conditioners">Air Conditioners</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-lg" to="/interactive-displays">Interactive Displays</Link>

          <div class='w-full h-[1px] bg-gray-500 my-9'></div>

          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-sm" to="/who-we-are">Who We Are</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-sm" to="/story">Story</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-sm" to="/go-green">Go Green</Link>

          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-sm" to="/blog">Blogs</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-sm" to="/newsroom">Newsroom</Link>


          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-sm" to="/find-dealers">Find Dealers</Link>
          <Link onClick={toggleMenu} class="hover:text-[#e4002b] text-white transform duration-200 text-sm" to="/contact">Contact Us</Link>



        </div>
      )}

      {isOn && <div> <ProductSearch /> </div>}



    </div>
  );
}











export default Navigation;
