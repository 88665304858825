import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PocketBase from 'pocketbase';
import ProductImageViewer from './components/ProductImageViewer';
import ImageMapping from './components/ImageMapping';
import KeyFeatureMapping from './components/KeyFeatureMapping';
import { SpecsRow, AmountCal, ProductInfoCardDesk, ProductInfoCard, BuyButton, Separation, FaqItem } from './components/ProductViewUtility';










function AudioView() {


	const { productId } = useParams();


	const [records, setRecords] = useState([]);
	const [records2, setRecords2] = useState([]);
	const [records3, setRecords3] = useState([]);
	const [keyfeature, setKeyfeature] = useState([]);


	const overviewRef = useRef(null);
	const specificationRef = useRef(null);
	const keyfeatureRef = useRef(null);
	const FaqRef = useRef(null);


	const scrollToOverview = () => { overviewRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToSpecification = () => { specificationRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToKeyfeature = () => { keyfeatureRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToFaq = () => { FaqRef.current.scrollIntoView({ behavior: 'smooth' }); };






	useEffect(() => {

		const pb = new PocketBase('https://api.akaiindia.shop');

		const fetchRecords = async () => {
			try {
				const fetchedRecords = await pb.collection('audio').getOne(productId, { expand: 'Specs_Images,relField2.subRelField' },);
				setRecords(fetchedRecords);
				setRecords2(fetchedRecords.Specs_Images);
				setRecords3(fetchedRecords.Listing_Image);
				setKeyfeature(fetchedRecords.Key_Features);




			} catch (error) {
				console.error('Error fetching records:', error);
			}
		};

		fetchRecords();
	}, []);




	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Function to handle scroll event
		const handleScroll = () => {
			// Calculate the scroll position
			const scrollPosition = window.scrollY;

			// Set visibility based on scroll position
			if (scrollPosition > window.innerHeight * 1.2) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []); 








	return (
		<div class="w-full overflow-hidden bg-red-000">

			<div class="w-screen bg-slate-000 flex flex-row pt-[70px] overflow-hidden">

				{/* Listing View */}

				<ProductImageViewer class='my-0 md:my-[50px] mx-auto' images={records3} records={records} />

			</div>


			<div class='w-screen flex flex-col items-center justify-center sticky mt-5 '>

				<div class="w-screen h-full  flex flex-row flex-wrap justify-center">

					<div class=" w-screen flex justify-center hidden md:flex md:block">
						<ProductInfoCardDesk className="" Product_Name={records.Product_Name} Output={records.Output_Power_RMS} SKU={records.SKU} Category={records.Category} Type={records.Type} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

					<div class=" w-screen md:hidden visible">
						<ProductInfoCard Product_Name={records.Product_Name} Output={records.Output_Power_RMS} SKU={records.SKU} Category={records.Category} Type={records.Type} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

					{/* <Popover className="" Product_Name={records.Product_Name} Output={records.Output_Power_RMS} SKU={records.SKU} Category={records.Category} Type={records.Type} Price={records.Price} Price_Offered={records.Price_Offered} /> */}


				</div>



				{/* Scroll navigation */}
				<div class="md:w-[90%] w-screen md:h-full bg-gray-200 flex md:flex-row justify-center mt-2 md:rounded-full md:space-x-12 md:space-y-0 space-x-5 p-5 md:py-3">

					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToOverview}>Overview</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToKeyfeature}>Key features</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToSpecification}>Specification</button>
					{/* <button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToFaq}>FAQ</button> */}

				</div>

			</div>



			{/*pop up bar  */}

<div className="fixed xl:top-[70px] lg:top-[109px] md:top-[106px] bottom-[0px] left-0 w-full h-max flex flex-col justify-center z-40">
				{/* Popover content */}
				{isVisible && (
					<>
						<div class="w-[100%] h-fit py-3 bg-black flex flex-col xl:flex-row flex-wrap justify-center items-center">

							<div class="flex xl:pl-5 border-0 mb-3 xl:mb-0 w-[100%] justify-center md:justify-center xl:justify-start items-center xl:w-[50%] text-white font-normal">
								<h1 className='text-sm font-light md:text-2xl md:font-semibold'>	{records.Product_Name} </h1>
							</div >

							<div class="flex flex-col md:flex-row w-[100%] xl:w-[50%] text-white  border-0  font-normal space-x-3 items-center justify-center xl:justify-end xl:pr-5">
							
							<div className='flex justify-center items-center space-x-3'>
								<p className='text-white text-lg md:text-2xl '>	₹{records.Price_Offered}/-   </p>
								<p class="text-red-000 line-through text-xs md:text-base">₹ {records.Price}/- </p>
								<AmountCal className='' a={records.Price_Offered} b={records.Price} />
							</div>
							
								<div className='mt-2 md:mt-0'> <BuyButton className='' /> </div>

							</div >


						</div>


						<div class="w-full md:h-max py-3 md:py-2 text-gray-200 bg-black border-t border-gray-700 flex md:flex-row justify-center mt-0 md:rounded-0 md:space-x-12 md:space-y-0 space-x-5 p-5 md:p-0">

							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToOverview}>Overview</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToKeyfeature}>Key features</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToSpecification}>Specification</button>
							{/* <button class="hover:text-[#e4002b] duration-200 text-base md:text-2xl " onClick={scrollToFaq}>FAQ</button> */}

						</div>

					</>


				)}
			</div>





			<div ref={overviewRef} class="w-screen h-auto flex bg-slate-000 mt-[3vw]">
				<p class="text-3xl md:text-5xl mb-[3vw]  font-bold m-auto">Overview</p>
			</div>

			<div class="w-screen h-full pb-[50px] lg:pb-[100px] bg-black-400 flex flex-col item-center justify-center">

				<div>
					<text class="px-[8%] lg:px-[20%] bg-gray-000 flex text-center">{records.overview}</text>
				</div>
			</div>

			<div ref={keyfeatureRef} class="w-screen h-auto flex flex-col bg-slate-000 mb-[2vw]">
				<p class="text-3xl md:text-5xl mb-[3vw] font-bold m-auto ">Key Features</p>
				<KeyFeatureMapping category='Audio' ext='.png' keyfeature={keyfeature} />

			</div>



			{/* showing A+ Images here  */}
			<div class="md:grid md:grid-cols-3 md:gap-4 items-center justify-center">
				<ImageMapping records={records} records2={records2} />
			</div>


			<div ref={specificationRef} class="w-screen h-[150px] flex bg-slate-000">
				<p class="text-3xl md:text-5xl mb-[3vw]  font-bold m-auto">Specification</p>
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Control</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='LED Display:' val={records.LED_Display} />
				<SpecsRow label='Design:' val={records.Design} />
				<SpecsRow label='Remote Control:' val={records.Remote_Control} />
				<SpecsRow label='Karaoke:' val={records.Karaoke} />
				<SpecsRow label='Dual UHF Wireless Mic:' val={records.Dual_UHF_wireless_mic} />
				<SpecsRow label='Touch_Control:' val={records.Touch_Control} />
				<SpecsRow label='FM:' val={records.FM} />
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Connectivity</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Optical:' val={records.Optical} />
				<SpecsRow label='TV ARC:' val={records.TV_ARC} />
				<SpecsRow label='Coxial:' val={records.Coxial} />
				<SpecsRow label='Output Power RMS:' val={records.Output_Power_RMS} />
				<SpecsRow label='USB:' val={records.USB} />
				<SpecsRow label='SD Card:' val={records.SD_Card} />
				<SpecsRow label='AUX:' val={records.AUX} />
				<SpecsRow label='Bluetooth:' val={records.Bluetooth} />

			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Audio</p>
			</div>



			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-row flex-wrap">

				<SpecsRow label='Driver Size:' val={records.Driver_Size} />
				<SpecsRow label='Subwoofer:' val={records.Subwoofer} />
				<SpecsRow label='Mid:' val={records.Mid} />
				<SpecsRow label='Tweeter:' val={records.Tweeter} />
				<SpecsRow label='Impedance:' val={records.Impedance} />
				<SpecsRow label='Frequency response:' val={records.Frequency_response} />
				<SpecsRow label='SN Ratio:' val={records.SN_Ratio} />
				<SpecsRow label='Separation:' val={records.Separation} />
				<SpecsRow label='File format support USB SD:' val={records.File_format_support_USB_SD} />
				<SpecsRow label='Line Input:' val={records.Line_Input} />
				<SpecsRow label='BT Name:' val={records.BT_Name} />
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Dimension</p>
			</div>




			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">

				<SpecsRow label='Product Dimension W x D x H:' val={records.Product_Dimension_W_x_D_x_H} />
				<SpecsRow label='Package Dimension W x D X H:' val={records.Package_Dimension_W_x_D_X_H} />
			</div>


			<div class="w-screen h-full pb-[50px] lg:pb-[100px] mt-[100px] bg-black-400 flex flex-col item-center justify-center">

				<div>
					<text class="px-[8%] lg:px-[20%] bg-gray-000 flex text-left">{records.SCO}</text>
				</div>
			</div>



			{/* <div ref={FaqRef} class="w-screen h-[150px] flex bg-slate-000">
								<p class=" text-xl lg:text-5xl font-bold m-auto">FAQ (Frequently Asked Questions)</p>
						</div>



							
							<div class="w-screen h-full  lg:pb-[100px] mt-[20px] bg-black-400 flex flex-col item-center justify-center">
								
								<div>
						
								<FaqItem question="What is GoogleTV?" answer="GoogleTV is OS system from Google"/>
								<FaqItem question="Why GoogleTV?" answer=" Its Future of TV"/>
						
						
								</div>
							</div> */}

		</div>
	);
}





// const SpecsRow = (props) => {

// 	return (
// 		<div class="w-[100%] lg:w-[33%] flex border-0 bg-gray-100 rounded-xl m-0.5">
// 			<p class="text-left text-gray-500 text-base p-[8px] lg:p-[20px] font-base">{props.label} <br/><span class="text-xl text-black font-normal py-[20px] m-auto">{props.val}</span></p>
// 		</div>
// 	);
// };




// const FaqItem = ({ question, answer, index }) => {
// 	const [isOpen, setIsOpen] = useState(false);

// 	const toggleAnswer = () => {
// 		setIsOpen(!isOpen);
// 	};

// 	return (
// 		<div className="w-screen flex flex-col justify-center items-center h-full transition ease-in-out delay-150 mb-2">

// 			<div className='lg:w-[70%] w-[95%] overflow-hidden border-2 border-gray-200 rounded-xl transition-all duration-500 ease-in-out text-black'>

// 				<div className=" relative flex transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5" onClick={toggleAnswer}>
// 					<span className='text-red-500 mr-2'>Question:{index}</span> {question}
// 					<div className='absolute right-5 my-auto'>
// 						<img className='w-[25px] h-[25px] mr-2' src={process.env.PUBLIC_URL + '/Icons/arrow-down.svg'} alt="My Image" />
// 					</div>
// 				</div>


// 				{isOpen && <div className="transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5"> <span className='text-red-500'>Answer:</span> {answer}</div>}

// 			</div>
// 		</div>
// 	);
// };




//for of product name price etc on Mobile   
// const ProductInfoCard = (props) => {


// 	return (
// 		<div class=" w-[97%] bg-black rounded-xl flex flex-row flex-wrap p-[5%] text-white text-middle space-y-2 m-auto ">

// 			<div className='flex flex-col text-center text-lg font-semibold items-center justify-center w-full'>
// 				<p>{props.Product_Name} </p>
// 			</div>

// 			<div className='w-full items-center justify-center flex flex-row text-white text-center text-xs'>
// 				<p>{props.SKU}</p><Sepration />
// 				<p>{props.Category}</p><Sepration />
// 				<p>{props.Type}</p>
// 			</div>


// 			<div class="w-full justify-center flex flex-row w-full  text-2xl font-normal space-x-3 items-center">
// 				<p>	₹{props.Price_Offered}/- </p>
// 				<p>	<span class="text-red-500 line-through text-sm">₹ {props.Price}/- </span> </p>
// 				<p>  <AmountCal a={props.Price_Offered} b={props.Price} /> </p>
// 			</div >

// 			<div className='flex justify-center items-center w-full'>
// 				<BuyButton className='mt-3' />
// 			</div>

// 		</div>
// 	);
// };



//infor of product name price etc   
// const ProductInfoCardDesk = (props) => {


// 	return (
// 		<div class="md:w-[95%] xl:w-[80%] h-full py-3 bg-black flex flex-row flex-wrap justify-center items-center rounded-2xl">

// 			<div class="flex flex-col px-[2%] border w-[65%] md:w-[45%] text-white font-normal">
// 				<p className='text-2xl font-semibold'>	{props.Product_Name} </p> <br />
// 				{/* <p className='text-lg mt-2'>	{props.SKU} <Sepration /> {props.Category} <Sepration /> {props.Type} <Sepration /> {props.Output} </p> */}
// 			</div >

// 			<div class="flex xl:w-[35%] w-[45%]   text-2xl font-normal space-x-3 items-center">
// 				<p className='text-white'>	₹{props.Price_Offered}/- <span class="text-red-500 line-through text-sm">₹ {props.Price}/- </span>
// 					<AmountCal a={props.Price_Offered} b={props.Price} />
// 				</p>
// 				<BuyButton className='ml-3' />

// 			</div >


// 		</div>
// 	);
// };




// function Sepration() { return <span class="mx-[10px] text-[#e4002b]">|</span> }


// function AmountCal(props) {

// 	const c = props.a - props.b;
// 	return <span class="mx-[20px] p-1 bg-green-600 text-base rounded-md">Save ₹{c}/-</span>
// }



// const Popover = (props) => {
// 	const [isVisible, setIsVisible] = useState(false);

// 	useEffect(() => {
// 		// Function to handle scroll event
// 		const handleScroll = () => {
// 			// Calculate the scroll position
// 			const scrollPosition = window.scrollY;

// 			// Set visibility based on scroll position
// 			if (scrollPosition > window.innerHeight * 1.2) {
// 				setIsVisible(true);
// 			} else {
// 				setIsVisible(false);
// 			}
// 		};

// 		// Add scroll event listener when component mounts
// 		window.addEventListener("scroll", handleScroll);

// 		// Remove scroll event listener when component unmounts
// 		return () => {
// 			window.removeEventListener("scroll", handleScroll);
// 		};
// 	}, []); // Empty dependency array to run effect only once when component mounts

// 	return (
// 		<div className="fixed top-[71px] left-0 w-full h-max flex justify-center">
// 			{/* Popover content */}
// 			{isVisible && (

// 				<div class="w-[100%] h-full py-3 bg-black flex flex-row flex-wrap justify-center items-center">

// 					<div class="flex flex-col px-[2%] w-[50%] text-white font-normal ">
// 						<h1 className='text-2xl font-semibold'>	{props.Product_Name} </h1>
// 						{/* <p className='text-lg mt-2'>	{props.SKU} <Sepration /> {props.Category} <Sepration /> {props.Type} <Sepration /> {props.Output} </p> */}
// 					</div >

// 					<div class="flex w-[50%]  text-2xl font-normal space-x-5 items-center justify-end pr-[2%] border-white border-0">
// 						<p className='text-white'>	₹{props.Price_Offered}/- <span class="text-red-000 font-light text-xl"> MRP (Incl. of all taxes) </span> <span class="text-red-000 line-through text-sm">₹ {props.Price}/- </span>
// 							<AmountCal a={props.Price_Offered} b={props.Price} />
// 						</p>
// 						<BuyButton className='ml-3' />

// 					</div >


// 				</div>

// 			)}
// 		</div>
// 	);
// };




export default AudioView;
