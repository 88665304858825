import React, { useState } from 'react';
import PocketBase from 'pocketbase';





function ContactUs() {

  const isMobile = window.innerWidth < 770;

  return (
    <div className="w-full h-full flex flex-col lg:flex-row">

      {isMobile ? <ContactMob /> : <ContactDesktop />}

      <div className="w-screen lg:w-[50%] py-[30px] lg:py-[100px]">

        <MyForm />

      </div>

    </div>
  );
}





function ContactMob() {

  return (

    <div className="pt-[120px] h-full px-10 bg-Black flex">

      <div className="m-auto space-y-2  text-center">

        <h1 className="font-semibold text-5xl my-[50px]">Reach Us</h1>
        
        <div className='border-b space-y-2 py-3'>
        <p className=" text-lg m-auto">Contact us</p>
        <p className=" text-2xl m-auto">+91 85448 40022</p>
        <p className=" text-2xl m-auto">+91 85448 40033</p>

        <p className=" text-lg m-auto"> </p>
        </div>
      
        <div className='border-b space-y-2 py-3'>
        <p className=" text-lg m-auto">Email us</p>
        <p className=" text-2xl m-auto">akaicare@akaiindia.in</p>
        </div>
        
        <div className='border-b space-y-2 py-3'>
        <p className=" text-lg m-auto">Office</p>
        <p className=" text-2xl m-auto">SCO 363-64, 1st Floor, 35B, <br/> Chandigarh, 160022 </p>
        </div>
      
        

      </div>

    </div>
  );

}







function ContactDesktop() {

  return (
    <div className="pt-[120px] lg:pt-0   lg:w-[50%] h-full lg:h-screen bg-Black flex">
      <div className="m-auto space-y-2">
        
      <div className='border-b space-y-2 py-3'>
        <p className="font-semibold text-left text-6xl lg:text-8xl mb-[50px]">Reach Us</p>
        <p className="text-left text-lg lg:text-sm m-auto">Contact us</p>
        <p className="text-left text-lg lg:text-xl m-auto">+91 85448 40022 | +91 85448 40033</p>
        <p className="text-left text-lg lg:text-xl m-auto"> </p>
        </div>
      
        <div className='border-b space-y-2 py-3'>
        <p className="text-left text-lg lg:text-sm m-auto">Email us</p>
        <p className="text-left text-lg lg:text-xl m-auto">akaicare@akaiindia.in</p>
        </div>
        
        <div className='border-b space-y-2 py-3'>
        <p className="text-left text-lg lg:text-sm m-auto">Office</p>
        <p className="text-left text-lg lg:text-xl m-auto">SCO 363-64, 1st Floor, 35B, Chandigarh, 160022 </p>
        </div>
      
      </div>
    </div>
  );

}











function MyForm() {
  const pb = new PocketBase('https://api.akaiindia.shop');

  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = { name, mobile, email, city, message };

    try {
      const record = await pb.collection('ContactUs').create(formData);
      console.log('Form submitted:', record);
      setStatus(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>

      <div className="flex flex-col m-auto md:w-[70%] w-[85%] ">

        <p className="text-black font-semibold text-left text-4xl md:text-4xl lg:pt-[50px] pt-[10px] mb-[30px] md:m-0 m-auto md:mb-5">Get in touch.</p>

         
          {status ?

            <div className='border-2 border-black p-3 rounded-2xl divide-y flex justify-center items-center h-max'> Your Message Sent Succesfully! </div>
            :
            <div className='border-2 border-black p-3 rounded-2xl divide-y'>

          <label>
            <input required
              className="w-full duration-200 text-left p-[10px] m-1 border-b-2"
              type="text"
              value={name}
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
            />
          </label>

          <label>
            <input required
              className="w-full duration-200 text-left p-[10px] m-1 border-b-2"
              type=""
              value={mobile}
              placeholder="Enter your mobile number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </label>


          <label>
            <input required
              className="w-full  duration-200 text-left p-[10px] m-1 border-b-2"
              type="email"
              value={email}
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>



          <label>
            <input required
              className="w-full  duration-200 text-left p-[10px] m-1 border-b-2"
              type="text"
              value={city}
              placeholder="Write your city"
              onChange={(e) => setCity(e.target.value)}
            />
          </label>



          <label>
            <input required
              className="w-full h-[150px]  duration-200 text-left p-[10px] m-1 border-b-2 text-wrap"
              type="text"
              value={message}
              placeholder="Write your message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </label>

          <input
            className="bg-black duration-200 hover:bg-slate-800 text-center text-white p-[20px] md:mt-[20px] mt-[20px] md:m-0 m-auto w-[120px] rounded-xl"
            type="submit"
            value="Send"
          />
                 </div>

          }

      </div>

    </form>
  );
}

export default ContactUs;
