import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PocketBase from 'pocketbase';

function Featured_Products() {
   
   
    const [records, setRecords] = useState([]);
    const galleryRef = useRef(null);



    useEffect(() => {
       
        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
        
                const fetchedRecords = await pb.collection('Featured_Products').getFullList({ sort: '-created' });
        
                setRecords(fetchedRecords);
        
            } catch (error) {
       
                console.error('Error fetching records:', error);
       
            }
        };

        fetchRecords();
    }, []);




    // Screen Size Check Part start
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const checkWindowSize = () => {
        setIsSmallScreen(window.innerWidth <= 800);
    };

    useEffect(() => {
        checkWindowSize();
        window.addEventListener('resize', checkWindowSize);

        return () => {
            window.removeEventListener('resize', checkWindowSize);
        };
    }, []);
    // Screen Size Check Part End

    const scrollLeft = () => {
        galleryRef.current.scrollLeft -= 300; // Assuming card width is 300px
    };

    const scrollRight = () => {
        galleryRef.current.scrollLeft += 300; // Assuming card width is 300px
    };

    const memoizedRecords = useMemo(() => records, [records]);

    return (
        <main className="w-screen h-full bg-slate-000 border-0 flex flex-col md:flex-col justify-center items-center">
            <h1 className="text-3xl font-bold md:text-7xl md:font-bold my-3 md:my-9">Featured Products </h1>

            <div className="w-screen h-full border-0 flex flex-col md:flex-row justify-center items-center">
                <button onClick={scrollLeft} className={`m-1 h-[100px] w-[30px] hover:h-[120px] duration-200 bg-black text-white p-2 rounded ${isSmallScreen ? 'hidden' : ''}`}>
                    {'<'}
                </button>
                <div className="w-screen md:w-[90%] grid grid-rows-2 grid-flow-col bg-slate-000 mb-9 p-3 overflow-x-scroll overflow-y-hidden" ref={galleryRef} style={{ scrollBehavior: 'smooth' }}>
                    {memoizedRecords.map((record, index) => (
                        <ImageCard key={record.id} record={record} />
                    ))}
                </div>
                <button onClick={scrollRight} className={`m-1 h-[100px] w-[30px] hover:h-[120px] duration-200 bg-black text-white p-2 rounded ${isSmallScreen ? 'hidden' : ''}`}>
                    {'>'}
                </button>
            </div>
        </main>
    );
}




const ImageCard = ({ record }) => {
   
    const { id, Product_URL, Image } = record;
  
    const imageUrl = `https://api.akaiindia.shop/api/files/${record.collectionId}/${id}/${Image}`;

    return (
        <div className="w-[165px] h-[165px] md:w-[300px] md:h-[300px] bg-slate-200 overflow-hidden rounded-xl m-2">
            <Link to={Product_URL}>
                <img className="object-cover" src={imageUrl} alt={`Product ${id}`} />
            </Link>
        </div>
    );
};




export default Featured_Products;
