
import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
// import { useMediaQuery } from 'react-responsive';
import InteractiveDisplaysMobile from './InteractiveDisplaysMobile';
import InteractiveDisplaysDesktop from './InteractiveDisplaysDesktop';



function InteractiveDisplays() {


  const isMobile = window.innerWidth < 768;




  return (
    <div>

    <title> Akai Interactive Display, Akai Interactive Flat Panel Display, Akai Interactive Panel for Classroom, </title>
    <link rel="preload" href={process.env.PUBLIC_URL + "./Interactive/main.webp"} as="image" />

      {/* <InteractiveDisplaysMobile /> */}
      {isMobile ? <InteractiveDisplaysMobile /> : <InteractiveDisplaysDesktop />}
    </div>

    );
  }

export default InteractiveDisplays;

