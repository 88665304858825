import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';





const SpecsRow = (props) => {

	return (
<>
		{props.val != '' && (

		<div class="w-full md:w-[49%] lg:w-[32%] flex border-0 bg-gray-100 rounded-xl m-0.5">
			<p class="text-left text-gray-500 text-base p-[8px] lg:p-[20px] font-base">{props.label} <br /><span class="text-xl text-black font-normal py-[20px] m-auto">{props.val}</span></p>
		</div>
		)
		}
		</>
);
};






const FaqItem = ({ question, answer, index }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleAnswer = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="w-screen flex flex-col justify-center items-center h-full transition ease-in-out delay-150 mb-2">

			<div className='lg:w-[70%] w-[95%] overflow-hidden border-2 border-gray-200 rounded-xl transition-all duration-500 ease-in-out text-black'>

				<div className=" relative flex transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5" onClick={toggleAnswer}>
					<span className='text-red-500 mr-2'>Question:{index}</span> {question}
					<div className='absolute right-5 my-auto'>
						<img className='w-[25px] h-[25px] mr-2' src={process.env.PUBLIC_URL + '/Icons/arrow-down.svg'} alt="My Image" />
					</div>
				</div>


				{isOpen && <div className="transition-all duration-500 w-full h-full hover:bg-gray-100 m-auto text-base lg:text-xl p-5"> <span className='text-red-500'>Answer:</span> {answer}</div>}

			</div>
		</div>
	);
};





//for of product name price etc on Mobile   
const ProductInfoCard = (props) => {


	return (
		<div class=" w-[97%] bg-black h-full rounded-xl flex flex-row flex-wrap p-[5%] text-white text-middle space-y-2 m-auto ">

			<div className='flex text-center text-lg font-semibold items-center justify-center w-full'>
				<p className='text-center'>{props.Product_Name} </p>
			</div>

			{/* <div className='w-full items-center justify-center flex flex-row text-white text-center text-xs'>
				<p>{props.SKU}</p><Sepration />
				<p>{props.Category}</p><Sepration />
				<p>{props.Type}</p>
			</div> */}


			<div class="w-full justify-center flex flex-row w-full  text-2xl font-normal space-x-3 items-center">
				<p>	₹{props.Price_Offered}/- </p>
				<p>	<span class="text-red-500 line-through text-sm">₹ {props.Price}/- </span> </p>
				<p>  <AmountCal a={props.Price_Offered} b={props.Price} /> </p>
			</div >

			<div className='flex justify-center items-center w-full'>
				<BuyButton className='mt-3' />
			</div>

		</div>
	);
};



//infor of product name price etc   
const ProductInfoCardDesk = (props) => {


	return (
		<div class="w-[90%] h-full py-3 bg-black flex flex-col xl:flex-row flex-wrap justify-center items-center  rounded-2xl xl:rounded-2xl">

			<div class="flex xl:pl-5 px-2 border-0 mb-3 xl:mb-0 w-[100%] md:justify-center xl:justify-start items-center xl:w-[50%] text-white font-normal">
				<h1 className='text-2xl xl:text-left md:text-center font-semibold'>	{props.Product_Name} </h1>
			</div >

			<div class="flex w-[100%] xl:w-[50%] text-white  border-0 text-2xl font-normal space-x-3 items-center justify-center xl:justify-end xl:pr-5">
				
				<p className='text-white '>	₹{props.Price_Offered}/-   </p>

				<p class="text-red-000 line-through text-base">₹ {props.Price}/- </p>

				<AmountCal a={props.Price_Offered} b={props.Price} />

				<BuyButton className='ml-3' />

			</div >


		</div>
	);
};



//sepration bar
function Sepration() { return <span class="mx-[10px] text-[#e4002b]">|</span> }





//this calculate saving amount of price
function AmountCal(props) {

	const c = props.b - props.a ;
	return <span class="px-2 py-1.5 border-2 border-green-600 text-sm md:text-base rounded-full "> Save ₹{c}</span>
}







//Buy Now Button


const BuyButton = (props) => {

	const [isOpen, setIsOpen] = useState(false);

	

	console.log(props.product + 'woho')


	const togglePopup = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="relative">
			{/* Button to open the popup */}
			<button className="bg-white py-2 px-5 text-sm font-semibold w-max text-black rounded-full active:border-green-200" onClick={togglePopup} > Buy Now </button>

			{/* Popup window */}

			{isOpen && (

				<div className="w-full fixed inset-0 flex items-center justify-center z-40 bg-black bg-opacity-90 bg-gray-700">

					<div className="relative flex flex-col md:flex-row itemns-center justify-center h-auto w-[90%] xl:w-[60%] lg:w-[85%] md:w-[75%] bg-white p-3 md:p-6 rounded-3xl shadow-lg flex">

						<div className='flex flex-col px-9 justify-center text-center items-center w-full md:w-[50%] space-y-3 md:space-y-9 my-9'>

							<p className="text-black text-md lg:text-xl md:text-md ">
								Currently, this product is not available for online purchase..
							</p>

							<p className="text-black text-sm lg:text-xl md:text-md font-semibold ">
								Please share your details and we'll promptly contact you with suitable buying options.
							</p>

							<h1 className="text-black text-lg md:text-2xl font-bold text-[#4002b]" > We appreciate your patience and interest!</h1>
						</div>

						<div className='flex justify-center items-center w-full md:w-[50%]'>

							<MyForm productName={props.product}/>
						</div>

						<button className="absolute top-0 left-0  text-black hover:text-red-500 py-2 px-4 rounded w-full md:w-[5vw] h-[5vw] " onClick={togglePopup}> X Close </button>

					</div>
				</div>
			)}
		</div>
	);
};








function MyForm({productName}) {


	const pb = new PocketBase('https://api.akaiindia.shop');


	const [name, setName] = useState('');
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [city, setCity] = useState('');
	const [message, setMessage] = useState('');
	const [status, setStatus] = useState(false);
	const [product, setProduct] = useState(productName);

	console.log(product + 'name of product')

	const handleSubmit = async (event) => {

		event.preventDefault();

		const formData = { name, mobile, email, city, message, product };

		try {
			const record = await pb.collection('buy_now').create(formData);
			console.log('Form submitted');
			setStatus(true);
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	};



	return (
		
		

		<form onSubmit={handleSubmit}>

			<div className="flex flex-col m-auto w-full z-40">

			{status ? 
			
			<div className='text-black flex items-center justify-center'> Your Message Sent Succesfully! </div>
			:
				<div className='border-2 border-black p-3 rounded-2xl divide-y'>

					<label>
						<input
							required
							className="w-full duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2 require text-sm text-black"
							type="text"
							value={name}
							placeholder="Enter your name"
							onChange={(e) => setName(e.target.value)}
						/>
					</label>



					<label>
						<input
							required
							className="w-full  duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2 require text-sm text-black"
							type="email"
							value={email}
							placeholder="Enter email"
							onChange={(e) => setEmail(e.target.value)}
						/>
					</label>



					<label>
						<input
							required
							className="w-full  duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2 require text-sm text-black"
							type=""
							value={mobile}
							placeholder="Enter your Mobile Number"
							onChange={(e) => setMobile(e.target.value)}
						/>
					</label>



					<label>
						<input
							required
							className="w-full duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2 require text-sm text-black"
							type="text"
							value={city}
							placeholder="Enter your city"
							onChange={(e) => setCity(e.target.value)}
						/>
					</label>



					<label>
						<input
							required
							className="w-full h-[80px] md:h-[120px]  duration-200 text-left md:p-[10px] p-[5px] m-1 border-b-2 require text-sm text-black"
							type="text"
							value={message}
							placeholder="Write us message"
							onChange={(e) => setMessage(e.target.value)}
						/>
					</label>



					<input
						
						className="bg-black duration-200 hover:bg-slate-800 text-center text-white p-[5px] mt-[5px] md:p-[20px] md:mt-[20px] md:mt-[20px] md:m-0 m-auto w-[120px] rounded-xl"
						type="submit"
						value="Send"
					/>
				</div>
	}
	</div>
		</form>
	
	);
}





const SubCategoryButton = (props) => {

	return (
		< div className='flex py-6 justify-center flex-row items-center hover:px-2 rounded-full w-max h-9 hover:border-2 border border-gray-700 hover:border-[#e4002b] duration-300'>

			<Link className='xl:text-2xl text-lg p-9 flex justify-center items-center' to={`/product/${props.category.toLowerCase()}/${props.subcategory.toLowerCase()}`} >

				<div>
					{props.subcategory.replace(/[-]/g, ' ')}
				</div>

				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
					</svg>
				</div>

			</Link>

		</div>
	);
}








function SectionBannercomp(props) {
   
	const [sectionBanner, setSectionBanner] = useState([]);
    const [filter, setFilter] = useState(props.name);

    useEffect(() => {
        const pb = new PocketBase('https://api.akaiindia.shop');

        const fetchRecords = async () => {
            try {
                const fetchedBanners = await pb.collection('Banners').getFullList('created');

                const filteredBanner = fetchedBanners.filter(item => item.category.toLowerCase().includes(filter.toLowerCase()));

                setSectionBanner(filteredBanner);
           
			} catch (error) {
                console.error('Error fetching records:', error);
            }
        };

        fetchRecords();
    }, [props.name]);

    if (sectionBanner.length === 0) {
        return <div></div>;
    }

    const url = `https://api.akaiindia.shop/api/files/${sectionBanner[0].collectionId}/${sectionBanner[0].id}/${sectionBanner[0].banner}`;

    return (
        <div className='w-[95%] h-full bg-red-000 rounded-2xl overflow-hidden mx-auto'>
            <img className='w-fit' src={url} />
        </div>
    );
}





export {SectionBannercomp, SpecsRow, FaqItem, ProductInfoCard, ProductInfoCardDesk, Sepration, AmountCal, BuyButton, SubCategoryButton };
