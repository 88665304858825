import React, { useState } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';

const Admin = () => {

  const { user, isAuthenticated, isLoading } = useAuth0();
  const Navigate = useNavigate();

  const goToAbout = () => {
    // Navigate to the about route
    Navigate('/');
  };


  if (isLoading) {
    return <div className='h-screen w-screen bg-red-500 pt-[200px]'>Loading ...</div>;
  }

  return (

    <div className='h-screen w-screen bg-red-500 pt-[200px]'>
      
      
    <LoginButton/>
    <LogoutButton/>


    {isAuthenticated && (
      <div className='pt-[200px]'>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <button onClick={goToAbout}>Go to About</button>
      </div>
      
    )
    }
    </div>
  );
};




const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button className='bg-black px-9 py-2 text-white rounded-full m-5' onClick={() => loginWithRedirect()}>Log In</button>;
};


const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button className='bg-black px-9 py-2 text-white rounded-full m-5' onClick={() => logout({ logoutParams: { returnTo: 'http://localhost:3000/Admin' } })}>
      Log Out
    </button>
  );
};





export default withAuthenticationRequired(Admin);


// export default Admin;
