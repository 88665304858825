import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PocketBase from 'pocketbase';
import ProductImageViewer from './components/ProductImageViewer';
import BuyButton from './components/BuyButton';

import ImageMapping from './components/ImageMapping';
import KeyFeatureMapping from './components/KeyFeatureMapping';

import { SpecsRow, AmountCal, ProductInfoCardDesk, ProductInfoCard, Separation, FaqItem } from './components/ProductViewUtility';







function WashingMachineView() {


	const { productId } = useParams();

	console.log('this is' + useParams())

	const [records, setRecords] = useState([]);
	const [records2, setRecords2] = useState([]);
	const [records3, setRecords3] = useState([]);


	const overviewRef = useRef(null);
	const specificationRef = useRef(null);
	const keyfeatureRef = useRef(null);
	const FaqRef = useRef(null);


	const scrollToOverview = () => { overviewRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToSpecification = () => { specificationRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToKeyfeature = () => { keyfeatureRef.current.scrollIntoView({ behavior: 'smooth' }); };
	const scrollToFaq = () => { FaqRef.current.scrollIntoView({ behavior: 'smooth' }); };






	useEffect(() => {

		const pb = new PocketBase('https://api.akaiindia.shop');

		const fetchRecords = async () => {
			try {
				const fetchedRecords = await pb.collection('washing_machine').getOne(productId, { expand: 'Specs_Images,relField2.subRelField' },);
				setRecords(fetchedRecords);
				setRecords2(fetchedRecords.Specs_Images);
				setRecords3(fetchedRecords.Listing_Image);

			} catch (error) {
				console.error('Error fetching records:', error);
			}
		};

		fetchRecords();
	}, []);









	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Function to handle scroll event
		const handleScroll = () => {
			// Calculate the scroll position
			const scrollPosition = window.scrollY;

			// Set visibility based on scroll position
			if (scrollPosition > window.innerHeight * 1.2) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		// Add scroll event listener when component mounts
		window.addEventListener("scroll", handleScroll);

		// Remove scroll event listener when component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []); // Empty dependency array to run effect only once when component mounts













	return (
		<div class="w-full overflow-hidden">

			<div class=" bg-slate-000 flex flex-row pt-[70px]">



				{/* Listing View */}

				<ProductImageViewer class='my-[50px]' images={records3} records={records} />

			</div>


			<div class='w-screen h-full flex flex-col items-center justify-center mt-5'>

				<div class="w-screen flex flex-row flex-wrap justify-center">

					<div class=" w-screen flex justify-center hidden md:flex md:block">
						<ProductInfoCardDesk Product_Name={records.Product_Name} SKU={records.SKU} Category={records.Category} Type={records.Type} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

					<div class=" w-screen h-full md:hidden visible">
						<ProductInfoCard Product_Name={records.Product_Name} SKU={records.SKU} Category={records.Category} Type={records.Type} Price={records.Price} Price_Offered={records.Price_Offered} />
					</div>

				</div>

				{/* Scroll navigation */}
				<div class="md:w-[90%] w-screen md:h-full bg-gray-200 flex md:flex-row justify-center mt-2 md:rounded-full md:space-x-12 md:space-y-0 space-x-5 p-5 md:py-3">

					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToOverview}>Overview</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToKeyfeature}>Key features</button>
					<button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToSpecification}>Specification</button>
					{/* <button class="hover:text-[#e4002b] duration-200 font-base text-base md:text-xl " onClick={scrollToFaq}>FAQ</button> */}

				</div>

			</div>







			{/*pop up bar  */}

			<div className="fixed xl:top-[70px] lg:top-[109px] md:top-[106px] bottom-[0px] left-0 w-full h-max flex flex-col justify-center z-40">
				{/* Popover content */}
				{isVisible && (
					<>
						<div class="w-[100%] h-fit py-3 bg-black flex flex-col xl:flex-row flex-wrap justify-center items-center">

							<div class="flex xl:pl-5 border-0 mb-3 xl:mb-0 w-[100%] justify-center md:justify-center xl:justify-start items-center xl:w-[50%] text-white font-normal">
								<h1 className='text-sm font-light md:text-2xl md:font-semibold text-center lg:text-left'>	{records.Product_Name} </h1>
							</div >

							<div class="flex flex-col md:flex-row w-[100%] xl:w-[50%] text-white  border-0  font-normal space-x-3 items-center justify-center xl:justify-end xl:pr-5">

								<div className='flex justify-center items-center space-x-3'>
									<p className='text-white text-lg md:text-2xl '>	₹{records.Price_Offered}/-   </p>
									<p class="text-red-000 line-through text-xs md:text-base">₹ {records.Price}/- </p>
									<AmountCal className='' a={records.Price_Offered} b={records.Price} />
								</div>

								<div className='mt-2 md:mt-0'> <BuyButton className='' /> </div>

							</div >


						</div>


						<div class="w-full md:h-max py-3 md:py-2 text-gray-200 bg-black border-t border-gray-700 flex md:flex-row justify-center mt-0 md:rounded-0 md:space-x-12 md:space-y-0 space-x-5 p-5 md:p-0">

							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToOverview}>Overview</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToKeyfeature}>Key features</button>
							<button class="hover:text-[#e4002b] duration-200 font-light text-base md:text-md " onClick={scrollToSpecification}>Specification</button>
							{/* <button class="hover:text-[#e4002b] duration-200 text-base md:text-2xl " onClick={scrollToFaq}>FAQ</button> */}

						</div>

					</>


				)}
			</div>




			<div ref={overviewRef} class="w-screen h-[150px] flex bg-slate-000">
				<p class="text-5xl font-bold m-auto">Overview</p>
			</div>

			<div class="w-screen h-full pb-[50px] lg:pb-[100px] bg-black-400 flex flex-col item-center justify-center">
				<div>
					<text class="px-[8%] lg:px-[20%] bg-gray-000 flex text-center">{records.overview}</text>
				</div>
			</div>

			<div ref={keyfeatureRef} class="w-screen h-[150px] flex bg-slate-000">
				<p class="text-5xl font-bold m-auto">Key Features</p>
			</div>

			{/* showing A+ Images here  */}
			<div class="grid grid-cols-3 gap-4 items-center justify-center">
				<ImageMapping records={records} records2={records2} />
			</div>

			<div ref={specificationRef} class="w-screen h-[150px] flex bg-slate-000">
				<p class="text-5xl font-bold m-auto">Specification</p>
			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">General</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='IN Box:' val={records.INBox} />
				<SpecsRow label='Function Type:' val={records.FunctionType} />
				<SpecsRow label='Washing Capacity:' val={records.WashingCapacity} />
				<SpecsRow label='Washing Method:' val={records.WashingMethod} />
				<SpecsRow label='Maximum Spin Speed:' val={records.MaximumSpinSpeed} />
				<SpecsRow label='Inbuilt Heater:' val={records.InbuiltHeater} />
				<SpecsRow label='Color:' val={records.Color} />
				<SpecsRow label='Dryer Capacity:' val={records.DryerCapacity} />
				<SpecsRow label='Water Level Selector:' val={records.WaterLevelSelector} />
				<SpecsRow label='Water Inlet Selector:' val={records.WaterInletSelector} />
				<SpecsRow label='Water Consumption:' val={records.WaterConsumption} />
				<SpecsRow label='Wash Cycle Duration:' val={records.WashCycleDuration} />
				<SpecsRow label='Spin Cycle Duration:' val={records.SpinCycleDuration} />
				<SpecsRow label='Soak Duration:' val={records.SoakDuration} />
				<SpecsRow label='Shade:' val={records.Shade} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Wash Modes</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Wash Program Types:' val={records.WashProgramTypes} />
				<SpecsRow label='Fuzzy Logic:' val={records.FuzzyLogic} />
				<SpecsRow label='Pre Wash Soak:' val={records.PreWashSoak} />
				<SpecsRow label='Quick Wash:' val={records.QuickWash} />
				<SpecsRow label='Spin Only:' val={records.SpinOnly} />
				<SpecsRow label='Spin & Rinse:' val={records.SpinAndRinse} />

			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Body Feature</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Outer Body Material:' val={records.OuterBodyMaterial} />
				<SpecsRow label='Tub Material:' val={records.TubMaterial} />
				<SpecsRow label='Transparent Window Display:' val={records.TransparentWindowDisplay} />
				<SpecsRow label='Transparent Lid:' val={records.TransparentLid} />
				<SpecsRow label='LID Material:' val={records.LIDMaterial} />
				<SpecsRow label='Wheel Support:' val={records.WheelSupport} />
				<SpecsRow label='Other Body Features:' val={records.OtherBodyFeatures} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Convenience Feature</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Digital Display:' val={records.DigitalDisplay} />
				<SpecsRow label='Tub Self Clean:' val={records.TubSelfClean} />
				<SpecsRow label='Display Features:' val={records.DisplayFeatures} />
				<SpecsRow label='Preset Timer:' val={records.PresetTimer} />
				<SpecsRow label='Wrinkle Prevention:' val={records.WrinklePrevention} />
				<SpecsRow label='Lint Filter:' val={records.LintFilter} />
				<SpecsRow label='Memory Backup:' val={records.MemoryBackup} />
				<SpecsRow label='Other Convenience Feature:' val={records.OtherConvenienceFeature} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Power Feature</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Noise Level Wash:' val={records.NoiseLevelWash} />
				<SpecsRow label='Noise Level Spin:' val={records.NoiseLevelSpin} />
				<SpecsRow label='Child Lock:' val={records.ChildLock} />
				<SpecsRow label='Shock Proof:' val={records.ShockProof} />
				<SpecsRow label='Door Lock:' val={records.DoorLock} />
				<SpecsRow label='Auto Power Off:' val={records.AutoPowerOff} />
				<SpecsRow label='Other Features:' val={records.OtherFeatures} />

			</div>

			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Dimension</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Length X Height X Width:' val={records.LengthXHeightXWidth} />
			</div>


			<div class="w-[80%] h-full m-auto flex flex-rox flex-wrap">
				<p class="text-2xl font-bold py-[30px] ">Warranty</p>
			</div>

			<div class="w-[80%] h-full m-auto bg-slate-000 flex flex-rox flex-wrap">
				<SpecsRow label='Warranty Summary:' val={records.WarrantySummary} />
				<SpecsRow label='Covered in Warranty:' val={records.CoveredInWarranty} />
				<SpecsRow label='Not Covered in Warranty:' val={records.NotCoveredInWarranty} />
				<SpecsRow label='Warranty Service Type:' val={records.WarrantyServiceType} />
			</div>


		</div>
	);
}



export default WashingMachineView;
