import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';



function NewsletterForm() {
  const [formData, setFormData] = useState({ name: '', email: '', acceptTerms: false });
  const [submitted, setSubmitted] = useState(false);

  const pb = new PocketBase('https://api.akaiindia.shop');


  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    setSubmitted(true);
    const record = pb.collection('NewsLetter').create(formData);
    console.log('Form submitted:', record);
  };

  return (
    <>
    <div class='w-full h-full md:h-[280px] lg:h-[280px] bg-gray-300 flex md:rounded-md items-center justify-center p-9 md:p-5 lg:p-9 '>
   
    <form class='flex flex-col xl:flex-row items-center justify-center' onSubmit={handleSubmit}>
        
        
          <div className='my-5'>
          <p className='xl:text-lg md:text-2xl'>✉ Subscribe for Exclusive Offers and Newsletter </p>
          </div>     







          <div className='flex items-center items-center flex-col xl:flex-row md:space-y-5 xl:space-y-0'>
         
         
         
          <input
            class='rounded-full w-[350px] m-4 md:m-1 xl:m-5 h-[50px] p-5 text-black'
            type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Enter email' required 
          />
      
      <div className='flex items-center'>
          <input
            class=' mx-3 my-0 text-white'
            type="checkbox" name="acceptTerms" checked={formData.acceptTerms} onChange={handleChange} required 
          />
           <p class='text-xs '> Accept Terms and Conditions </p>
           </div>



           <button
                className={`m-4 md:m-1 text-white xl:m-5 rounded-full w-[150px] h-[50px] p-5 mx-3 py-0 ${submitted ? 'bg-red-500' : 'bg-gray-800'}`}
                type="submit"
                disabled={submitted}
            >
                Subscribe
            </button>
    
            </div>
    </form>
    </div>
    </>
  );
}

export default NewsletterForm;
