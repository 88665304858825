import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Import the bubble theme




function SeoSection({ subcategory }) {

	// const { seo_subtext, seo_text, seo_title } = data;

	const [open, setOpen] = useState(false);


	const [seoText, setSeoText] = useState();
	const [seoTitle, setSeoTitle] = useState('');
	const [seoSubtext, setSeoSubtext] = useState('');

    
    useEffect(() => {
           
            const pb = new PocketBase('https://api.akaiindia.shop');
    
            const fetchRecords = async () => {
                try {
                    // const seo = await pb.collection('SEO').getOne(id, { expand: 'relField1,relField2.subRelField', });
                    const seo = await pb.collection('SEO').getFullList({ filter: `Category = "${subcategory}"` });

                    setSeoTitle(seo[0].seo_title)
                    setSeoSubtext(seo[0].seo_subtext)
                    setSeoText(seo[0].seo_text)
                    
                    console.log('SEO OK')

                } catch (error) {
                    console.error('Error fetching records:', error);
                }
            };
    
            fetchRecords();
    
        }, []);    





	return (


		<div class="w-full pb-[10px] mt-20 lg:pb-[50px] bg-red-000  flex item-center justify-center">

			<div className='w-[93%] md:w-[80%] bg-gray-000 rounded-xl border-2 flex flex-col items-start justify-center'>

				<h1 class="px-[8%] lg:px-[5%] mt-4 font-bold bg-gray-000 py-5 text-2xl md:text-4xl flex h1-center">{seoTitle}</h1>

				<text class="px-[8%] pb-3 lg:px-[5%] text-md md:text-xl bg-gray-000 flex text-left">{seoSubtext}</text>

				<button className='mx-[8%] lg:mx-[5%] border hover:text-red-500 hover:border-red-500 rounded-full font-semibold p-2 px-3 my-5' onClick={() => setOpen(!open)}>Read More</button>

				{ open && 
                
                <div class="px-[8%] lg:px-[10%] bg-gray-000 flex text-left text-sm md:text-base pb-20" >
                <ReactQuill 
                theme="bubble" value={seoText} readOnly preserveWhitespace />
                </div>
                }



			</div>
		</div>

	)
}

export default SeoSection;