import React, { useState, useEffect } from 'react';

const ProductImageViewer = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [index, setIndex] = useState(0);
  const url = 'https://api.akaiindia.shop/api/files/' + props.records.collectionId + '/' + props.records.id + '/';

  // Update currentImage when index changes
  
  useEffect(() => {
    setCurrentImage(index);
  }, [index]);

  const handleNextImage = () => {
    if (index < props.images.length - 1) {
      setIndex(index + 1);
    }
  };

  const handlePreviousImage = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };


  





  return (
    <div className="w-screen lg:w-[80%] mx-auto mt-8 md:mt-14 xl:mt-8  flex flex-col items-center bg-red-000">
    
      <div className="w-screen bg-gray-000 grid grid-cols-1 lg:grid-cols-6 gap-4 items-center justify-center bg-green-000">
      
        <button className="text-5xl hidden lg:block hover:text-6xl hover:border p-3 rounded-xl duration-200" onClick={handlePreviousImage}> ❮ </button>
      
            <div className="w-[350px] lg:w-[620px] lg:h-[620px] m-auto rounded-lg lg:col-span-4 overflow-hidden">
              <img className="border m-auto rounded-lg col-span-4" src={url + props.images[currentImage]} alt={`Product Image ${currentImage + 1}`} />
            </div>

        <button className="object-contain text-5xl hidden lg:block hover:text-6xl hover:border p-3 rounded-xl duration-200" onClick={handleNextImage}>❯</button>
      
      </div>

      <div className="flex space-x-2 lg:space-x-9 p-3 rounded-xl  overflow-x-scroll w-[100%] lg:w-full justify-center" >
        {props.images.map((image, idx) => (
          <img
            key={idx}
            src={url + image}
            alt={`Product Image ${idx + 1}`}
            className={`hover:w-14 hover:h-14 duration-200 w-12 h-12 rounded-lg cursor-pointer ${idx === currentImage ? 'h-14 w-14 border-2 border-black' : ''}`}
            onClick={() => setIndex(idx)}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductImageViewer;
